import React, { Component } from 'react'

export default class Archive extends Component {
    render() {
        return (
            <div>
                 <br/>
                <br/>
                <br/>
                <br/>
                
                <h1>Archive page</h1>
            </div>
        )
    }
}
