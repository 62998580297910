import React, { useState, useEffect } from 'react'
import { Error, isEmpty } from '../_components/Validation';
import { documentActions } from '../_actions';
import { connect } from 'react-redux';
import { API } from '../_services';
import Message from '../_components/Message';
import { countries } from '../_constants/countries';

function DocumentApprove(props) {

    const [state, setState] = useState({ approveDisplay: false, rejectDisplay: false, errors: {}, load: false, error: null, success: null, body: [], documentId: null });
    const [form, setForm] = useState({ username: '', password: '', cpassword: '', reason: '' });

    useEffect(() => {
        setState(state => ({ ...state, body: props.data }))
    }, []);

    const handleApprove = e => {
        setState(state => ({ ...state, approveDisplay: true, rejectDisplay: false }));
        // console.log('approve');
    }

    const handleReject = e => {
        setState(state => ({ ...state, rejectDisplay: true, approveDisplay: false }));
        // console.log('reject');
    }

    const handleChange = e => {
        e.persist();
        setForm(form => ({ ...form, [e.target.name]: e.target.value }));
    }

    const handleSubmit = e => {
        setState(state => ({ ...state, load: true }));
        if (isEmpty(validate())) {
            const data = {
                "approverId": props.approverId,
                "changeDescription": "",
                "changeJustification": "",
                "documentApproval": true,
                "parentChangeId": props.parentId,
                "password": form.password,
                "username": form.username
            }
            console.log(data)
            // props.approveDocument(data);
            API.approveDocument(data).then(resp => {
                if (resp.status === 200) {
                    setState(state => ({ ...state, success: resp.data.message }));
                    setTimeout(() => {
                        setState(state => ({ ...state, success: null }));
                        props.onComplete();
                    }, 2000);
                } else {
                    setState(state => ({ ...state, error: resp.data.message }));
                    setTimeout(() => {
                        setState(state => ({ ...state, error: null }));
                    }, 2000);
                }
            })
            setState(state => ({ ...state, load: false }));
        } else {
            setState(state => ({ ...state, load: false }));
        }
    }

    const handleReasonSubmit = e => {
        setState(state => ({ ...state, load: true }));
        if (form.reason) {
            const data = {
                "approverId": props.approverId,
                "changeDescription": "",
                "changeJustification": form.reason,
                "documentApproval": false,
                "parentChangeId": props.parentId,
                "password": form.password,
                "username": form.username
            }
            console.log(data)
            API.approveDocument(data).then(resp => {
                if (resp.status === 200) {
                    setState(state => ({ ...state, success: resp.data.message }));
                    setTimeout(() => {
                        setState(state => ({ ...state, success: null }));
                        props.onComplete();
                    }, 2000);
                } else {
                    setState(state => ({ ...state, error: resp.data.message }));
                    setTimeout(() => {
                        setState(state => ({ ...state, error: null }));
                    }, 2000);
                }
            })
            setState(state => ({ ...state, load: false }));
        } else {
            let errors = {};
            errors['reason'] = 'Enter Reason';
            setState(state => ({ ...state, errors: errors }));
        }
    }

    const validate = e => {
        let errors = {};
        const { username, password, cpassword } = form;
        if (!username) errors['name'] = 'Enter Username';
        if (!password) errors['password'] = 'Enter Password';
        if (password && cpassword && (password !== cpassword)) errors['confirm'] = 'Password Mismatch';
        setState(state => ({ ...state, errors: errors }));
        return errors;
    }

    const viewHandle = id => {
        setState(state => ({ ...state, documentId: id }))
    }

    const getCountry = list => {
        let country = list.split(',');
        let selectedCountry = '';

        if (country) {
            country.map((v) => {
                // if (countries.find((o) => o.value === v)) {
                let cc = countries.find((o) => o.value === v).label;
                selectedCountry = selectedCountry + ' ' + cc;
                // }
            })
        }
        return selectedCountry;
    }

    useEffect(() => {
        let errors = {};
        if (form.password && form.cpassword && (form.password !== form.cpassword)) errors['confirm'] = 'Password Mismatch';
        setState(state => ({ ...state, errors: errors }));
    }, [form]);
    console.log(state.body)
    return (
        <React.Fragment>
            <div className="modal fade show" id="exampleModalLong" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="exampleModalLabel">{props.title}</h4>
                            <button type="button" onClick={props.onClose} className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body text-center">
                            <div className="col-md-12">
                                <span className='text-center' >No. Of Documents - <span style={{ fontWeight: 900 }}>{state.body.document && state.body ? state.body.document.length : null}</span></span><br></br><br></br>
                                <ul className="list-unstyled doc-list-ul mb-0">
                                    {state.body && state.body.document ? state.body.document.map((value, i) => {
                                        return (
                                            <React.Fragment key={i}>
                                                <li key={i}>
                                                    <div className="row d-flex justify-content-between">
                                                        <div className="col">
                                                            <div style={state.documentId === value.documentId ? { fontSize: '16px', fontWeight: 900 } : { fontSize: '16px' }}>
                                                                <span style={{ float: 'left' }}>{value.documentNumber} - {value.associatedReferenceNumber}</span>
                                                                <span className="mdi mdi-eye" onClick={() => viewHandle(value.documentId)} style={{ fontSize: '18px', color: '#198ae3', cursor: 'pointer', marginRight: '5px', float: 'right' }} data-tip="View"></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>

                                                {state.documentId === value.documentId ?
                                                    <React.Fragment>
                                                        <table className="table table-sm">
                                                            <tbody>
                                                                <tr>
                                                                    <td>Document Number</td>
                                                                    <th>{value.documentNumber}</th>
                                                                </tr>
                                                                <tr>
                                                                    <td>IFU Number</td>
                                                                    <th>{value.associatedReferenceNumber}</th>
                                                                </tr>
                                                                <tr>
                                                                    <td>Part Number</td>
                                                                    <th>{value.productSubFamilyNameOne}</th>
                                                                </tr>
                                                                <tr>
                                                                    <td>Description of IFU</td>
                                                                    <th>{value.productFamilyDescription}</th>
                                                                </tr>
                                                                <tr>
                                                                    <td>Language</td>
                                                                    <th>{value.languageList}</th>
                                                                </tr>
                                                                <tr>
                                                                    <td>Change Type</td>
                                                                    <th>{value.changeType}</th>
                                                                </tr>
                                                                <tr>
                                                                    <td>Bill Material</td>
                                                                    <th>{value.productSubFamilyNameTwo}</th>
                                                                </tr>
                                                                <tr>
                                                                    <td>Description of Change</td>
                                                                    <th>{value.changeDescription}</th>
                                                                </tr>
                                                                <tr>
                                                                    <td>Justification of Change</td>
                                                                    <th>{value.changeJustification}</th>
                                                                </tr>
                                                                <tr>
                                                                    <td>Internal</td>
                                                                    <th>{value.isInternal ? 'Yes' : 'No'}</th>
                                                                </tr>
                                                                <tr>
                                                                    <td>Approval Routine Number</td>
                                                                    <th>{value.approvalRoutingNumber}</th>
                                                                </tr>
                                                                <tr>
                                                                    <td>Country</td>
                                                                    <th>{getCountry(value.countryList)}</th>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <br></br>
                                                    </React.Fragment>
                                                    : null
                                                }
                                            </React.Fragment>
                                        )
                                    }) : null}
                                </ul>
                                <span className='text-center'>
                                    <button type="button" className="btn btn-sm btn-success" style={{ margin: '10px' }} onClick={handleApprove}>Approve</button>
                                    <button type="button" className="btn btn-sm btn-danger" style={{ margin: '10px' }} onClick={handleReject}>Reject</button>
                                </span>
                            </div>
                            <div className="col-md-12">
                                {state.approveDisplay ?
                                    <div className='show'>
                                        <div className="row" style={{ marginTop: '10px' }}>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Username</label>
                                                    <input type="text" className="form-control" id="username" name="username" value={form.username} placeholder="" onChange={handleChange} />
                                                    <Error error={state.errors['name']} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Password</label>
                                                    <input type="password" className="form-control" id="password" name="password" value={form.password} placeholder="" onChange={handleChange} />
                                                    <Error error={state.errors['password']} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Confirm Password</label>
                                                    <input type="password" className="form-control" id="cpassword" name="cpassword" value={form.cpassword} placeholder="" onChange={handleChange} />
                                                    <Error error={state.errors['confirm']} />
                                                </div>
                                            </div>
                                            {form.username && form.password && form.cpassword && isEmpty(state.errors) ?
                                                <div className="col-md-12 form-group text-center">
                                                    <div className="col-md-12 text-center">
                                                        <span className="btn btn-block btn-gradient-primary btn-md fit-content font-weight-medium auth-form-btn m-auto" onClick={state.load ? null : handleSubmit}>{state.load ? 'Submitting...' : 'Approve'}</span>
                                                    </div>
                                                </div>
                                                : null}

                                        </div>
                                    </div>
                                    :
                                    null
                                }
                                {state.rejectDisplay ?
                                    <div className='show'>
                                        <div className="row" style={{ marginTop: '10px' }}>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Username</label>
                                                    <input type="text" className="form-control" id="username" name="username" value={form.username} placeholder="" onChange={handleChange} />
                                                    <Error error={state.errors['name']} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Password</label>
                                                    <input type="password" className="form-control" id="password" name="password" value={form.password} placeholder="" onChange={handleChange} />
                                                    <Error error={state.errors['password']} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Confirm Password</label>
                                                    <input type="password" className="form-control" id="cpassword" name="cpassword" value={form.cpassword} placeholder="" onChange={handleChange} />
                                                    <Error error={state.errors['confirm']} />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Reason</label>
                                                    <input type="text" className="form-control" id="reason" name="reason" value={form.reason} placeholder="" onChange={handleChange} />
                                                    <Error error={state.errors['reason']} />
                                                </div>
                                            </div>
                                            {form.reason && form.username && form.password && form.cpassword && isEmpty(state.errors) ?
                                                <div className="col-md-12 form-group text-center">
                                                    <div className="col-md-12 text-center">
                                                        <span className="btn btn-block btn-gradient-primary btn-md fit-content font-weight-medium auth-form-btn m-auto" onClick={state.load ? null : handleReasonSubmit}>{state.load ? 'Submitting...' : 'Reject'}</span>
                                                    </div>
                                                </div>
                                                : null}
                                        </div>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-secondary" data-dismiss="modal" onClick={props.onClose}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <Message error={state.error} success={state.success} />
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    customer: state
})

const mapDispatchToProps = {
    approveDocument: documentActions.approveDocument,
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentApprove);