import React, { Component } from "react";
import "../assets/css/tc.css";
export class TermsAndCondition extends React.Component {
  constructor(props) {
    super(props);
    this.iframeIsLoaded = this.iframeIsLoaded.bind(this);
  }
  iframeIsLoaded() {
    document.getElementById('downloadReport').disabled = '';
    alert("Inside iframeloaded");
  }
  componentDidMount() {
    // var myiframe = document.getElementById('myiframe');
    // var firefox = true;
    // if (firefox) {
    //     myiframe.onload = function () { //this.iframeIsLoaded(); };
    // } else {
    //     alert('testing 1'); // this fires for IE
    //     myiframe.onreadystatechange = function() {
    //         alert('testing 2'); // this fires for IE
    //         if (myiframe.readyState == 'complete') {
    //             alert('testing 3'); // this never fires
    //             this.iframeIsLoaded();
    //         }
    //     }
    // }
  }
  render() {
    return (
      <div className="modal fade show">
        <div className="modal_inner" style={{  top:'20px', width: '800px', left: '50%',transform: "translate(-50%)"}}>
          <span
            className="mdi mdi-close-circle float-right modal-close"
            style={{ cursor: "pointer", paddingTop: "10px", fontSize: "20px" }}
            onClick={this.props.closePopup}></span>
          <div className="content">
            <div className="container text-center">
              <h1 style={{ marginTop: "-15px", background: '#0976b2', height: '40px', paddingTop: '10px', padding: '10px -10px 0px -10px !important', margin: '0px -20px 0px -20px', color: 'white',textAlign: 'center' }}>Terms And Conditions</h1>
              <div className="row d-flex justify-content-center" style={{ margin: "10px", maxHeight: "500px", overflow: 'auto', width: '100%', height: '500px' }}>
                <div className="col-md-12" >
                <p class="c22"><span class="c17 c23">Shed Paper, Inc. Terms of Use</span></p>
<p class="c0"><span class="c1">Last Modified: 25FEB2021</span></p>
<p class="c0"><span class="c7">Acceptance of the Terms of Use</span></p>
<p class="c0"><span class="c1">These terms of use are entered into by and between You and Shed Paper, Inc., a California corporation (&quot;</span><span class="c5">Company</span><span class="c1">,&quot; &quot;</span><span class="c5">we</span><span class="c1">,&quot; or &quot;</span><span class="c5">us</span><span class="c1">&quot;). The following terms and conditions, together with any documents they expressly incorporate by reference (collectively, &quot;</span><span class="c5">Terms of Use</span><span class="c1">&quot;), govern your access to and use of www.shedpaper.com, including any content, functionality, and services offered on or through </span><span class="c1"><a class="c9" href="https://www.google.com/url?q=http://www.shedpaper.com&amp;sa=D&amp;source=editors&amp;ust=1614482405350000&amp;usg=AOvVaw2ZbylH9WakWjsKkHoOHedl">www.shedpaper.com</a></span><span class="c1">&nbsp;and including any mobile app that we may offer (collectively, the &quot;</span><span class="c5">Website</span><span class="c1">&quot;), whether as a guest or a registered user.</span></p>
<p class="c0"><span class="c1">Please read the Terms of Use carefully before you start to use the Website. </span><span class="c5">By using the Website or by clicking to accept or agree to the Terms of Use when this option is made available to you, you accept and agree to be bound and abide by these Terms of Use and our Privacy Policy, found at </span><span class="c13 c10"><a class="c9" href="https://www.google.com/url?q=http://www.shedpaper.com/TC%2Bprivacy&amp;sa=D&amp;source=editors&amp;ust=1614482405351000&amp;usg=AOvVaw3l3UbVEZ3ku12lfmhJhAbx">www.shedpaper.com/TC+privacy</a></span><span class="c10 c13">,</span><span class="c1">&nbsp;</span><span class="c5">incorporated herein by reference.</span><span class="c1">&nbsp;If you do not want to agree to these Terms of Use or the Privacy Policy, you must not access or use the Website. </span></p>
<p class="c0"><span class="c7">Changes to the Terms of Use</span></p>
<p class="c0"><span class="c1">We may revise and update these Terms of Use from time to time in our sole discretion. All changes are effective immediately when we post them, and apply to all access to and use of the Website thereafter. </span></p>
<p class="c0"><span class="c1">Your continued use of the Website following the posting of revised Terms of Use means that you accept and agree to the changes. You are expected to check this page from time to time so you are aware of any changes, as they are binding on you. </span></p>
<p class="c0"><span class="c7">Accessing the Website and Account Security</span></p>
<p class="c0"><span class="c1">We reserve the right to withdraw or amend this Website, and any service or material we provide on the Website, in our sole discretion without notice. We will not be liable if for any reason all or any part of the Website is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the Website, or the entire Website, to users, including registered users.</span></p>
<p class="c0"><span class="c1">You are responsible for both:</span></p>
<ul class="c11 lst-kix_list_31-0 start">
   <li class="c2 li-bullet-0"><span class="c1">Making all arrangements necessary for you to have access to the Website.</span></li>
   <li class="c2 li-bullet-0"><span class="c1">Ensuring that all persons who access the Website through your internet connection are aware of these Terms of Use and comply with them.</span></li>
</ul>
<p class="c0"><span class="c1">To access the Website or some of the resources it offers, you may be asked to provide certain registration details or other information. It is a condition of your use of the Website that all the information you provide on the Website is correct, current, and complete. You agree that all information you provide to register with this Website or otherwise, including, but not limited to, through the use of any interactive features on the Website, is governed by our </span><span class="c10 c18 c28">Privacy Policy</span><span class="c1">&nbsp;</span><span class="c13 c10"><a class="c9" href="https://www.google.com/url?q=http://www.shedpaper.com/TC%2Bprivacy&amp;sa=D&amp;source=editors&amp;ust=1614482405353000&amp;usg=AOvVaw0TTC3GKD1dDqIOs0jmVs-e">www.shedpaper.com/TC+privacy</a></span><span class="c13 c10">,</span><span class="c1">&nbsp;and you consent to all actions we take with respect to your information consistent with our Privacy Policy.</span></p>
<p class="c0"><span class="c1">You also acknowledge that your account is personal to you and agree not to provide any other person with access to this Website or portions of it using any access credentials that we send to you via email or otherwise. You agree to notify us immediately of any unauthorized access to or use of your user name or password or any other breach of security. You also agree to ensure that you exit from your account at the end of each session. You should use particular caution when accessing your account from a public or shared computer so that others are not able to view or record your password or other personal information.</span></p>
<p class="c0"><span class="c1">We have the right to disable any user name, password, or other identifier, whether chosen by you or provided by us, at any time in our sole discretion for any or no reason, including if, in our opinion, you have violated any provision of these Terms of Use.</span></p>
<p class="c0"><span class="c7">Intellectual Property Rights</span></p>
<p class="c0"><span class="c1">The Website and its entire contents, including the Instructions for Use (IFUs) that you access via our Website and including any features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof) are owned by the Company, its licensors, or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.</span></p>
<p class="c0"><span class="c1">These Terms of Use permit you to use the Website and IFUs for your use only, or if you are accessing on behalf of your employer or are an agent for a company, then for your company&rsquo;s use as well. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our Website, except as follows:</span></p>
<ul class="c11 lst-kix_list_31-0">
   <li class="c2 li-bullet-0"><span class="c1">Your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials.</span></li>
   <li class="c2 li-bullet-0"><span class="c1">You may store files that are automatically cached by your Web browser for display enhancement purposes.</span></li>
   <li class="c2 li-bullet-0"><span class="c1">You may print a copy of a reasonable number of pages of the Website for your own personal, non-commercial use and not for further reproduction, publication, or distribution.</span></li>
   <li class="c2 li-bullet-0"><span class="c1">If we provide desktop, mobile, or other applications for download, you may download a single copy to your computer or mobile device solely for your own personal, non-commercial use, provided you agree to be bound by our end user license agreement for such applications.</span></li>
</ul>
<p class="c0"><span class="c1">You must not:</span></p>
<ul class="c11 lst-kix_list_31-0">
   <li class="c2 li-bullet-0"><span class="c1">Modify copies of any materials including the IFUs from this site.</span></li>
   <li class="c2 li-bullet-0"><span class="c1">Delete or alter any copyright, trademark, or other proprietary rights notices from copies of materials from this site.</span></li>
   <li class="c2 li-bullet-0"><span class="c1">Use the IFUs outside of the geographic region in which you are present.</span></li>
</ul>
<p class="c0"><span class="c1">You must not access or use any part of the Website, the IFUs or any services or materials available through the Website for purposes of any commercial use that may compete with or violate the intellectual property rights of Company or the licensors/owners of the IFUs. </span></p>
<p class="c0"><span class="c1">If you wish to make any use of material on the Website including the IFUs other than that set out in this section, please address your request to: info@shedpaper.com.</span></p>
<p class="c0"><span class="c1">If you print, copy, modify, download, or otherwise use or provide any other person with access to any part of the Website in breach of the Terms of Use, your right to use the Website will stop immediately and you must, at our option, return or destroy any copies of the materials you have made. No right, title, or interest in or to the Website or any content on the Website is transferred to you, and all rights not expressly granted are reserved by the Company. Any use of the Website not expressly permitted by these Terms of Use is a breach of these Terms of Use and may violate copyright, trademark, and other laws.</span></p>
<p class="c0"><span class="c7">Trademarks</span></p>
<p class="c0"><span class="c1">The Company name, the terms ShedPaper, the Company logo, and any logos or other branding set forth on the IFUs, &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;and all related names, logos, product and service names, designs, and slogans are trademarks of the Company or its affiliates or licensors. You must not use such marks without the prior written permission of the Company or licensors of such marks. All other names, logos, product and service names, designs, and slogans on this Website and the IFUs are the trademarks of their respective owners.</span></p>
<p class="c0"><span class="c7">Prohibited Uses</span></p>
<p class="c0"><span class="c1">You may use the Website and IFUs only for lawful purposes and in accordance with these Terms of Use. You agree not to use the Website and IFUs:</span></p>
<ul class="c11 lst-kix_list_31-0">
   <li class="c2 li-bullet-0"><span class="c1">In any way that violates any applicable federal, state, local, or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from the US or other countries). </span></li>
   <li class="c2 li-bullet-0"><span class="c1">For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information, or otherwise.</span></li>
   <li class="c2 li-bullet-0"><span class="c1">To transmit, or procure the sending of, any advertising or promotional material, including any &quot;junk mail,&quot; &quot;chain letter,&quot; &quot;spam,&quot; or any other similar solicitation.</span></li>
   <li class="c2 li-bullet-0"><span class="c1">To impersonate or attempt to impersonate the Company, a Company employee, another user, or any other person or entity (including, without limitation, by using email addresses associated with any of the foregoing).</span></li>
   <li class="c2 li-bullet-0"><span class="c1">To engage in any other conduct that restricts or inhibits anyone&#39;s use or enjoyment of the Website, or which, as determined by us, may harm the Company or users of the Website, or expose them to liability.</span></li>
   <li class="c2 li-bullet-0"><span class="c1">To provide the IFUs to a competing manufacturer or to establish a competing business with Company or the licensors/owners of the IFUs.</span></li>
</ul>
<p class="c0"><span class="c1">Additionally, you agree not to:</span></p>
<ul class="c11 lst-kix_list_31-0">
   <li class="c2 li-bullet-0"><span class="c1">Use the Website in any manner that could disable, overburden, damage, or impair the site or interfere with any other party&#39;s use of the Website, including their ability to engage in real time activities through the Website.</span></li>
   <li class="c2 li-bullet-0"><span class="c1">Use any robot, spider, or other automatic device, process, or means to access the Website for any purpose, including monitoring or copying any of the material on the Website.</span></li>
   <li class="c2 li-bullet-0"><span class="c1">Use any manual process to monitor or copy any of the material on the Website, or for any other purpose not expressly authorized in these Terms of Use, without our prior written consent.</span></li>
   <li class="c2 li-bullet-0"><span class="c1">Use any device, software, or routine that interferes with the proper working of the Website.</span></li>
   <li class="c2 li-bullet-0"><span class="c1">Introduce any viruses, Trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful.</span></li>
   <li class="c2 li-bullet-0"><span class="c1">Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Website, the server on which the Website is stored, or any server, computer, or database connected to the Website. </span></li>
   <li class="c2 li-bullet-0"><span class="c1">Attack the Website via a denial-of-service attack or a distributed denial-of-service attack.</span></li>
   <li class="c2 li-bullet-0"><span class="c1">Otherwise attempt to interfere with the proper working of the Website.</span></li>
</ul>
<p class="c0"><span class="c7">Changes to the Website</span></p>
<p class="c0"><span class="c1">We may update the content on this Website from time to time, but its content is not necessarily complete or up-to-date. Any of the material on the Website may be out of date at any given time, and we are under no obligation to update such material. </span></p>
<p class="c0"><span class="c7">Information About You and Your Visits to the Website</span></p>
<p class="c0"><span class="c1">All information we collect on this Website is subject to our Privacy Policy </span><span class="c13 c10"><a class="c9" href="https://www.google.com/url?q=http://www.shedpaper.com/TC%2Bprivacy&amp;sa=D&amp;source=editors&amp;ust=1614482405357000&amp;usg=AOvVaw3YHAI8T-R__pu-_wTk001a">www.shedpaper.com/TC+privacy</a></span><span class="c1">. By using the Website, you consent to all actions taken by us with respect to your information in compliance with the Privacy Policy. </span></p>
<p class="c0"><span class="c7">Links from the Website</span></p>
<p class="c0"><span class="c1">If the Website contains links to other sites and resources provided by third parties, these links are provided for your convenience only. This includes links contained in advertisements, including banner advertisements and sponsored links. We have no control over the contents of those sites or resources, and accept no responsibility for them or for any loss or damage that may arise from your use of them. If you decide to access any of the third-party websites linked to this Website, you do so entirely at your own risk and subject to the terms and conditions of use for such websites.</span></p>
<p class="c0"><span class="c7">Geographic Restrictions</span></p>
<p class="c0"><span class="c1">The owner of the Website is based in the State of California in the United States. We provide this Website for use only by persons worldwide. We make no claims that the Website or any of its content is accessible or appropriate outside of the territories intended for use of the IFUs. Access to the Website may not be legal by certain persons or in certain countries. If you access the Website from outside an intended territory of distribution of the IFUs you do so on your own initiative and are responsible for compliance with local laws.</span></p>
<p class="c0"><span class="c7">Disclaimer of Warranties</span></p>
<p class="c0"><span class="c1">You understand that we cannot and do not guarantee or warrant that IFUs or other files available for downloading from the internet or the Website will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our site for any reconstruction of any lost data. TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.</span></p>
<p class="c0"><span class="c1">YOUR USE OF THE WEBSITE, IFUS AND OTHER CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE PROVIDED ON AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE WEBSITE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE WEBSITE, ITS CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS. </span></p>
<p class="c0"><span class="c1">TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.</span></p>
<p class="c0"><span class="c1">THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</span></p>
<p class="c0"><span class="c7">Limitation on Liability</span></p>
<p class="c0"><span class="c1">TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COMPANY, ITS AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE. </span></p>
<p class="c0"><span class="c1">TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COLLECTIVE LIABILITY OF THE COMPANY AND ITS SUBSIDIARIES AND AFFILIATES, AND THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, AND DIRECTORS, TO ANY PARTY (REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT, OR OTHERWISE) EXCEED FIFTY DOLLARS $50.</span></p>
<p class="c0"><span class="c1">The limitation of liability set out above does not apply to liability resulting from our gross negligence or willful misconduct.</span></p>
<p class="c0"><span class="c1">THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</span></p>
<p class="c0"><span class="c7">Indemnification</span></p>
<p class="c0"><span class="c1">You agree to defend, indemnify, and hold harmless the Company, its affiliates, licensors, and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys&#39; fees) arising out of or relating to your violation of these Terms of Use or your use of the Website, including, but not limited to, your use of the IFUs, any use of the Website&#39;s content, services, and products other than as expressly authorized in these Terms of Use, or your use of any information obtained from the Website.</span></p>
<p class="c0"><span class="c7">Governing Law and Jurisdiction</span></p>
<p class="c0"><span class="c1">All matters relating to the Website and these Terms of Use, and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with the internal laws of the State of California without giving effect to any choice or conflict of law provision or rule (whether of the State of California or any other jurisdiction).</span></p>
<p class="c0"><span class="c1">Any legal suit, action, or proceeding arising out of, or related to, these Terms of Use or the Website shall be instituted exclusively in the federal courts of the United States or the courts of the State of California, in each case located in the City of Santa Ana and County of Orange, although we retain the right to bring any suit, action, or proceeding against you for breach of these Terms of Use in your country of residence or any other relevant country. You waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.</span></p>
<p class="c0"><span class="c7">Waiver and Severability</span></p>
<p class="c0"><span class="c1">No waiver by the Company of any term or condition set out in these Terms of Use shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of the Company to assert a right or provision under these Terms of Use shall not constitute a waiver of such right or provision.</span></p>
<p class="c0"><span class="c1">If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to be invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms of Use will continue in full force and effect. </span></p>
<p class="c0"><span class="c7">Entire Agreement</span></p>
<p class="c0"><span class="c1">The Terms of Use, our Privacy Policy and Terms of Service (if applicable) constitute the sole and entire agreement between you and Shed Paper, Inc. regarding the Website and supersede all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, regarding the Website. </span></p>
<p class="c0"><span class="c7">Your Comments and Concerns</span></p>
<p class="c0"><span class="c1">This website is operated by Shed Paper, Inc. located at: 231 E. Alessandro Blvd. #376 Riverside, CA 92508.</span></p>
<p class="c0"><span class="c1">All other feedback, comments, requests for technical support, and other communications relating to the Website should be directed to: </span><span class="c20 c10 c26"><a class="c9" href="mailto:info@shedpaper.com">info@shedpaper.com</a></span></p>
<p class="c0 c14"><span class="c1"></span></p>
<p class="c0 c14"><span class="c1"></span></p>
<p class="c12"><span class="c8"></span></p>
<p class="c12"><span class="c8"></span></p>
<p class="c12"><span class="c8"></span></p>
<p class="c12"><span class="c8"></span></p>
<p class="c12"><span class="c8"></span></p>
<p class="c12"><span class="c8"></span></p>
<p class="c12"><span class="c8"></span></p>
<p class="c12"><span class="c8"></span></p>
<p class="c12"><span class="c8"></span></p>
<p class="c22"><span class="c8">ShedPaper Website Privacy Policy</span></p>
<p class="c0"><span class="c1">Last modified: 25FEB2021</span></p>
<p class="c0 c14"><span class="c7"></span></p>
<p class="c0"><span class="c17 c20">Introduction</span></p>
<p class="c0"><span class="c10">Shed Paper, Inc. (</span><span class="c17">&quot;Company&quot;</span><span class="c10">&nbsp;or</span><span class="c17">&nbsp;&quot;We&quot;</span><span class="c1">) respect your privacy and are committed to protecting it through our compliance with this policy.</span></p>
<p class="c0"><span class="c20 c17 c21">We only collect the following personal information from end users for purposes of performing our services through the Website:</span></p>
<ul class="c11 lst-kix_1obh0ds7jomg-0 start">
   <li class="c0 c3 li-bullet-0"><span class="c23 c17">Your E-Mail Address</span></li>
</ul>
<p class="c0"><span class="c10">This policy describes what we do with your personal information that we collect, again, it is limited at this time to e-mail address unless you submit other personal information to us through a Contact Us button or email address listed either in this Privacy Policy or on our Website, but we encourage you NOT to submit your personal information to us and we do not need any personal information other than your e-mail to perform the services to end users on or through </span><span class="c10"><a class="c9" href="https://www.google.com/url?q=http://www.shedpaper.com&amp;sa=D&amp;source=editors&amp;ust=1614482405363000&amp;usg=AOvVaw1kfqPg7DlcQiKjNX_SW4TT">www.Shedpaper.com</a></span><span class="c10">&nbsp;(our &quot;</span><span class="c17">Website</span><span class="c1">&quot;). &nbsp;Despite our limited collection of personal information this Privacy Policy describes our practices for collecting, using, maintaining, protecting, and disclosing that information.</span></p>
<p class="c0"><span class="c1">This policy applies to information we collect:</span></p>
<ul class="c11 lst-kix_409nmwjwxlda-0 start">
   <li class="c2 li-bullet-0"><span class="c1">On our Website.</span></li>
   <li class="c2 li-bullet-0"><span class="c1">In email, text, and other electronic messages between you and this Website.</span></li>
</ul>
<p class="c0"><span class="c1">It does not apply to information collected by:</span></p>
<ul class="c11 lst-kix_409nmwjwxlda-0">
   <li class="c2 li-bullet-0"><span class="c1">Us offline or through any other means, including on any other website operated by Company or any third party including our licensors of Information For Use (IFU) content on our Website; or </span></li>
   <li class="c2 li-bullet-0"><span class="c1">Any third party, including through any application or content (including advertising) that may link to or be accessible from the Website.</span></li>
</ul>
<p class="c0"><span class="c10">Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, your choice is not to use our Website. By accessing or using this Website, you agree to this privacy policy. This policy may change from time to time (see </span><span class="c10 c24"><a class="c9" href="https://www.google.com/url?q=https://docs.google.com/document/d/18FVfoVJcqPfcPybbDEj9UzwS8mdel9If/edit%23bookmark%3Did.17dp8vu&amp;sa=D&amp;source=editors&amp;ust=1614482405365000&amp;usg=AOvVaw0u4bO7Ij7tt0PAA1U0CFaX">Changes to Our Privacy Policy</a></span><span class="c1">). Your continued use of this Website after we make changes is deemed to be acceptance of those changes, so please check the policy periodically for updates. </span></p>
<p class="c0 c14"><span class="c7"></span></p>
<p class="c0 c14"><span class="c7"></span></p>
<p class="c0"><span class="c7">If You Live in the European Union:</span></p>
<p class="c0"><span class="c10">Please see our European Union Resident Data Privacy Notice </span><span class="c20 c10"><a class="c9" href="https://www.google.com/url?q=http://www.shedpaper.com/TC%2Bprivacy&amp;sa=D&amp;source=editors&amp;ust=1614482405366000&amp;usg=AOvVaw0OdApUQdimjcjs3bwO_1kd">d</a></span><span class="c20 c10">.</span></p>
<p class="c0 c14"><span class="c7"></span></p>
<p class="c0"><span class="c7">Information We Collect About You and How We Collect It</span></p>
<p class="c0"><span class="c1">We collect the following personal information from and about end users of our Website:</span></p>
<ul class="c11 lst-kix_409nmwjwxlda-0">
   <li class="c2 li-bullet-0"><span class="c1">E-mail address;</span></li>
   <li class="c2 li-bullet-0"><span class="c1">About your internet connection, the equipment you use to access our Website, and usage details.</span></li>
</ul>
<p class="c0"><span class="c1">We collect this information:</span></p>
<ul class="c11 lst-kix_409nmwjwxlda-0">
   <li class="c2 li-bullet-0"><span class="c1">Directly from you when you provide it to us.</span></li>
   <li class="c2 li-bullet-0"><span class="c1">Automatically as you navigate through the site. </span></li>
   <li class="c2 li-bullet-0"><span class="c10">From third parties, for example, our customers or licensors/owners of IFUs.</span><span class="c25">&nbsp;</span></li>
</ul>
<p class="c0"><span class="c10 c18">Information You Provide to Us</span><span class="c5">&nbsp; </span></p>
<p class="c0"><span class="c1">The information we collect on or through our Website may include:</span></p>
<ul class="c11 lst-kix_409nmwjwxlda-0">
   <li class="c2 li-bullet-0"><span class="c1">Information that you provide by filling in forms on our Website. This includes information provided at the time of requesting our services or access to portions of our Website including the IFU database we provide. &nbsp;We may also ask you for information when you contact us by email or other button on our Website or when you report a problem with our Website.</span></li>
   <li class="c2 li-bullet-0"><span class="c1">Records and copies of your correspondence (including email addresses), if you contact us.</span></li>
</ul>
<p class="c0"><span class="c10 c18">Information We Collect Through Automatic Data Collection Technologies</span><span class="c15">&nbsp; </span></p>
<p class="c0"><span class="c1">As you navigate through and interact with our Website, we may use automatic data collection technologies to collect certain information about your equipment, browsing actions, and patterns, including:</span></p>
<ul class="c11 lst-kix_409nmwjwxlda-0">
   <li class="c2 li-bullet-0"><span class="c1">&nbsp;Details of your visits to our Website, including traffic data, location data, logs, and other communication data and the resources that you access and use on the Website.</span></li>
   <li class="c2 li-bullet-0"><span class="c1">Information about your computer and internet connection, including your browser&rsquo;s country location, operating system, and browser type.</span></li>
</ul>
<p class="c0"><span class="c1">The information we collect automatically is only statistical data and does not include personal information, but we do collect the country of origin for your request based on your browser information that is collected either by us or from third parties. It helps us to improve our Website and to deliver a better and more personalized service, including by enabling us to:</span></p>
<ul class="c11 lst-kix_409nmwjwxlda-0">
   <li class="c2 li-bullet-0"><span class="c1">To optimize search results related to provision of appropriate IFUs to you.</span></li>
   <li class="c2 li-bullet-0"><span class="c1">Estimate our audience size and usage patterns.</span></li>
   <li class="c2 li-bullet-0"><span class="c1">Store information about your preferences, allowing us to customize our Website according to your individual interests.</span></li>
   <li class="c2 li-bullet-0"><span class="c1">Speed up your searches.</span></li>
   <li class="c2 li-bullet-0"><span class="c1">Recognize you when you return to our Website.</span></li>
</ul>
<p class="c0"><span class="c1">The technologies we use for this automatic data collection may include:</span></p>
<ul class="c11 lst-kix_409nmwjwxlda-0">
   <li class="c2 li-bullet-0"><span class="c17">Cookies (or browser cookies).</span><span class="c10">&nbsp;A cookie is a small file placed on the hard drive of your computer. You may refuse to accept browser cookies by activating the appropriate setting on your browser. However, if you select this setting you may be unable to access certain parts of our Website. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you direct your browser to our Website. </span></li>
   <li class="c2 li-bullet-0"><span class="c17">Flash Cookies.</span><span class="c10">&nbsp;Certain features of our Website may use local stored objects (or Flash cookies) to collect and store information about your preferences and navigation to, from, and on our Website. Flash cookies are not managed by the same browser settings as are used for browser cookies. For information about managing your privacy and security settings for Flash cookies, see </span><span class="c10"><a class="c9" href="https://www.google.com/url?q=https://docs.google.com/document/d/18FVfoVJcqPfcPybbDEj9UzwS8mdel9If/edit%23bookmark%3Did.4d34og8&amp;sa=D&amp;source=editors&amp;ust=1614482405370000&amp;usg=AOvVaw1mlnxabWziJq5dFSSAXd7n">Choices About How We Use and Disclose Your Information</a></span><span class="c10">.</span></li>
</ul>
<p class="c0"><span class="c7">How We Use Your Information</span></p>
<p class="c0"><span class="c1">We use information that we collect about you or that you provide to us, including any personal information:</span></p>
<ul class="c11 lst-kix_409nmwjwxlda-0">
   <li class="c2 li-bullet-0"><span class="c1">To present our Website and its contents to you.</span></li>
   <li class="c2 li-bullet-0"><span class="c1">To provide you with access to the IFU database maintained on the Website.</span></li>
   <li class="c2 li-bullet-0"><span class="c1">To provide you with information, products, or services that you request from us.</span></li>
   <li class="c2 li-bullet-0"><span class="c1">To fulfill any other purpose for which you provide it.</span></li>
   <li class="c2 li-bullet-0"><span class="c1">In any other way we may describe when you provide the information.</span></li>
   <li class="c2 li-bullet-0"><span class="c1">For any other purpose with your consent.</span></li>
</ul>
<p class="c0"><span class="c7">Disclosure of Your Information</span></p>
<p class="c0"><span class="c1">We may disclose aggregated information about our users, and information that does not identify any individual, without restriction. </span></p>
<p class="c0"><span class="c1">We may disclose personal information that we collect or you provide as described in this privacy policy:</span></p>
<ul class="c11 lst-kix_409nmwjwxlda-0">
   <li class="c2 li-bullet-0"><span class="c1">To our licensors/owners of IFUs, their and our subsidiaries and affiliates.</span></li>
   <li class="c2 li-bullet-0"><span class="c1">To contractors, service providers, and other third parties we use to support our business.</span></li>
   <li class="c2 li-bullet-0"><span class="c1">To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Shed Paper&#39;s assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by Shed Paper about our Website users is among the assets transferred.</span></li>
   <li class="c2 li-bullet-0"><span class="c1">To fulfill the purpose for which you provide it. </span></li>
   <li class="c2 li-bullet-0"><span class="c1">For any other purpose disclosed by us when you provide the information.</span></li>
   <li class="c2 li-bullet-0"><span class="c1">With your consent.</span></li>
</ul>
<p class="c0"><span class="c1">We may also disclose your personal information:</span></p>
<ul class="c11 lst-kix_409nmwjwxlda-0">
   <li class="c2 li-bullet-0"><span class="c1">To comply with any court order, law, or legal process, including to respond to any government or regulatory request.</span></li>
   <li class="c2 li-bullet-0"><span class="c1">To enforce or apply our terms of use and other agreements, including for billing and collection purposes.</span></li>
   <li class="c2 li-bullet-0"><span class="c1">If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of Shedpaper, our customers, or others. </span></li>
</ul>
<p class="c0"><span class="c7">Choices About How We Use and Disclose Your Information</span></p>
<p class="c0"><span class="c1">We strive to provide you with choices regarding the personal information you provide to us. We have created mechanisms to provide you with the following control over your information: </span></p>
<ul class="c11 lst-kix_409nmwjwxlda-0">
   <li class="c2 li-bullet-0"><span class="c17">Tracking Technologies and Advertising.</span><span class="c10">&nbsp;You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. To learn how you can manage your Flash cookie settings, visit the Flash player settings page on Adobe&#39;s website. If you disable or refuse cookies, please note that some parts of this site may then be inaccessible or not function properly.</span></li>
</ul>
<p class="c0"><span class="c10">We do not control third parties&#39; collection or use of your information to serve interest-based advertising. However these third parties may provide you with ways to choose not to have your information collected or used in this way. You can opt out of receiving targeted ads from members of the Network Advertising Initiative (&quot;</span><span class="c17">NAI</span><span class="c1">&quot;) on the NAI&#39;s website.</span></p>
<p class="c0"><span class="c7">Data Security</span></p>
<p class="c0"><span class="c1">We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. </span></p>
<p class="c0"><span class="c7">Changes to Our Privacy Policy</span></p>
<p class="c0"><span class="c1">It is our policy to post any changes we make to our privacy policy on this page. If we make material changes to how we treat our users&#39; personal information, we will notify you through a notice on the Website home page. The date the privacy policy was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date active and deliverable email address for you, and for periodically visiting our Website and this privacy policy to check for any changes.</span></p>
<p class="c0"><span class="c7">Contact Information</span></p>
<p class="c0"><span class="c1">To ask questions or comment about this privacy policy and our privacy practices, contact us at: </span></p>
<p class="c0 c14"><span class="c1"></span></p>
<p class="c4"><span class="c6">Shed Paper, Inc.</span></p>
<p class="c4"><span class="c6">376 E Alessandro Blvd. #376</span></p>
<p class="c4"><span class="c6">Riverside, California, 92508</span></p>
<p class="c4"><span class="c6">United States of America</span></p>
<p class="c4 c14"><span class="c6"></span></p>
<p class="c4"><span class="c6">info@shedpaper.com</span></p>
<p class="c0"><span class="c1">&nbsp;</span></p>
<p class="c0 c14"><span class="c1"></span></p>
<p class="c0 c14"><span class="c1"></span></p>
<div>
   <p class="c14 c27"><span class="c16"></span></p>
   <p class="c0"><span class="c16">Shed Paper, Inc. Website Terms of Use</span></p>
   <p class="c0"><span class="c16">February 2021&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}