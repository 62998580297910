import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { userActions } from '../_actions';
import { connect } from 'react-redux';

class Sidenav extends React.Component {
  state = { menu: [], active: 'home', view: null, myRole: 'USER' };
  handleNavbar = e => {
    let { menu } = this.state;
    this.setState({ menu: [e.target.textContent], active: e.target.textContent });
  }
  handleLogout = e => {
    this.props.logout();
  }

  componentDidMount() {
    let user = this.props.auth;
    let role = this.props.role;
    this.setState({ myRole: role ? role : 'USER' });
  }

  render() {
    let { active, menu, view } = this.state;
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav">
          <li className={active === 'Home' ? "nav-item active font-weight-bold" : "nav-item"}>
            <br /><br />
            <Link className="nav-link" to="/" onClick={this.handleNavbar}>
              <span className="menu-title">Home</span>
              <i className="mdi mdi-home menu-icon"></i>
            </Link>
          </li>
          <li className={active === 'Documents' ? "nav-item active font-weight-bold" : "nav-item"}>
            <Link className="nav-link" to="/document/list" onClick={this.handleNavbar}>
              <span className="menu-title">Documents</span>
              <i className="mdi mdi-file-multiple menu-icon"></i>
            </Link>
          </li>
          {this.state.myRole === 'ADMIN' && <li className={active === 'Manage User' ? "nav-item active font-weight-bold" : "nav-item"}>
            <Link className="nav-link" to="/manageuser" onClick={this.handleNavbar}>
              <span className="menu-title">Manage User</span>
              <i className="mdi mdi-account menu-icon"></i>
            </Link>
          </li>}
          {this.state.myRole === 'ADMIN' && <li className={active === 'Manage Customer' ? "nav-item active font-weight-bold" : "nav-item"}>
            <Link className="nav-link" to="/managecustomer" onClick={this.handleNavbar}>
              <span className="menu-title">Manage Customer</span>
              <i className="mdi mdi-account-multiple menu-icon "></i>
            </Link>
          </li>}
          {this.state.myRole === 'ADMIN' && <li className={active === 'Manage Notification' ? "nav-item active font-weight-bold" : "nav-item"}>
            <Link className="nav-link" to="/managenotification" onClick={this.handleNavbar}>
              <span className="menu-title">Manage Notification</span>
              <i className="mdi mdi-account-multiple menu-icon "></i>
            </Link>
          </li>}
          <li className={active === 'Logout' ? "nav-item active font-weight-bold" : "nav-item"} onClick={this.handleLogout}>
            <span className="nav-link">
              <button className="btn btn-block btn-md btn-gradient-primary mt-4">Logout</button>
            </span>
          </li>
        </ul>
      </nav>
    )
  }
}

function mapStateToProps(state) {
  const users = state.users.items;
  const auth = state.authentication.user;
  const role = state.authentication.role;
  return { users, auth, role };
}

const actionCreators = {
  logout: userActions.logout,
};

function mapState(state) {
}

const SidenavPage = connect(mapStateToProps, actionCreators)(Sidenav);
export default SidenavPage;