import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../_actions';
import { Checkbox } from '@material-ui/core';
import { validateEmail, isEmpty, Error } from './Validation';

class AddUserModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.modalData.id,
      firstName: props.modalData.firstName,
      email: props.modalData.email,
      lastName: props.modalData.lastName,
      role: props.modalData.role,
      isTrainingCompleted: props.modalData.isTrainingCompleted,
      errors: {},
      training: false,
      load: false,
      myRole: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckBox = this.handleCheckBox.bind(this);
  }

  componentDidMount() {
    this.setState({ myRole: this.props.myRole });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true, load: true });
    if (isEmpty(this.validation())) {
      const userData = {
        id: this.state.id,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        role: this.state.role || 'USER',
        isTrainingCompleted: true
      }

      if (this.props.modalData.action === 'Add User') {
        this.props.addUser(userData);
        this.props.closePopup();
        this.setState({ load: false });
      } else {
        this.props.updateUser(userData);
        this.props.closePopup();
        this.setState({ load: false });
      }
    } else {
      this.setState({ load: false });
    }

  }

  validation = () => {
    const { firstName, lastName, email, isTrainingCompleted } = this.state;

    let errors = {};
    if (!firstName) errors['fname'] = 'Enter First Name'; else if (!isNaN(firstName)) errors['fname'] = 'Should not be a Number';
    if (!lastName) errors['lname'] = 'Enter Last Name';
    if (!email) errors['email'] = 'Enter Email'; else if (!validateEmail(email)) errors['email'] = 'Invalid Email Address';
    if (!isTrainingCompleted) errors['training'] = 'Training is required';
    this.setState({ errors: errors });
    return errors;
  }

  handleCheckBox = (event) => {
    this.setState({ role: event.target.checked ? 'ADMIN' : 'USER' });
  };

  handleTraining = (event) => {
    // console.log(event.target)
    this.setState({ isTrainingCompleted: this.state.isTrainingCompleted ? false : true });
  };

  handleChange(e) {
    const { name, value } = e.target;
    // console.log(name, value)
    this.setState({ [name]: value }, () => { this.checkValidation() });
  }

  checkValidation = () => {
    const { firstName, email } = this.state;

    let errors = {};
    if (email && !validateEmail(email)) errors['email'] = 'Invalid Email Address';
    if (firstName && !isNaN(firstName)) errors['fname'] = 'Should not be a Number';
    this.setState({ errors: errors });
  }

  render() {
    return (
      <div className='modal fade show'>
        <div className='modal_inner'>
          <h1 className="text-center pt-3" style={{ fontFamily: "Times New Roman", fontSize: "20px" }}>
            <i style={{ fontSize: '21px' }} className={this.state.id ? "mdi mdi-account-edit menu-icon ml-3" : "mdi mdi-account-plus menu-icon ml-3"}></i>     {this.props.modalData.action}
            <span className="mdi mdi-close-circle float-right modal-close" style={{ cursor: 'pointer' }} onClick={this.props.closePopup}></span>
          </h1>
          <hr />
          <form style={{ padding: '10px' }}>

            <div className="row  mt-4 mb-4 form-row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>First Name</label>
                  <input type="text" className="form-control form-control-lg" id="" placeholder="First Name" name="firstName" value={this.state.firstName} onChange={this.handleChange} />
                  <Error error={this.state.errors['fname']} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Last Name</label>
                  <input type="text" className="form-control form-control-lg" id="" placeholder="Last Name" name="lastName" value={this.state.lastName} onChange={this.handleChange} />
                  <Error error={this.state.errors['lname']} />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>E-Mail</label>
                  <input type="text" className="form-control form-control-lg" id="" placeholder="Email" name="email" value={this.state.email} onChange={this.handleChange} />
                  <Error error={this.state.errors['email']} />
                </div>
              </div>
              {this.state.myRole === 'ADMIN' ?
                <div className="col-md-6">
                  <div className="form-group">
                    <Checkbox color='primary' name='role' checked={this.state.role == 'ADMIN' ? true : false} onChange={this.handleCheckBox}></Checkbox>
                    <label style={{ marginTop: "13px", paddingLeft: "0px" }}>Admin</label>
                  </div>
                </div>
                : null}
              <div className="col-md-6">
                <div className="form-group">
                  <Checkbox color='primary' name='training' checked={this.state.isTrainingCompleted == true ? true : false} onChange={this.handleTraining}></Checkbox>
                  <label style={{ marginTop: "13px", paddingLeft: "0px" }}>Is Training Completed?</label><br />
                  <Error error={this.state.errors['training']} />
                </div>
              </div>
            </div>
            <div className="mt-2 text-center">
              {this.state.load ?
                <a className="btn btn-block btn-secondary btn-md fit-content font-weight-medium auth-form-btn m-auto">SUBMITTING...</a>
                :
                <a className={"btn btn-block btn-gradient-primary btn-md fit-content font-weight-medium auth-form-btn m-auto"} onClick={this.handleSubmit}>{this.state.id ? 'UPDATE' : 'SUBMIT'}</a>
              }
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  state: state,
})
const actionCreators = {
  addUser: userActions.addUser,
  updateUser: userActions.updateUser
};

const connectedPage = connect(mapStateToProps, actionCreators)(AddUserModal);
export { connectedPage as AddUserModal };
