import { userConstants } from '../_constants';
import { API } from '../_services';
import { history, authHeader } from '../_helpers';
import axios from 'axios';
import { APIURL } from '../_constants/api.config';

export const customerActions = {
    addCustomer,
    updateCustomer,
    listCustomers,
    addSelectedCustomer,
    removeSelectedCustomer,
    removeMessage,
    getApprovalInitiatorList,
    approveCustomer
};

// function addSelectedCustomer(selectedCustomer) {
//     return dispatch => {
//         dispatch(add(selectedCustomer));
//     };

//     function add(selectedCustomer) {
//         return {
//             type: userConstants.ADD_SELECTED_CUSTOMER,
//             selectedCustomer
//         }
//     }
// }

function addSelectedCustomer(selectedCustomer) {
    return dispatch => {
        // let selectedLogo = await API.getLogo(selectedCustomer.id);
        API.getLogo(selectedCustomer.id).then(logos => {
            // console.log('logos', logos)
            dispatch(logo(logos));
        }).catch(error => {
            // console.log('logo error', error)
            dispatch(logo(null));
        })
        dispatch(add(selectedCustomer));
    };

    function add(selectedCustomer) {
        return {
            type: userConstants.ADD_SELECTED_CUSTOMER,
            selectedCustomer,
            // selectedLogo
        }
    }

    function logo(selectLogo) {
        return {
            type: userConstants.GET_LOGO,
            selectLogo,
            // selectedLogo
        }
    }
}

function addCustomer(customerData) {
    // console.log("Action Add Customer Called");
    return dispatch => {
        API.addCustomer(customerData)
            .then(
                user => {
                    dispatch(success('Customer Added Successfully!!'));
                    //  listCustomers();
                    history.push('/managecustomer');
                },
                error => {
                    dispatch(failure('Failed to Add Customer!!'));
                }
            );
    };

    function success(message) {
        return {
            type: userConstants.CHECK_ADD_CUSTOMER_SUCCESS,
            message
        }
    }

    function failure(error) {
        return {
            type: userConstants.CHECK_ADD_CUSTOMER_FAILURE,
            error
        }
    }
}

function updateCustomer(customerData) {
    // console.log("Action Update Customer Called");
    return dispatch => {
        API.updateCustomer(customerData)
            .then(
                response => {
                    dispatch(success('Customer updated successfully!!'));
                    history.push('/managecustomer');
                },
                error => {
                    dispatch(failure('Failed to update Customer!!'));
                }
            );
    };

    function success(message) {
        return {
            type: userConstants.CHECK_UPDATE_CUSTOMER_SUCCESS,
            message
        }
    }

    function failure(message) {
        return {
            type: userConstants.CHECK_UPDATE_CUSTOMER_FAILURE,
            message
        }
    }
}


function listCustomers() {
    // console.log("Action List Customers Called");
    return dispatch => {
        API.listCustomers()
            .then(
                customers => {
                    // console.log('customers', customers)
                    dispatch(successList(customers));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function successList(customers) {
        return {
            type: userConstants.CHECK_LIST_CUSTOMER_SUCCESS,
            customers
        }
    }

    function failure(error) {
        return {
            type: userConstants.CHECK_LIST_CUSTOMER_FAILURE,
            error
        }
    }
}


function removeSelectedCustomer(selectedCustomer) {
    return dispatch => {
        dispatch(remove(selectedCustomer));
    };

    function remove(selectedCustomer) {
        return {
            type: userConstants.REMOVE_SELECTED_CUSTOMER,
            selectedCustomer
        }
    }
}

function removeMessage(message) {
    return dispatch => {
        dispatch(remove(message));
    };

    function remove(message) {
        return {
            type: userConstants.REMOVE_ADD_UPDATE_MESSAGE,
            message
        }
    }
}

function getApprovalInitiatorList() {
    return dispatch => {
        API.getApprovalInitiatorList()
            .then(
                approvalInitiatorList => {
                    dispatch(success(approvalInitiatorList));
                },
                error => {
                    error = "Failed to fetch approval initiator list."
                    dispatch(failure(error));
                }
            );
    };

    function success(approvalInitiatorList) {
        return {
            type: userConstants.APPROVER_INITIATOR_FETCH_SUCCESS,
            approvalInitiatorList
        };
    }

    function failure(error) {
        return {
            type: userConstants.APPROVER_INITIATOR_FETCH_FAILURE,
            error
        };
    }
}

function approveCustomer(data) {
    return dispatch => {
        API.approveCustomer(data)
            .then(
                message => {
                    // console.log(message)
                    dispatch(success(message.message));
                },
                error => {
                    dispatch(failure("Customer approval failed."));
                }
            ).catch(err => {
                // console.log('error here', err)
            })
    };

    function success(message) {
        return {
            type: userConstants.APPROVE_CUSTOMER_SUCCESS,
            message
        }
    }

    function failure(message) {
        return {
            type: userConstants.APPROVE_CUSTOMER_FAILURE,
            message
        }
    }
}