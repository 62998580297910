import React from 'react';
import '../assets/css/modal.css';
import '../assets/css/dataTable.css';
import Message from "../_components/Message";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {NotificationList} from "../NotificationPage";

export class ManageNotification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error:'',
            message: ''
        };
    };
      
    render() {
      const message = this.state.message;
      const error = this.state.error;
        return (  
          <div className="container-fluid">
            <Message error={error} success={message} />
            <div className="content-wrapper main-bg"></div>
            <div className="page-header">
              <div className="row w-100">
                <div className="col-md-6">
                  <h3 className="page-title">
                    <span className="page-title-icon bg-gradient-primary text-white mr-2">
                      <i className="mdi mdi-file-multiple"></i>
                    </span>
                    Notification List
                  </h3>
                </div>
                <div className="col-md-6">
                  <Link
                    to="/notification/add"
                    className="btn btn-sm btn-info float-right ml-auto">
                    <i className="mdi mdi-plus-circle"></i> Add Notification
                  </Link>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 grid-margin">
                <div className="card">
                  <div className="card-body">
                     <NotificationList/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
    }
}