import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { store } from '../_helpers';
import { userActions } from '../_actions';
import { API } from '../_services';
import logo from '../assets/images/shedpaper.jpg';

import '../LoginPage/login.css'

class SendRequest extends React.Component {
    constructor() {
        super();
        this.state = {
            error: ''
        };
        this.notifyAdmin = this.notifyAdmin.bind(this);
    };


    notifyAdmin(e) {
        e.preventDefault();
        this.props.notifyAdmin(this.props.location.state.username);
    }

    render() {
        return (
            <div className="container-scroller">
                <div className="container-fluid page-body-wrapper full-page-wrapper">
                    <div className="content-wrapper d-flex align-items-center auth">
                        <div className="row w-100">
                            <div className="col-lg-5 col-md-6 mx-auto">
                                <div className="auth-form-light text-left p-5">
                                    <div className="brand-logo">
                                        <img src={logo} />
                                    </div>
                                    <h4 className="login">Request</h4>
                                    {this.props.error && <h6 className="text-center">Send request to admin to {this.props.error}</h6>}
                                    {this.props.message && <h6 className="font-weight-light text-center" style={{ color: 'green' }}>{this.props.message}</h6>}
                                    <form className="pt-3">
                                        <br />
                                        <div className="mt-4">
                                            <a className="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn" onClick={this.notifyAdmin}>Send Request</a>
                                        </div>
                                    </form>
                                    <Link to='/login'><span style={{ float: 'left', color: 'blue', cursor: 'pointer' }}><i className='mdi mdi-arrow-left-thick'></i> Login</span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapState(state) {
    const { error, message } = state.authentication || null;
    return { error, message };
}

const actionCreators = {
    notifyAdmin: userActions.notifyAdmin,
};

const sendRequestPage = connect(mapState, actionCreators)(SendRequest);
export { sendRequestPage as SendRequest };