import { useEffect, useState } from 'react';
import Table from "../_components/Table";
import { notificationActions } from "../_actions";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import React from "react";
import Message from "../_components/Message";
import DeleteModal from '../_components/deleteModal';

function NotificationList(props) {

  const [notifications, setNotifications] = useState([]);
  const [confirmationModal, showConfirmationModal] = useState('');
  const [selectedNotification, setSelectedNotification] = useState('');

  useEffect(() => {
    props.listNotifications();
  }, []);

  const editNotifications = (notifcation) => {
    props.setSelectedNotificationData(notifcation);
  };

  const deleteNotification = (id) => {
    setSelectedNotification(id);
    showConfirmationModal(true);
  };
  const Header = [
    { headerName: "ID", field: "id", resizable: true },
    { headerName: "Notification", field: "notificationText", resizable: true },
    { headerName: "Customer", field: "customerName", resizable: true, 
      cellRendererFramework: (params) => {
        let cName = params.node.data && params.node.data.customerName ? params.node.data.customerName : 'All';
        return (
          <React.Fragment>
            <span>{cName}</span>
          </React.Fragment>
        );
      }
    },
    { 
      headerName: "Product", field: "productName", resizable: true, 
      cellRendererFramework: (params) => {
        let pName = params.node.data && params.node.data.productName ? params.node.data.productName : 'All';
        return (
          <React.Fragment>
            <span>{pName}</span>
          </React.Fragment>
        );
      }
    },
    {
      headerName: "ACTION",
      cellRendererFramework: (params) => {
        return (
          <React.Fragment>
            <span
              className="mdi mdi-pencil"
              onClick={() => editNotifications(params.node.data)}
              style={{ fontSize: "18px", color: "#198ae3", cursor: "pointer" }}
            />
            
            <span 
              className="mdi mdi-delete" 
              onClick={() => deleteNotification(params.node.data.id)} 
              style={{ fontSize: '18px', color: '#e91e63', cursor: 'pointer' }} 
              data-toggle="tooltip" data-placement="top" title="Delete"
            />
          </React.Fragment>
        );
      }
    }
  ];
  
  return (
    <div>
      <Message error={props.error} success={props.message} />
      {confirmationModal !== '' ? <DeleteModal deleteContext="Remove" modal={true} title="Are you sure, you want to remove notification Id" name={selectedNotification} onClose={() => showConfirmationModal('') && setSelectedNotification('')} onDelete={() => setTimeout(() => {setSelectedNotification(''); showConfirmationModal('')}, 1000) && props.deleteNotification(selectedNotification)} /> : null}
      <Table headers={Header} data={props.notifications} />
    </div>
  )
}

function mapStateToProps(state) {
  const notifications = state.notifications.items || [];
  const message = state.notifications.message;
  const error = state.notifications.error;
  const auth = state.authentication.user;
  return { notifications, auth, message, error };
}

const mapDispatchToProps = {
  listNotifications: notificationActions.getNotifications,
  deleteNotification: notificationActions.deleteNotification,
  setSelectedNotificationData: notificationActions.setSelectedNotificationData
};

const connectedApp = connect(mapStateToProps, mapDispatchToProps)(NotificationList);
export { connectedApp as NotificationList };