import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { store } from "../_helpers";
import { userActions } from "../_actions";
import logo from "../assets/images/shedpaper.jpg";
import { history } from "../_helpers";

import "./login.css";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    // reset login status
    this.props.logout();
    // console.log("store is", store.getState());
    this.state = {
      username: "",
      password: "",
      isValidUsername: false,
      submitted: false,
      submittedPassword: false,
      showRequest: false,
      isValidPassword: true,
      error: "",
      errorInput: "",
      message: null
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitPassword = this.handleSubmitPassword.bind(this);
  }

  componentDidMount() {
    // console.log(this.props);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.error !== this.props.error) {
      this.setState({ error: this.props.error });
    }
    // console.log("Component will update called", this.props);
    if (typeof this.props.isValidUser1 != "undefined") {
      if (!this.props.isValidUser1 !== this.state.showRequest) {
        // console.log("is valid user defined1", (!this.props.isValidUser1 !== this.state.showRequest));
        this.setState({
          isValidUsername: this.props.isValidUser1,
          showRequest: !this.props.isValidUser1
        });
      }
      if (this.props.isValidUser1 != this.state.isValidUsername) {
        // console.log("is valid user defined1", (!this.props.isValidUser1 !== this.state.showRequest));
        this.setState({
          isValidUsername: this.props.isValidUser1,
          showRequest: !this.props.isValidUser1
        });
      }
      // console.log("AuthFailure", this.state.error);
      // console.log("props AuthFailure", this.props.error);
    }
    // if(this.props.forgotPasswordMessage) {
    //   this.setState({message:this.props.forgotPasswordMessage});
    //   // setTimeout(()=>{

    //   // }, 8000);
    // }
  }
  validate(username) {
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (!pattern.test(username)) {
      this.setState({ errorInput: "Please enter valid email address." });
      return false;
    } else {
      this.setState({ errorInput: "" });
    }
    return true;
  }

  handleChange(e) {
    const { name, value } = e.target;
    // console.log(name, value)
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { username } = this.state;
    if (username) {
      if (this.validate(username)) {
        // console.log(username)
        this.props.isValidUser(username);
      }

      //this.setState({ isValidUsername: true });
    }
  }

  handleSubmitPassword(e) {
    e.preventDefault();
    this.setState({ submittedPassword: true });
    const { username, password } = this.state;
    if (username && password) {
      this.props.login(username, password);
    }
  }

  handleForgot = (e) => {
    // this redirect after backend response with
    //  this.props.history.push({ pathname: "/forgot_password", state: { email: this.state.username } });
    this.props.forgotPassword(this.state.username);
  };

  render() {
    const { loggingIn, forgotPasswordMessage, forgotPasswordError } = this.props;
    const {
      username,
      password,
      submitted,
      isValidUsername,
      submittedPassword,
      isValidPassword,
      error,
      errorInput
    } = this.state;
    // console.log(isValidUsername)
    return (
      <div className="content-wrapper d-flex align-items-center auth" style={{ height: "100%" }}>
        <div className="row w-100">
          <div className="col-lg-5 col-md-6 mx-auto">
            <a href="https://www.shedpaper.com/" style={{ 'text-decoration': "none" }}>
              <svg id="shedpaper-url-btn" height="20pt" viewBox="-73 0 512 512" width="20pt" xmlns="http://www.w3.org/2000/svg" 
                style={{position: 'absolute',top: '15px', transform: 'rotate(90deg)', left: '-15px' }}>
                <path d="m54.425781 135.785156c8.164063-1.410156 13.636719-9.167968 12.230469-17.332031-7.988281-46.289063 
                27.71875-88.453125 74.394531-88.453125 46.535157 0 82.304688 41.964844 74.425781 88.269531-1.386718 8.167969 
                4.105469 15.914063 12.273438 17.304688 8.167969 1.394531 15.914062-4.105469 17.304688-12.273438 
                10.960937-64.449219-38.78125-123.300781-104.003907-123.300781-65.148437 0-115.128906 58.84375-103.957031 
                123.554688 1.410156 8.1875 9.199219 13.636718 17.332031 12.230468zm0 0"></path>
                <path d="m300.664062 240.644531c-11.242187-22.066406-38.019531-30.875-60-20-10.21875-20.058593-33.589843-29.535156-54.832031-22.160156v-91.863281c0-24.484375-19.675781-44.941406-43.867187-45.605469-25.347656-.71875-46.351563 19.582031-46.351563 
                44.984375v231.082031l-17.621093-18.453125c-16.914063-17.707031-45.234376-18.953125-63.136719-2.769531-18.890625 17.074219-19.832031 46.148437-2.4375 64.421875l101.273437 108.019531c14.121094 15.058594 34.066406 23.699219 54.714844 23.699219h122.144531c41.355469 
                0 75-33.644531 75-75v-156c0-33.230469-35.050781-55.117188-64.886719-40.355469zm34.886719 196.355469c0 24.8125-20.1875 45-45 45h-122.144531c-12.390625 0-24.355469-5.183594-32.828125-14.222656-.363281-.382813-101.050781-107.777344-101.414063-108.160156-5.800781-6.078126-5.523437-15.777344.804688-21.503907 
                5.964844-5.386719 15.53125-4.832031 21.324219 1.234375l43.46875 45.519532c9.320312 9.765624 25.847656 3.140624 25.847656-10.355469v-268.511719c0-8.429688 6.992187-15.234375 15.539063-14.996094 8.097656.222656 14.683593 7.226563 14.683593 15.617188 0 165.054687-.28125 90.238281-.28125 179.378906 0 8.308594 
                6.765625 15.078125 15.140625 14.992188 8.363282.085937 15.140625-6.679688 15.140625-14.992188v-47.851562c1.335938-6.914063 7.421875-12.148438 14.71875-12.148438 8.269531 0 15 6.730469 15 15v45c0 8.285156 6.714844 15 15 15 8.285157 0 15-6.714844 15-15v-25c0-8.269531 6.730469-15 15-15s15 6.730469 15 15v25c0 8.285156 
                6.714844 15 15 15 8.285157 0 15-6.714844 15-15v-5c0-8.269531 6.730469-15 15-15s15 6.730469 15 15zm0 0">
                </path>
              </svg>
              <h3 class="text-center" 
                style={{color: "white", fontWeight: "600", 'text-transform': 'uppercase' }}>
                Navigate to shedpaper customer site.
              </h3>
            </a>
            <div className="auth-form-light text-left p-5">
              <div className="brand-logo">
                <img src={logo}/>
              </div>
              {!isValidUsername && (
                <div className="loginCon">
                  <h4 className="login">Login</h4>
                  <h6 className="font-weight-light text-center">
                    Please enter your email or username to login.
                  </h6>
                  <form className="pt-3" name="form" onSubmit={this.handleSubmit}>
                    <div className="form-group mt-4 mb-4">
                      {errorInput && username && <div className="error-msg">{errorInput}</div>}
                      {submitted && !username && (
                        <div className="error-msg">Username is required</div>
                      )}
                      <input
                        type="email"
                        className="form-control form-control-lg"
                        id="exampleInputEmail1"
                        placeholder="Username/Email"
                        name="username"
                        value={username}
                        onChange={this.handleChange}
                        autoFocus />
                    </div>
                    <br />
                    <div className="mt-2">
                      <a
                        className="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn"
                        onClick={this.handleSubmit}>
                        Login
                      </a>
                    </div>
                  </form>
                </div>
              )}
              {isValidUsername && (
                <div className="loginCon">
                  <h4 className="login">Login</h4>
                  {forgotPasswordMessage ? (
                    <h6 className="font-weight-light text-center" style={{ color: "green" }}>
                      {forgotPasswordMessage}
                    </h6>
                  ) : forgotPasswordError ? (
                    <h6 className="font-weight-light text-center" style={{ color: "red" }}>
                      {forgotPasswordMessage}
                    </h6>
                  ) : (
                        <h6 className="font-weight-light text-center">
                          Please enter account password to login
                        </h6>
                      )}
                  <form className="pt-3" name="form1" onSubmit={this.handleSubmitPassword}>
                    <div className="form-group mt-4 mb-4">
                      {submittedPassword && !password && (
                        <div className="error-msg">Password is required</div>
                      )}
                      {submittedPassword && error && !!password && (
                        <div className="error-msg">{error}</div>
                      )}
                      <input
                        type="password"
                        className="form-control form-control-lg"
                        id="exampleInputEmail1"
                        name="password"
                        placeholder="Password"
                        value={password}
                        onChange={this.handleChange}
                        autoFocus
                      />
                    </div>
                    <br />
                    <div className="mt-2">
                      <a
                        className="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn"
                        onClick={this.handleSubmitPassword}>
                        Submit
                      </a>
                    </div>
                  </form>
                  <span
                    onClick={() => this.props.isValidUser(null, true)}
                    style={{ float: "left", color: "blue", cursor: "pointer" }}>
                    <i className="mdi mdi-arrow-left-thick"></i> Login
                  </span>
                  <span
                    style={{ float: "right", color: "blue", cursor: "pointer" }}
                    onClick={this.handleForgot}>
                    Forgot Password?
                  </span>
                </div>
              )}
              <br />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapState(state) {
  // console.log('state', state)
  const {
    loggingIn,
    isValidUser1,
    error,
    forgotPasswordMessage,
    forgotPasswordError,
    role
  } = state.authentication;
  const { register } = state.registration;
  // console.log("Login Failure", error);
  return { loggingIn, register, isValidUser1, error, forgotPasswordMessage, forgotPasswordError };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
  getToken: userActions.getToken,
  isValidUser: userActions.isValidUser,
  forgotPassword: userActions.forgotPassword
};

const connectedLoginPage = connect(mapState, actionCreators)(LoginPage);
export { connectedLoginPage as LoginPage };
