
import React, { useState, useEffect } from 'react';
import Select from "react-dropdown-select";
import { Link, useLocation } from "react-router-dom";
import { Error, isEmpty } from "../_components/Validation";
import { connect } from "react-redux";
import { customerActions } from "../_actions";
import {API} from "../_services";
import Message from "../_components/Message";
import { history } from '../_helpers';

const AddNotification = (props, state) => {
  const location = useLocation();
  const [selectedCustomer, setSelectedCustomer] = React.useState([]);
  const [customers, setCustomers] = React.useState([]);
  const [selectedProduct, setSelectedProduct] = React.useState([]);
  const [customerProducts, setCustomerProducts] = React.useState([]);
  const [notification, setNotification] = React.useState('');
  const [loadInProgress, setLoadInProgress] = React.useState('');
  const [message, setMessage] = React.useState({
    success: null,
    error: null
  });
  const [submitDisable, setSubmitDisable] = React.useState(true);
  
  const onProductSelect = (value) => {
    setSelectedProduct(value);
  };

  const onCustomerSelect = (value, action) => {
    setSelectedProduct([]);
    let selectedCustomerId;
    if(Array.isArray(value)) {
      setSelectedCustomer(value);
      selectedCustomerId = value[0].value;
    } else {
      selectedCustomerId = value.value;
    }
    if(selectedCustomerId) {
      API.getProductByCustomer(selectedCustomerId).then((response) => {
        let products = [];
        if(response.data && response.data.list) {
          response.data.list.map((v) => { 
            products.push({ value: v.parentChangeId, name: v.productFamilyName, label: v.productFamilyName });
          });
          setCustomerProducts(products);
          if(location.pathname.includes("update") && props.selectedNotification && props.selectedNotification.productID) {
            let selectedProduct = [];
            let filteredProduct = response.data.list.find(product => product.parentChangeId === props.selectedNotification.productID) || [];
            selectedProduct.push({value: filteredProduct.parentChangeId, name: filteredProduct.productFamilyName, label: filteredProduct.productFamilyName});
            setSelectedProduct(selectedProduct);
          }
        }
      })
    }
  };

  const changeNotification = (e) => {
    if(e.target.value) {
      setSubmitDisable(false);
    } else {
      setSubmitDisable(true);
    }
    setNotification(e.target.value);
  };

  const handleSubmit = () => {
    let data = {};
    if(!notification) {
      return;
    }
    if(selectedCustomer && selectedCustomer.length && selectedCustomer[0].value) {
      data.customerID = selectedCustomer[0].value;
    }
    if(selectedProduct && selectedProduct.length && selectedProduct[0].value) {
      data.productID = Number(selectedProduct[0].value);
    }
    data.notificationText = notification;
    let req;
    let action= 'add';
    if(location.pathname.includes("update")) {
      data.id = props.selectedNotification.id;
      req = API.updateNotification(data);
      action = 'update';
    } else {
      req = API.addNotification(data);
    }
    req.then((response) => {
      console.log(response);
      if(response.status === 200) {
        setMessage({"success": `Notification ${action}ed Successfully!!"`});
        setTimeout(() =>{
          history.push('/managenotification');
        }, 1000);
      } else {
        setMessage({"error" : `Failed to ${action} notification!!"`});
      }
    })

  };

  useEffect(() => {
    props.listCustomers();
    console.log('trigger use effect hook', customers);
    if(location.pathname.includes("update")) {
      setNotification(props.selectedNotification.notificationText);
    }
  }, []);

  useEffect(() => {
    let customers = [];
    let selectedCustomer = [];
    props.customers.map((v) => {
      if(v.status === "APPROVED") {
        customers.push({ value: v.id, name: v.customerName, label: v.customerName });
      }
    });
    if(location.pathname.includes("update") && props.selectedNotification) {
      if(props.customers) {
        if(props.selectedNotification.customerID ) {
          let filteredCustomer = props.customers.find(customer => customer.id === props.selectedNotification.customerID) || [];
          selectedCustomer.push({value: filteredCustomer.id, name: filteredCustomer.customerName, label: filteredCustomer.customerName});
          setSelectedCustomer(selectedCustomer);
        }
        setCustomers((customers));
      }
    } else {
      setCustomers(customers);
    }
    console.log("Inside customers", customers);
  }, [props.customers]);

  return (
    <div>
      <Message error={message.error} success={message.success} />
      <div className="container-fluid">
        <Link to="/managenotification">
          <span style={{ fontSize: "20px", marginLeft: "3%", color: "black" }}>
            <i className="mdi mdi-arrow-left-thick"/>
          </span>
        </Link>
        <div className="content-wrapper main-bgs">
          <div className="page-header">
            <h3 className="page-title">
              <span className="page-title-icon bg-gradient-primary text-white mr-2">
                <i className="mdi mdi-plus-circle"></i>
              </span>
              {location.pathname.includes("add") ? "Add Notification" : "Edit Notification"}
            </h3>
          </div>
          <div className="row">
            <div className="col-12 grid-margin">
              <div className="card">
                <div className="card-body">
                  <form encType="multipart/form-data">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Customers</label>
                          <Select
                            options={customers}
                            values={selectedCustomer}
                            onChange={(value) =>
                              onCustomerSelect(value, 'selectChange')
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Products</label>
                          <Select
                            options={customerProducts}
                            values={selectedProduct}
                            onChange={(value) =>
                              onProductSelect(value)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Notification Message<b style={{color: 'red'}}>*</b></label>
                          <textarea
                            rows={4}
                            name="notificationDescription"
                            className="form-control"
                            value={notification}
                            onChange={changeNotification}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 form-group text-center">
                        <div className="row d-flex align-items-center mt-5">
                          <div className="col-md-12 text-center">
                            {loadInProgress ? (
                              <span className="btn btn-block btn-gradient-primary btn-md fit-content font-weight-medium auth-form-btn m-auto">
                                Submitting
                              </span>
                            ) : (
                                <span
                                  className={`btn btn-block btn-gradient-primary btn-md fit-content font-weight-medium auth-form-btn m-auto ${submitDisable && 'btn-disabled'}`}
                                  onClick={handleSubmit}>
                                  {location.pathname.includes("add")
                                    ? "Add Notification"
                                    : "Update Notification"}
                                </span>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

function mapStateToProps(state) {
  const customers = state.customers ? state.customers.items : [];
  const selectedNotification = state.notifications && state.notifications.selectedNotification ? state.notifications.selectedNotification : [];
  return {customers, selectedNotification};
}

const actionCreators = {
  listCustomers: customerActions.listCustomers,
};

const connectedApp = connect(mapStateToProps, actionCreators)(AddNotification);
export { connectedApp as AddNotification };