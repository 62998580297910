import React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { documentActions, userActions } from "../_actions";
import Table from "../_components/Table";
import DocumentView from "./DocumentView";
import { compose } from "redux";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import DocumentApprove from "./DocumentApprove";
import { API } from "../_services";
// import moment from 'moment';

class DocumentsList extends React.Component {
  state = {
    users: [],
    customers: [],
    documents: []
  };
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modalBody: {},
      active: "Documents",
      users: [],
      customers: [],
      approveModal: false,
      approveId: null,
      parentId: null,
      data: null
    };
  }

  componentDidMount() {
    // this.props.getDocumentsList();
    // this.props.getParentDocumentsList();
    // this.props.getAllParentsDocuments();
    // this.props.listUsers();
    // this.setState({ users: this.props.users, customers: this.props.customers });
    API.getDocumentsList().then(
      (data) => {
        this.setState({ documents: data.data.list });
      }
      // error => { }
    );
  }

  // componentWillReceiveProps(nextProps) {
  //     // this.props.getDocumentsList();
  // }

  // viewHandle = document => {
  //     console.log("document is", document);
  //     //this.setState({ modal: true, modalBody: document })
  // }

  // editChildHandle = document => {
  //     let childData = document.allData.document[0];
  //     console.log(childData);
  //     let documentId = childData.childDocumentId;
  //     this.setState({ modal: true, modalBody: document })
  //     console.log("inside edit document is", document);
  //     this.props.getDocumentDetails(documentId);
  //     this.props.history.push(`/document/editchild/${documentId}`)

  // }

  // editParentHandle = document => {
  //     let parentData = document.data;
  //     console.log("parentData", parentData);
  //     let documentId = parentData.parentDocumentChangeId;

  //     this.setState({ modal: true, modalBody: parentData })
  //     // this.props.getDocumentDetails(documentId);
  //     this.props.history.push(`/document/editparent/${documentId}`)
  // }

  approveHandle = (parentId, approverId, data) => {
    console.log(parentId);
    this.props.history.push("view/" + parentId);
    // this.setState({ approveModal: true, approveId: approverId, parentId: parentId, data: data });
  };

  // onComplete = e => {
  //     this.setState({ approveModal: false, approveId: null });
  //     this.props.getDocumentsList();
  //     this.props.getParentDocumentsList();
  // }

  render() {
    // console.log(this.state.documents, "DOC");
    const documents = this.state.documents;
    const parentdocuments = this.state.documents;
    const users = this.props.users;
    const customers = this.props.customers;
    // console.log(parentdocuments)
    // console.log(this.state.approveId)
    const StatusClass = {
      REJECTED: "badge badge-danger",
      APPROVED: "badge badge-success",
      REQUIRE_APPROVAL: "badge badge-dark",
      Online: "badge badge-success",
      Offline: "badge badge-dark"
    };
    const Status = {
      REJECTED: "Rejected",
      APPROVED: "Approved",
      REQUIRE_APPROVAL: "Approval Pending"
    };
    const Header = [
      { headerName: "NO.", field: "no" },
      { headerName: "NUMBER", field: "documentNumber" },
      { headerName: "EFFECTIVE DATE", field: "effectiveDate" },
      { headerName: "IFU NUMBER", field: "associatedReferenceNumber" },
      // { headerName: 'CUSTOMER', field: 'obsoleteDate'},
      // { headerName: 'PART NUMBER', field: 'productSubFamilyNameOne'},

      {
        headerName: "STATUS",
        cellRendererFramework: (params) => {
          return (
            <span
              className={StatusClass[params.node.data.status]}
              style={{ marginTop: "5px" }}></span>
          );
        }
      },
      {
        headerName: "ACTION",
        cellRendererFramework: (params) => {
          return (
            <React.Fragment>
              <span
                className="mdi mdi-eye"
                onClick={() => this.viewHandle(params.node.data)}
                style={{
                  fontSize: "18px",
                  color: "#198ae3",
                  cursor: "pointer",
                  marginRight: "5px"
                }}
                data-tip="View"></span>
              <span
                className="mdi mdi-pencil"
                onClick={() => this.editChildHandle(params.node.data)}
                style={{
                  fontSize: "18px",
                  color: "#f44336",
                  cursor: "pointer",
                  marginRight: "5px"
                }}
                data-tip="Edit"></span>
            </React.Fragment>
          );
        }
      }
    ];

    let documentsList = [];
    try {
      documents &&
        documents.data &&
        documents.data.list.length > 0 &&
        documents.data.list.map((value, i) => {
          documentsList.push({
            no: ++i,
            documentNumber: value.document[0].documentNumber,
            effectiveDate: value.effectiveDate,
            productSubFamilyNameOne: value.productSubFamilyNameOne,
            associatedReferenceNumber: value.document[0].associatedReferenceNumber,
            // obsoleteDate: value.obsoleteDate,
            // status: value.isDeleted ? <span style={{ color: 'red' }}>DELETED</span> : value.userActive ? <span style={{ color: 'green' }}>ACTIVE</span> : <span style={{ color: 'orange' }}>INACTIVE</span>,
            documentId: value.documentId,
            status: value.status,
            allData: value
          });
        });
    } catch (error) { }

    const UnHeader = [
      { headerName: "#", field: "no" },
      { headerName: "Document ID", field: "documentId" },
      { headerName: "Effective Date", field: "effectiveDate" },
      { headerName: "Approver", field: "approverId" },
      { headerName: "Customer", field: "customer" },
      { headerName: "Type", field: "type" },
      {
        headerName: "Accessibility",
        field: "Accessibility",
        cellRendererFramework: (params) => {
          return (
            <span
              style={{ marginTop: "5px" }}
              className={StatusClass[params.node.data.Accessibility]}>
              {params.node.data.Accessibility}
            </span>
          );
        }
      },
      {
        headerName: "STATUS",
        field: "status",
        cellRendererFramework: (params) => {
          return (
            <span style={{ marginTop: "5px" }} className={StatusClass[params.node.data.status]}>
              {Status[params.node.data.status]}
            </span>
          );
        }
      },
      {
        headerName: "ACTION",
        cellRendererFramework: (params) => {
          return (
            <React.Fragment>
              <span
                className="badge badge-info view-badge"
                onClick={() =>
                  this.approveHandle(
                    params.node.data.parentDocumentChangeId,
                    params.node.data.documentApproverId,
                    params.node.data.data
                  )
                }>
                {" "}
                <i className="mdi mdi-eye"></i> View
              </span>
            </React.Fragment>
          );
        }
      }
    ];

    let UnApprovedDocs = [];
    documents &&
      documents.length > 0 &&
      documents.map((value, i) => {
        if (value.status === "REQUIRE_APPROVAL") {
          let approver =
            users && users.length > 0
              ? users.find((o) => {
                return o.id === value.approverId;
              })
              : null;
          let customer =
            typeof customers === "object" && customers && customers.length > 0 && value.customerId
              ? customers.find((o) => {
                return o.id === value.customerId;
              })
              : null;
          UnApprovedDocs.push({
            no: ++i,
            documentId: value.parentDocumentChangeId, //value.associatedReferenceNumber + effect,
            approverId: approver ? approver.firstName : "",
            effectiveDate: value.effectiveDate,
            Accessibility: value.isInternal ? "Offline" : "Online",
            type: value.changeType,
            status: value.status,
            customer: customer ? customer.customerName : "",
            documentApproverId: value.approverId,
            parentDocumentChangeId: value.parentDocumentChangeId
          });
        }
      });
    let AllDocs = [];
    documents &&
      documents.length > 0 &&
      documents.map((value, i) => {
        let approver =
          users && users.length > 0
            ? users.find((o) => {
              return o.id === value.approverId;
            })
            : null;
        let customer =
          typeof customers === "object" && customers && customers.length > 0 && value.customerId
            ? customers.find((o) => {
              return o.id === value.customerId;
            })
            : null;
        AllDocs.push({
          no: ++i,
          documentId: value.parentDocumentChangeId, //value.associatedReferenceNumber + effect,
          approverId: approver ? approver.firstName : "",
          effectiveDate: value.effectiveDate,
          Accessibility: value.isInternal ? "Offline" : "Online",
          type: value.changeType,
          status: value.status,
          customer: customer ? customer.customerName : "",
          documentApproverId: value.approverId,
          parentDocumentChangeId: value.parentDocumentChangeId
        });
      });

    return (
      <div className="container-fluid">
        <div className="content-wrapper main-bg"></div>
        <div className="page-header">
          <div className="row w-100">
            <div className="col-md-6">
              <h3 className="page-title">
                <span className="page-title-icon bg-gradient-primary text-white mr-2">
                  <i className="mdi mdi-file-multiple"></i>
                </span>
                Documents
              </h3>
            </div>
            <div className="col-md-6" style={{ textAlign: 'end', display: 'flex' }}>
              <Link
                to="/document/search"
                style={{ marginRight: '10px !important' }}
                className="btn btn-sm btn-info ml-auto">
                <i className="mdi mdi-filter"></i> Search Document
              </Link>
              <Link
                to="/document/add"
                className="btn btn-sm btn-info ml-auto">
                <i className="mdi mdi-plus-circle"></i> Add Document
              </Link>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <Tabs>
                  <TabList className="stltab">
                    <Tab onClick={() => this.setState({ active: "Documents" })}>
                      <span className={this.state.active === "Documents" ? "activeTab" : ""}>
                        Documents
                      </span>
                    </Tab>
                    <Tab onClick={() => this.setState({ active: "All Documents" })}>
                      <span className={this.state.active === "All Documents" ? "activeTab" : ""}>
                        All Documents
                      </span>
                    </Tab>
                  </TabList>
                  <TabPanel>
                    <Table headers={UnHeader} data={UnApprovedDocs} />
                  </TabPanel>
                  <TabPanel>
                    <Table headers={UnHeader} data={AllDocs} />
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  documents: state.documents.documents,
  parentdocuments: state.documents.parentdocuments,
  parentdocuments: state.documents.parentdocuments,
  users: state.users.items,
  customers: state.customers.items
});

const actionCreators = {
  getDocumentsList: documentActions.getDocumentsList,
  getParentDocumentsList: documentActions.getParentDocumentsList,
  getAllParentsDocuments: documentActions.getAllParentsDocuments,
  getDocumentDetails: documentActions.getDocumentDetails,
  listUsers: userActions.listUsers
};

export default compose(withRouter, connect(mapStateToProps, actionCreators))(DocumentsList);

// const documentsList = connect(mapStateToProps, actionCreators)(DocumentsList);
// export { documentsList as DocumentsList };
