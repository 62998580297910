import {
  userConstants
} from '../_constants';
import {
  API
} from '../_services';
import {
  history
} from '../_helpers';

export const notificationActions = {
  addNotification,
  updateNotification,
  deleteNotification,
  getNotifications,
  setSelectedNotificationData
};

function addNotification(notificationData) {
  return dispatch => {
      API.addNotification(notificationData)
          .then(
              notification => {
                  dispatch(success('Notification Added Successfully!!'));
                  history.push('/managenotification');
              },
              error => {
                  dispatch(failure('Failed to Add Notification !!'));
              }
          );
  };

  function success(message) {
      return {
          type: userConstants.CHECK_ADD_NOTIFICATION_SUCCESS,
          message
      }
  }

  function failure(error) {
      return {
          type: userConstants.CHECK_ADD_NOTIFICATION_FAILURE,
          error
      }
  }
}

function setSelectedNotificationData(notificationData) {
  return dispatch => {
    let id = notificationData.id;
    dispatch(add());
    setTimeout(() => {
      history.push(`/notification/update/${id}`);
    }, 1000)
  };
  function add() {
    return {
      type: userConstants.ADD_SELECTED_NOTIFICATION_SUCCESS,
      notificationData: notificationData
    }
  }
}

function updateNotification(notificationData) {
  return dispatch => {
      API.updateNotification(notificationData)
          .then(
              notification => {
                  dispatch(success('Notification Updated Successfully!!'));
                  history.push('/managenotification');
              },
              error => {
                  dispatch(failure('Failed to Update Notification !!'));
              }
          );
  };

  function success(message) {
      return {
          type: userConstants.CHECK_UPDATE_NOTIFICATION_SUCCESS,
          message
      }
  }

  function failure(error) {
      return {
          type: userConstants.CHECK_UPDATE_NOTIFICATION_FAILURE,
          error
      }
  }
}

function deleteNotification(id) {
  return dispatch => {
      API.deleteNotification(id)
          .then(
              notification => {
                  dispatch(success('Notification Deleted Successfully!!', id));
              },
              error => {
                  dispatch(failure('Failed to Delete Notification !!', id));
              }
          );
  };

  function success(message, id) {
      return {
          type: userConstants.CHECK_DELETE_NOTIFICATION_SUCCESS,
          message,
          id
      }
  }

  function failure(error, id) {
      return {
          type: userConstants.CHECK_DELETE_NOTIFICATION_FAILURE,
          error,
          id
      }
  }
}

function getNotifications() {
  return dispatch => {
      API.listNotifications()
          .then(
              notifications => {
                  dispatch(success('Notification fetched successfully!!', notifications));
              },
              error => {
                  dispatch(failure('Failed to fetch notifications !!'));
              }
          );
  };

  function success(message, notifications) {
      return {
          type: userConstants.LIST_NOTIFICATION_SUCCESS,
          message,
          notifications
      }
  }

  function failure(error) {
      return {
          type: userConstants.LIST_NOTIFICATION_FAILURE,
          error
      }
  }
}