import { authHeader } from "../_helpers";
import { APIURL } from "../_constants/api.config";

import axios from "axios";

export const API = {
  login,
  forgotPassword,
  changePassword,
  setPassword,
  logout,
  isValidUser,
  listUser,
  addUser,
  updateUser,
  deleteUser,
  notifyAdmin,
  addCustomer,
  updateCustomer,
  getCustomerById,
  listCustomers,
  getLogo,
  getApprovalInitiatorList,
  approveCustomer,
  addDocument,
  editDocument,
  editParentDocument,
  getDocumentDetails,
  getDocumentsList,
  addDocumentFiles,
  addDocumentListData,
  getParentDocumentsList,
  approveDocument,
  addChildToParent,
  deleteParentChange,
  deleteParentFileById,
  deleteChildDoc,
  getSingleParentById,
  getReport,
  getCompanyListModule2,
  sendSearchURLLink,
  searchDocuments,
  getApprovedCustomerData,
  getCustomerLogo,
  getAuditReport,
  getParentLogoForCustomer,
  contactUs,
  getDocumentURL,
  internalSearchDocuments,
  getDocumentDownload,
  updateAccessibility,
  getProductByCustomer,
  listNotifications,
  addNotification,
  updateNotification,
  deleteNotification
};

// const requestOptions = {
//     headers: { ...authHeader() },
//     'Accept': 'application/json',
//     'Content-Type': 'application/json',
// };

function handleErrorResponse(error) {
  if (error.message === "Network Error") {
    return error.message;
  } else {
    return error.response;
  }
}

function isValidUser(email) {
  email = JSON.stringify({
    email: email
  });
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    }
  };
  return axios
    .post(`${APIURL}/user/verify/email`, email, requestOptions)
    .then((user) => {
      // console.log("Response is", user);
      localStorage.setItem("user", JSON.stringify(user.data));
      return user;
    })
    .catch((error) => {
      return handleErrorResponse(error);
    });
}

function login(username, password) {
  let loginData = JSON.stringify({
    username: username,
    password: password
  });
  const requestOptions = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  return axios
    .post(`${APIURL}/user/get/token`, loginData, requestOptions)
    .then((user) => {
      localStorage.setItem("user", JSON.stringify(user));
      return user;
    })
    .catch((error) => {
      error = "Authentication failure";
      return Promise.reject(error);
      //return handleErrorResponse(error);
    });
}

function forgotPassword(username) {
  let forgotPasswordData = JSON.stringify({
    email: username
  });
  const requestOptions = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  return axios
    .post(`${APIURL}/user/forgot/password`, forgotPasswordData, requestOptions)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return handleErrorResponse(error);
    });
}

function changePassword(data) {
  let changePasswordData = {
    email: data.email,
    newPassword: data.newPassword,
    oldPassword: data.oldPassword
  };
  if(data.resettoken) {
    changePasswordData.resettoken = data.resettoken;
  }
  const requestOptions = {
    headers: {
      ...authHeader(),
      "Content-Type": "application/json"
    }
  };
  return axios
    .post(`${APIURL}/user/change/password`, changePasswordData, requestOptions)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return handleErrorResponse(error);
    });
}

function setPassword(data) {
  // let datas = {
  //   id: data.id,
  //   password: data.password
  // };
  // console.log(datas)
  const requestOptions = {
    headers: {
      ...authHeader(),
      "Content-Type": "application/json"
    }
  };
  return axios
    .post(`${APIURL}/user/set/password`, data, requestOptions)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return handleErrorResponse(error);
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
}

function addUser(data) {
  let userData = {
    email: data.email,
    firstName: data.firstName,
    lastLoginDate: data.lastLoginDate,
    lastName: data.lastName,
    password: data.password,
    passwordActive: data.passwordActive,
    passwordCreationDate: data.passwordCreationDate,
    passwordSetDate: data.passwordSetDate,
    role: data.role,
    userActive: data.userActive,
    isTrainingCompleted: true
  };
  const requestOptions = {
    headers: {
      ...authHeader()
    },
    "Content-Type": "application/json"
  };
  return axios
    .post(`${APIURL}/user/add`, userData, requestOptions)
    .then((user) => {
      return user;
    })
    .catch((error) => {
      return handleErrorResponse(error);
    });
}

function listUser(data) {
  const requestOptions = {
    headers: {
      ...authHeader()
    },
    Accept: "application/json",
    "Content-Type": "application/json"
  };
  return axios
    .get(`${APIURL}/user/list/user`, requestOptions)
    .then((users) => {
      return users.data.userList;
    })
    .catch((error) => {
      return handleErrorResponse(error);
      //return users.userList;
    });
}

async function deleteUser(id) {
  const requestOptions = {
    headers: {
      ...authHeader()
    },
    data: {
      id: id
    }
  };
  try {
    const users = await axios.delete(`${APIURL}/user/delete`, requestOptions);
    return "success";
  } catch (error) {
    return handleErrorResponse(error);
  }
}

async function updateUser(data) {
  let userData = {
    id: data.id,
    email: data.email,
    firstName: data.firstName,
    lastLoginDate: data.lastLoginDate,
    lastName: data.lastName,
    password: data.password,
    passwordActive: data.passwordActive,
    passwordCreationDate: data.passwordCreationDate,
    passwordSetDate: data.passwordSetDate,
    role: data.role,
    userActive: data.userActive
  };
  const requestOptions = {
    headers: {
      ...authHeader()
    }
  };
  try {
    await axios
      .put(`${APIURL}/user/modify`, userData, requestOptions)
      .then((user) => {
        return "success";
      })
      .catch((error) => {
        return handleErrorResponse(error);
      });
  } catch (error) {
    return handleErrorResponse(error);
  }
}

async function notifyAdmin(email) {
  email = JSON.stringify({
    email: email
  });
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    }
  };
  try {
    const response = await axios.post(`${APIURL}/user/notify/admin`, email, requestOptions);
    // console.log("Response is", response);
  } catch (error) {
    return handleErrorResponse(error);
  }
}

// async function addCustomer(customerData) {
//     const requestParams = {
//         contactEmail: customerData.contactEmail,
//         contactInformation: customerData.contactInformation,
//         countryExclusionList: customerData.countryExclusionList,
//         customerName: customerData.customerName,
//         customerWebsiteLink: customerData.customerWebsiteLink,
//         approverId: customerData.approverId
//     }

//     try {
//         const response = await axios.post(`${APIURL}/customer/add`, {}, { params:requestParams, headers: {
//             ...authHeader()
//           } });
//         return response;
//     }
//     catch (error) {
//         return handleErrorResponse(error);
//     }
// }

async function addCustomer(customerData) {
  const requestParams = {
    contactEmail: customerData.contactEmail,
    contactInformation: customerData.contactInformation,
    countryExclusionList: customerData.countryExclusionList,
    customerName: customerData.customerName,
    customerWebsiteLink: customerData.customerWebsiteLink, //going as undefined
    approverId: customerData.approverId,
    customerLogo: customerData.customerLogo
  };
  var bodyFormData = new FormData();
  // bodyFormData.set(customerData);
  Object.keys(requestParams).map((k) => {
    bodyFormData.append(k, customerData[k]);
  });
  let requestOptions = {
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data"
    }
  };

  try {
    const response = await axios.post(`${APIURL}/customer/add`, bodyFormData, requestOptions);
    return response;
  } catch (error) {
    return handleErrorResponse(error);
  }
}

// function updateCustomer(customerData) {
//     const requestParams = {
//         contactEmail: customerData.contactEmail,
//         contactInformation: customerData.contactInformation,
//         countryExclusionList: customerData.countryExclusionList,
//         customerName: customerData.customerName,
//         customerWebsiteLink: customerData.customerWebsiteLink,
//         approverId: customerData.approverId,
//         id: customerData.id
//     }

//     return axios.put(`${APIURL}/customer/update`, {}, { params:requestParams, headers: {
//         ...authHeader()
//       } })
//         .then((response) => {
//             return response;
//         }).catch((error) => {
//             return handleErrorResponse(error);
//         });
// }

async function updateCustomer(customerData) {
  const requestParams = {
    contactEmail: customerData.contactEmail,
    contactInformation: customerData.contactInformation,
    countryExclusionList: customerData.countryExclusionList,
    customerName: customerData.customerName,
    customerWebsiteLink: customerData.customerWebsiteLink,
    approverId: customerData.approverId,
    id: customerData.id,
    customerLogo: customerData.customerLogo
  };
  var bodyFormData = new FormData();
  Object.keys(requestParams).map((k) => {
    bodyFormData.append(k, customerData[k]);
  });
  let requestOptions = {
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data"
    }
  };
  return axios
    .put(`${APIURL}/customer/update`, bodyFormData, requestOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return handleErrorResponse(error);
    });
}

async function listCustomers() {
  const requestOptions = {
    headers: {
      ...authHeader()
    },
    Accept: "application/json",
    "Content-Type": "application/json"
  };
  // try {
  //     const customers = await axios.get(`${APIURL}/customer/list/`, requestOptions);
  //     return customers.data.userList;
  // }
  // catch (error) {
  //     return handleErrorResponse(error);
  // }

  try {
    const customers = await axios.get(`${APIURL}/customer/list/`, requestOptions);
    return customers.data.customerList;
  } catch (error) {
    return handleErrorResponse(error);
  }
}

function getCustomerById(id) {
  return axios
    .get(`${APIURL}/customer/get/${id}`, {
      headers: {
        ...authHeader(),
        "Content-Type": "application/json"
      }
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

function getLogo(customerId) {
  const requestOptions = {
    headers: {
      ...authHeader()
    },
    Accept: "application/json",
    "Content-Type": "application/json"
  };
  try {
    const customers = axios.get(`${APIURL}/customer/logo/${customerId}`, requestOptions);
    // console.log("customers", cus);
    return customers;
  } catch (error) {
    return error;
  }
}

// function updateUser(data) {
//     const requestOptions = {
//         headers: {...authHeader()},
//         'Accept': 'application/json',
//         'Content-Type': 'application/json',
//     };
//     return axios.get(`${APIURL}/user/list/user`, requestOptions)
//         .then((users) => {
//             return users.userList;
//         }).catch((error) => {
//             //return handleErrorResponse(error);
//             return users.userList;

//     });
// }
async function getApprovalInitiatorList() {
  const requestOptions = {
    headers: {
      ...authHeader()
    },
    Accept: "application/json",
    "Content-Type": "application/json"
  };
  try {
    const customers = await axios.post(`${APIURL}/customer/check/request`, {}, requestOptions);
    return customers;
  } catch (error) {
    return error;
  }
}

async function approveCustomer(data) {
  const requestOptions = {
    headers: {
      ...authHeader()
    },
    Accept: "application/json",
    "Content-Type": "application/json"
  };
  // const requestParams = {
  //     changeDescription: data.changeDescription,
  //     changeJustification: data.changeJustification,
  //     customerApproval: data.customerApproval, //Can be yes or no
  //     customerApproverId: data.customerApproverId,
  //     customerId: data.customerId,
  //     username: data.username,
  //     password: data.password,
  // }
  // try {
  //     const customers = await axios.post(`${APIURL}/customer/approve/add`, requestParams, requestOptions);
  //     return customers;
  // }
  // catch (error) {
  //     return error;
  // }
  return axios
    .post(`${APIURL}/customer/approve/add`, data, requestOptions)
    .then((resp) => {
      return resp;
    })
    .catch((err) => {
      return err.response;
    });
}

async function addDocument(documentData) {
  console.log("documentData", documentData);
  const requestParams = {
    approverId: documentData.approverId,
    associatedReferenceNumber: documentData.associatedReferenceNumber,
    changeDescription: documentData.changeDescription,
    changeJustification: documentData.changeJustification,
    changeType: documentData.changeType,
    childDocument: documentData.childDocument,
    comments: documentData.comments,
    countryList: documentData.countryList,
    customerId: documentData.customerId,
    isInternal: documentData.isInternal,
    keywords: documentData.keywords,
    languageList: documentData.languageList,
    mainDocument: documentData.mainDocument,
    obsoleteDate: documentData.obsoleteDate,
    password: documentData.password,
    productFamilyDescription: documentData.productFamilyDescription,
    productFamilyName: documentData.productFamilyName,
    productSubFamilyNameOne: documentData.productSubFamilyNameOne,
    productSubFamilyNameTwo: documentData.productSubFamilyNameTwo,
    productFamilyDescription: documentData.productFamilyDescription,
    username: documentData.username,
    effectiveDate: documentData.effectiveDate
  };

  // const requestParams = {
  //     document: documentData.changeImage,
  //     customerId: 2,
  //     effectiveDate: '20-Oct-2020',
  //     obsoleteDate: '20-Oct-2020',
  //     countryList: 'INDIA',
  //     isInternal: 'Yes',
  //     productFamilyName: 'test family',
  //     productSubFamilyNameOne: 'test family2',
  //     productFamilyDescription: 'test descrip',
  //     associatedReferenceNumber: 'test1234',
  //     changeDescription: 'test desc',
  //     changeJustification: documentData.changeJustification,
  //     languageList: 'INDIA',
  //     approverId: 1,
  //     keywords: 'test'
  // }
  var bodyFormData = new FormData();
  Object.keys(requestParams).map((k) => {
    bodyFormData.append(k, requestParams[k]);
  });
  let requestOptions = {
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data"
    }
  };
  // console.log('received data ', requestParams)
  try {
    // console.log('body data ', bodyFormData)
    const response = await axios.post(`${APIURL}/document/add`, bodyFormData, requestOptions);
    return response;
  } catch (error) {
    return handleErrorResponse(error);
  }
}
// Child doc
function editDocument(documentData) {
  const requestParams = {
    approverId: documentData.approverId,
    associatedReferenceNumber: documentData.associatedReferenceNumber,
    changeDescription: documentData.changeDescription,
    changeJustification: documentData.changeJustification,
    changeType: documentData.changeType,
    childDocumentId: documentData.childDocumentId,
    comments: documentData.comments,
    countryList: documentData.countryList,
    customerId: documentData.customerId,
    id: documentData.id,
    isInternal: documentData.isInternal,
    keywords: documentData.keywords,
    languageList: documentData.languageList,
    mainDocument: documentData.mainDocument,
    obsoleteDate: documentData.obsoleteDate,
    password: documentData.password,
    productFamilyDescription: documentData.productFamilyDescription,
    productFamilyName: documentData.productFamilyName,
    productSubFamilyNameOne: documentData.productSubFamilyNameOne,
    productSubFamilyNameTwo: documentData.productSubFamilyNameTwo,
    productFamilyDescription: documentData.productFamilyDescription,
    username: documentData.username,
    effectiveDate: documentData.effectiveDate
  };
  var bodyFormData = new FormData();
  Object.keys(documentData).map((k) => {
    bodyFormData.append(k, documentData[k]);
  });
  let requestOptions = {
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data"
    }
  };
  return axios
    .put(`${APIURL}/document/updateSingleChildDocument`, bodyFormData, requestOptions)
    .then((resp) => {
      return resp;
    })
    .catch((err) => {
      return err.response;
    });
  // try {
  //     const response = axios.put(`${APIURL}/document/updateSingleChildDocument`, bodyFormData, requestOptions);
  //     return response;
  // }
  // catch (error) {
  //     return handleErrorResponse(error);
  // }
}

function editParentDocument(documentData) {
  var bodyFormData = new FormData();
  if (documentData.parentDocuments) {
    documentData.parentDocuments.map((file) => {
      bodyFormData.append("parentDocuments", file);
    });
  }
  Object.keys(documentData).map((k) => {
    bodyFormData.append(k, documentData[k]);
  });
  let requestOptions = {
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data"
    }
  };
  return axios
    .put(`${APIURL}/document/updateParent`, bodyFormData, requestOptions)
    .then((resp) => {
      return resp;
    })
    .catch((err) => {
      return err.response;
    });
  // try {
  //     const response = axios.put(`${APIURL}/document/updateParent`, bodyFormData, requestOptions);
  //     return response;
  // }
  // catch (error) {
  //     return handleErrorResponse(error);
  // }
}

function getDocumentDetails(documentId) {
  let requestOptions = {
    headers: {
      ...authHeader(),
      "Content-Type": "application/json"
    }
  };
  try {
    const response = axios.get(`${APIURL}/document/get/${documentId}`, requestOptions);
    return response;
  } catch (error) {
    return handleErrorResponse(error);
  }
  // axios.get(`${APIURL}/document/get/${documentId}`, requestOptions).then(response => {
  //     return response;
  // }).catch(error => {
  //     return error;
  // })
}

function getDocumentsList() {
  let requestOptions = {
    headers: {
      ...authHeader(),
      "Content-Type": "application/json"
    }
  };
  // try {
  //     const response = await axios.get(`${APIURL}/document/list`, requestOptions);
  //     return response;
  // }
  // catch (error) {
  //     return handleErrorResponse(error);
  // }
  try {
    return axios.get(`${APIURL}/document/getAllParentsForLoginUser`, requestOptions);
  } catch (error) {
    return handleErrorResponse(error);
  }
}

function getParentDocumentsList() {
  let requestOptions = {
    headers: {
      ...authHeader(),
      "Content-Type": "application/json"
    }
  };
  // try {
  //     const response = await axios.get(`${APIURL}/document/list`, requestOptions);
  //     return response;
  // }
  // catch (error) {
  //     return handleErrorResponse(error);
  // }
  try {
    return axios.get(`${APIURL}/document/getDocumentParents`, requestOptions);
  } catch (error) {
    return handleErrorResponse(error);
  }
}

function getAllParentsDocuments() {
  let requestOptions = {
    headers: {
      ...authHeader(),
      "Content-Type": "application/json"
    }
  };
  try {
    return axios.get(`${APIURL}/document/getAllParentsForLoginUser`, requestOptions);
  } catch (error) {
    return handleErrorResponse(error);
  }
}
// async function addDocumentFiles(documentList, username, password, approverId) {
//     console.log('prrover id ', approverId)
//     await Promise.all(documentList.map(async document => {
//         const formData = new FormData();
//         formData.append('mainDocument', document.mainDocument)
//         formData.append('childDocument', document.childDocument)
//         let requestOptions = {
//             headers: {
//                 ...authHeader(),
//                 'Content-Type': 'multipart/mixed'
//             }
//         };
//         try {
//             const response = await axios.post(`${APIURL}/document/addDocumentFiles`, formData, requestOptions)
//             console.log(response)
//             document.uploadDocPath = response.data.uploadDocPath;
//             document.uploadApprovalDocPath = response.data.uploadApprovalDocPath;
//             document.uploadDocumentFileId = response.data.uploadDocumentFileId;
//         } catch (error) {
//             return handleErrorResponse(error);
//         }
//     }))

//     try {
//         // console.log(documentList);
//         const docResponse = await addDocumentListData(documentList, username, password, approverId)
//         return docResponse;
//     } catch (error) {
//         return handleErrorResponse(error);
//     }
// }

// function addDocumentFiles(documentList, username, password) {

//     let requestOptions = {
//         headers: {
//             ...authHeader(),
//             'Content-Type': 'multipart/mixed'
//         }
//     };

//     return Promise.all(documentList.map(document => {
//         const formData = new FormData();
//         formData.append('mainDocument', document.mainDocument)
//         formData.append('childDocument', document.childDocument)
//         axios.post(`${APIURL}/document/addDocumentFiles`, formData, requestOptions).then(response => {
//             // console.log(response)
//             return response;
//             // document.uploadDocPath = response.data.uploadDocPath;
//             // document.uploadApprovalDocPath = response.data.uploadApprovalDocPath;
//             // document.uploadDocumentFileId = response.data.uploadDocumentFileId;
//             // console.log('id ', response.data.uploadDocumentFileId)
//             // return document;
//         })
//     })).then(rep => {
//         console.log('1 ', rep)
//     }).then(repp => {
//         console.log('2 ', repp)
//     })
//     // .then(resp => {
//     //     console.log(documentList)
//     //     addDocumentListData(documentList, username, password).then(response => {
//     //         console.log('return ', response);
//     //         return response;
//     //     }).catch(err => {
//     //         console.log(err)
//     //         return err
//     //     })
//     // })

//     // return addDocumentListData(documentList, username, password).then(resp => {
//     //     return resp;
//     // }).catch(err => {
//     //     console.log(err)
//     // })
//     // try {
//     //     console.log(documentList);
//     //     const docResponse = await addDocumentListData(documentList, username, password)
//     //     return docResponse;
//     // } catch (error) {
//     //     return handleErrorResponse(error);
//     // }
// }

//main function add document data

// async function addDocumentListData(data) {
//     let list = JSON.stringify({ list: data.list });
//     var myJsonObject = JSON.parse(list);
//     myJsonObject.approverId = data.approverId;
//     myJsonObject.customerId = data.customerId;
//     myJsonObject.username = data.username;
//     myJsonObject.password = data.password;
//     myJsonObject.changeDescription = data.changeDescription;
//     myJsonObject.changeJustification = data.changeJustification;
//     myJsonObject.effectiveDate = data.effectiveDate;
//     myJsonObject.uploadParentDocumentFileId = data.uploadParentDocumentFileId;
//     console.log('json ', myJsonObject)
//     myJsonObject = JSON.stringify(myJsonObject); //change back to string
//     let requestOptions = {
//         headers: {
//             ...authHeader(),
//             'Content-Type': 'application/json'
//         }
//     };
//     return await axios.post(`${APIURL}/document/addDocumentListData`, myJsonObject, requestOptions);

// }

//my fuction add document data
function addDocumentListData(data) {
  let list = JSON.stringify({
    list: data.list
  });
  var myJsonObject = JSON.parse(list);
  myJsonObject.approverId = data.approverId;
  myJsonObject.customerId = data.customerId;
  myJsonObject.username = data.username;
  myJsonObject.password = data.password;
  myJsonObject.changeDescription = data.changeDescription;
  myJsonObject.changeJustification = data.changeJustification;
  myJsonObject.effectiveDate = data.effectiveDate;
  myJsonObject.isInternal = data.isInternal;
  myJsonObject.changeType = data.changeType;
  myJsonObject.uploadParentDocumentFileId = data.uploadParentDocumentFileId;
  myJsonObject.productFamilyName = data.productFamilyName;
  console.log("json ", myJsonObject);
  myJsonObject = JSON.stringify(myJsonObject); //change back to string
  let requestOptions = {
    headers: {
      ...authHeader(),
      "Content-Type": "application/json"
    }
  };
  return axios
    .post(`${APIURL}/document/addDocumentListData`, myJsonObject, requestOptions)
    .then((resp) => {
      return resp;
    })
    .catch((err) => {
      console.log(err.response);
      return err.response;
    });
}

async function approveDocument(data) {
  let myrequestOptions = {
    headers: {
      ...authHeader()
    },
    Accept: "application/json",
    "Content-Type": "application/json"
  };
  return axios
    .post(`${APIURL}/document/approveDocuments`, data, myrequestOptions)
    .then((resp) => {
      return resp;
    })
    .catch((err) => {
      return err.response;
    });
}

async function addDocumentFiles(documentsToUpload, isParent, parentLogo) {
  const formData = new FormData();
  formData.append("isParent", isParent);
  documentsToUpload.map((file) => {
    if (isParent) {
      formData.append("parentDocuments", file);
      formData.append("parentLogo", parentLogo);
    } else {
      formData.append("childMainDocument", file);
    }
  });

  let requestOptions = {
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/mixed"
    }
  };

  try {
    return await axios.post(`${APIURL}/document/addDocumentFiles`, formData, requestOptions);
  } catch (error) {
    return handleErrorResponse(error);
  }
}

function deleteChildDoc(childDocumentId, username, password) {
  const requestOptions = {
    headers: {
      ...authHeader()
    },
    data: {
      childDocumentId: childDocumentId,
      username: username,
      password: password
    }
  };
  return axios
    .delete(`${APIURL}/document/deleteChildDoc`, requestOptions)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return handleErrorResponse(error);
    });
  // try {
  //     return await axios.delete(`${APIURL}/document/deleteChildDoc`, requestOptions);
  // }
  // catch (error) {
  //     return handleErrorResponse(error);
  // }
}

async function deleteParentChange(documentParentChangeId, username, password) {
  const requestOptions = {
    headers: {
      ...authHeader()
    },
    data: {
      documentParentChangeId: documentParentChangeId,
      username: username,
      password: password
    }
  };
  return axios
    .delete(`${APIURL}/document/deleteParentChange`, requestOptions)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return handleErrorResponse(error);
    });
  // try {
  //     return await axios.delete(`${APIURL}/document/deleteParentChange`, requestOptions);
  // }
  // catch (error) {
  //     return handleErrorResponse(error);
  // }
}

function deleteParentFileById(data) {
  const requestOptions = {
    headers: {
      ...authHeader()
    },
    data: {
      documentParentChangeId: data.documentParentChangeId,
      fileId: data.fileId,
      password: data.password,
      username: data.username
    }
  };
  return axios
    .delete(`${APIURL}/document/deleteParentFileById`, requestOptions)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return handleErrorResponse(error);
    });
  // try {
  //     return await axios.delete(`${APIURL}/document/deleteParentChange`, requestOptions);
  // }
  // catch (error) {
  //     return handleErrorResponse(error);
  // }
}

async function addChildToParent(documentData) {
  var bodyFormData = new FormData();
  Object.keys(documentData).map((k) => {
    bodyFormData.append(k, documentData[k]);
  });
  let requestOptions = {
    headers: {
      ...authHeader(),
      "Content-Type": "application/json"
    }
  };

  try {
    return await axios.post(`${APIURL}/document/addFilesToParent`, bodyFormData, requestOptions);
  } catch (error) {
    return handleErrorResponse(error);
  }
  // return await axios.post(`${APIURL}/document/addFilesToParent`, bodyFormData, requestOptions);
}

async function getSingleParentById(parantChangeId) {
  let requestOptions = {
    headers: {
      ...authHeader(),
      "Content-Type": "application/json"
    }
  };
  return axios
    .get(`${APIURL}/document/getSingleParentById/${parantChangeId}`, requestOptions)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return handleErrorResponse(error);
    });
}

function getReport(from, to, email) {
  let requestOptions = {
    headers: {
      ...authHeader(),
      "Content-Type": "application/json"
    }
  };
  let url = `${APIURL}/user/create/report/`;
  if (email || from & to) {
    url = url + "?";
  }
  url = from ? `${url}from = ${from} & to=${to}` : url;
  if (email) {
    url = url.includes("from") ? `${url}&email=${email}` : `${url}email=${email}`;
  }
  return axios
    .get(url, requestOptions)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return handleErrorResponse(error);
    });
}

function getCompanyListModule2() {
  let url = `${APIURL}/module2/getApprovedCustomers`;
  let requestOptions = {
    headers: {
      "Content-Type": "application/json",
      isModule2: true
    }
  };
  return axios
    .get(url, requestOptions)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return handleErrorResponse(error);
    });
}

function sendSearchURLLink(customerName, emailId, parentChangeId) {
  let url = `${APIURL}/module2/sendSearchURLLink`;
  let requestOptions = {
    headers: {
      "Content-Type": "application/json",
      isModule2: true
    }
  };
  let data = {
    customerName: customerName,
    userEmail: emailId,
    parentChangeId: parentChangeId
  };
  return axios
    .post(url, data, requestOptions)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return handleErrorResponse(error);
    });
}

function searchDocuments(searchDoumentData) {
  let url = `${APIURL}/module2/searchDocuments/`;
  let requestOptions = {
    headers: {
      "Content-Type": "application/json",
      isModule2: true
    }
  };
  searchDoumentData = {
    language: searchDoumentData.language || "",
    country: searchDoumentData.country || "",
    keyword: searchDoumentData.keyword || "",
    documentNumber: searchDoumentData.documentNumber || "",
    productName: searchDoumentData.productName || "",
    encryptedEmail: searchDoumentData.encryptedEmail,
    customerName: searchDoumentData.customerName,
    parentChangeId: searchDoumentData.parentChangeId
  };
  console.log("searchDoumentData", searchDoumentData);
  let apiCall = axios.post(url, JSON.stringify(searchDoumentData), requestOptions);
  return apiCall
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return handleErrorResponse(error);
    });
}

function getCustomerLogo(customerName, parentChangeId) {
  let url = `${APIURL}/module2/singleCustomerLogoURL/${customerName}/${parentChangeId}`;
  let requestOptions = {
    headers: {
      "Content-Type": "application/json",
      isModule2: true
    }
  };
  let apiCall = axios.get(url, requestOptions);
  return apiCall
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return handleErrorResponse(error);
    });
}

function getAuditReport(start, end) {
  let requestOptions = {
    headers: {
      ...authHeader(),
      "Content-Type": "application/json"
    }
  };
  return axios
    .get(`${APIURL}/user/create/report?from=${start}&to=${end}`, requestOptions)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return handleErrorResponse(error);
    });
}

function getParentLogoForCustomer(customerName) {
  let requestOptions = {
    headers: {
      "Content-Type": "application/json",
      isModule2: true
    }
  };
  return axios
    .get(`${APIURL}/module2/getParentLogosForCustomer/${customerName}`, requestOptions)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return handleErrorResponse(error);
    });
}

function getApprovedCustomerData() {
  let url = `${APIURL}/module2/getApprovedCustomersData`;
  let requestOptions = {
    headers: {
      "Content-Type": "application/json",
      isModule2: true
    }
  };
  return axios
    .get(url, requestOptions)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return handleErrorResponse(error);
    });
}

function contactUs(data) {
  let contactData = {
    email: data.email,
    name: data.name,
    phoneNumber: data.phoneNumber,
    subject: data.subject,
    textField: data.textField
  };
  const requestOptions = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  return axios
    .post(`${APIURL}/module2/contactUs`, contactData, requestOptions)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return handleErrorResponse(error);
    });
}

function getDocumentURL(docId, email) {
  let documentData = {
    docId: docId,
    encryptedUserEmail: email
  };
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      isModule2: true
    }
  };
  return axios
    .post(`${APIURL}/module2/getDocumentURL`, documentData, requestOptions)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return handleErrorResponse(error);
    });
}

function internalSearchDocuments(searchDoumentData) {
  let url = `${APIURL}/document/searchDocuments/`;
  let requestOptions = {
    headers: {
      ...authHeader(),
      "Content-Type": "application/json"
    }
  };
  searchDoumentData = {
    keywords: searchDoumentData.keyword || "",
    changeOrderNo: searchDoumentData.changeOrderNumber || "",
    productName: searchDoumentData.productName || "",
    customerName: searchDoumentData.customerName || "",
    isArchieveSearch: searchDoumentData.isArchieveSearch || ""
  };
  console.log("searchDoumentData", searchDoumentData);
  let apiCall = axios.post(url, JSON.stringify(searchDoumentData), requestOptions);
  return apiCall
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return handleErrorResponse(error);
    });
}

function getDocumentDownload(id) {
  let url = `${APIURL}/document/getDocumentDownload/${id}`;
  let requestOptions = {
    headers: {
      ...authHeader(),
      "Content-Type": "application/json"
    }
  };
  let apiCall = axios.get(url, requestOptions);
  return apiCall
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return handleErrorResponse(error);
    });
}

function updateAccessibility(data) {
  let url = `${APIURL}/document/update/IsInternal`;
  let requestOptions = {
    headers: {
      ...authHeader(),
      "Content-Type": "application/json"
    }
  };
  let apiCall = axios.post(url, JSON.stringify(data), requestOptions);
  return apiCall
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return handleErrorResponse(error);
    });
}

function getProductByCustomer(id) {
  let url = `${APIURL}/document/getProductByCustomer/${id}`;
  let requestOptions = {
    headers: {
      ...authHeader(),
      "Content-Type": "application/json"
    }
  };
  let apiCall = axios.get(url, requestOptions);
  return apiCall
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return handleErrorResponse(error);
    });
}

function addNotification(data) {
  let requestOptions = {
    headers: {
      ...authHeader()
    }
  };
  return axios
    .post(`${APIURL}/user/addNotification`, data, requestOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return handleErrorResponse(error);
    });
}

async function listNotifications() {
  const requestOptions = {
    headers: {
      ...authHeader()
    },
    Accept: "application/json",
    "Content-Type": "application/json"
  };
  try {
    const notifications = await axios.get(`${APIURL}/user/listNotification/`, requestOptions);
    return notifications.data.list;
  } catch (error) {
    return handleErrorResponse(error);
  }
}

async function updateNotification(requestParams) {
  let requestOptions = {
    headers: {
      ...authHeader(),
    }
  };
  return axios
    .post(`${APIURL}/user/updateNotification`, requestParams, requestOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return handleErrorResponse(error);
    });
}

async function deleteNotification(id) {
  const requestOptions = {
    headers: {
      ...authHeader()
    },
    data: {
      id: id
    }
  };
  return axios
    .delete(`${APIURL}/user/deleteNotification`, requestOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return handleErrorResponse(error);
    });
}
