import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import { Provider } from 'react-redux';
import { store } from './_helpers';

function saveToLocalStorage(state) {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('state', serializedState);
    } catch (e) {
        // console.log(e);
    }
}
store.subscribe(() => saveToLocalStorage(store.getState()));

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('app'));
