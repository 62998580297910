import React, { Component } from 'react';
import { AgGridReact, AllModules } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

class Table extends Component {

  constructor(props) {
    super(props);

    this.state = {
      print: false,
      modules: AllModules,
      columnDefs: [
        {
          headerName: 'Athlete',
          field: 'athlete',
          minWidth: 170,
          checkboxSelection: function (params) {
            return params.columnApi.getRowGroupColumns().length === 0;
          },
          headerCheckboxSelection: function (params) {
            return params.columnApi.getRowGroupColumns().length === 0;
          },
        },
        // { field: 'age' },
        // { field: 'country' },
        // { field: 'year' },
        // { field: 'date' },
        // { field: 'sport' },
        // { field: 'gold' },
        // { field: 'silver' },
        // { field: 'bronze' },
        // { field: 'total' },
      ],
      autoGroupColumnDef: {
        headerName: 'Group',
        minWidth: 170,
        field: 'athlete',
        valueGetter: function (params) {
          if (params.node.group) {
            return params.node.key;
          } else {
            return params.data[params.colDef.field];
          }
        },
        headerCheckboxSelection: true,
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: { checkbox: true },
      },
      defaultColDef: {
        // editable: true,
        sortable: true,
        resizable: true,
        // filter: true,
        flex: 1,
        minWidth: 100,
      },
      rowSelection: 'multiple',
      rowGroupPanelShow: 'always',
      pivotPanelShow: 'always',
      paginationPageSize: 10,
      paginationNumberFormatter: function (params) {
        return '[' + params.value.toLocaleString() + ']';
      },
      rowData: []
    };
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    const httpRequest = new XMLHttpRequest();
    const updateData = data => {
      this.setState({ rowData: data });
      params.api.paginationGoToPage(1);
    };

    httpRequest.open(
      'GET',
      'https://raw.githubusercontent.com/ag-grid/ag-grid/master/grid-packages/ag-grid-docs/src/olympicWinners.json'
    );
    httpRequest.send();
    httpRequest.onreadystatechange = () => {
      if (httpRequest.readyState === 1 && httpRequest.status === 200) {
        updateData(JSON.parse(httpRequest.responseText));
      }
    };
  };

  onPageSizeChanged = newPageSize => {
    var value = document.getElementById('page-size').value;
    this.gridApi.paginationSetPageSize(Number(value));
  };

  render() {
    let { headers, data } = this.props;
    return (
      <div style={{ width: '100%', height: '100%' }}>
        <div className="test-container">

          <div className="test-header">
            Page Size:
            <select defaultValue={10} onChange={() => this.onPageSizeChanged()} id="page-size">
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>

          <div id="myGrid" style={{ height: '520px', width: '100%' }} className="myGrid ag-theme-alpine">
            <AgGridReact
              id='myGrid2'
              modules={this.state.modules}
              columnDefs={headers}
              autoGroupColumnDef={this.state.autoGroupColumnDef}
              defaultColDef={this.state.defaultColDef}
              suppressRowClickSelection={true}
              groupSelectsChildren={true}
              debug={true}
              rowSelection={this.state.rowSelection}

              pagination={data.length < 10 ? false : true}
              paginationPageSize={this.state.paginationPageSize}
              paginationNumberFormatter={this.state.paginationNumberFormatter}
              onGridReady={this.onGridReady}
              rowData={data}
            // domLayout='autoHeight'
            />
          </div>

        </div>
      </div>
    );
  }
}

export default Table;