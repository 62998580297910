import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Error, isEmpty } from './Validation';
import { userActions } from '../_actions/user.actions';
import Message from '../_components/Message';

export const ChangePassword = ({ changePasswordMessage, changePasswordError, changePassword, removeChangeSuccessMessage }) => {
  let user = JSON.parse(localStorage.getItem('user'))
  let auth = JSON.parse(user.config.data);

  const [state, setState] = useState({ email: '', oldPassword: '', newPassword: '', confirmPassword: '' })
  const [value, setValue] = useState({ errors: {}, load: false })

  const handleChange = e => {
    e.persist();
    setState(state => ({ ...state, [e.target.name]: e.target.value }));
  }

  useEffect(() => {
    const { newPassword } = state;
    let errors = {};
    setValue(value => ({ ...value, errors: errors }));
    setTimeout(() => {
      if (changePasswordMessage) {
        removeChangeSuccessMessage();
      }
    }, 2000);
  }, [state, changePasswordMessage]);

  useEffect(() => {
    if (changePasswordError) {
      setValue(value => ({ ...value, error: changePasswordError }));
      setTimeout(() => setValue(value => ({ ...value, error: null })), 7000);
    }

    if (changePasswordMessage) {
      setValue(value => ({ ...value, success: changePasswordMessage }));
      setTimeout(() => setValue(value => ({ ...value, success: null })), 4000);
    }
  }, [changePasswordError, changePasswordMessage])

  const handleSubmit = e => {
    setValue(value => ({ ...value, load: true }));
    if (isEmpty(handleValid())) {
      const data = {
        password: state.newPassword
      }
      setValue(value => ({ ...value, load: false }));
      console.log("this value is " + this);
      changePassword({
        email: state.email,
        newPassword: state.newPassword,
        oldPassword: state.oldPassword
      });
    } else {
      setValue(value => ({ ...value, load: false }))
    }
  }

  const handleValid = e => {
    const { email, newPassword, oldPassword, confirmPassword } = state;
    let errors = {};
    if (!email) errors['email'] = 'Enter the UserName';
    if (!newPassword) errors['new'] = 'Enter New Password';
    if (!oldPassword) errors['old'] = 'Enter Old Password';
    // if (oldPassword && (oldPassword !== auth.password)) errors['old'] = 'Old Password is Wrong';
    if (email && (email !== auth.username)) errors['email'] = 'UserName is Wrong';
    if (newPassword !== confirmPassword) errors['confirm'] = "Password didn't match";
    setValue(value => ({ ...value, errors: errors }))
    console.log(auth.password)
    return errors;
  }

  return (
    <div className="container-fluid">
      <div className="content-wrapper main-bg"></div>
      <div className="page-header">
        <div className="row w-100">
          <div className="col-md-6">
            <h3 className="page-title">
              <span className="page-title-icon bg-gradient-primary text-white mr-2">
                <i className="mdi mdi-account-settings-variant"></i>
              </span>
                            Change Password
                        </h3>
          </div>
        </div>
      </div>
      {changePasswordMessage ? <h6 className="font-weight-light text-center" style={{ color: 'green' }}>{changePasswordMessage}</h6> : changePasswordError ? <h6 className="font-weight-light text-center" style={{ color: 'red' }}>{changePasswordMessage}</h6> : ''}
      <div style={{ background: 'white', padding: '30px', borderRadius: "15px", boxShadow: "2px 2px solid red", overflow: 'auto' }}>
        <form>
          {/* <div className="row mt-4 mb-4 form-row"> */}
          <div className="col-md-6">
            <div className="form-group">
              <label>Email</label>
              <input type="email" className="form-control form-control-lg" id="" placeholder="Enter Email" name="email" value={state.email} onChange={handleChange} />
              <Error error={value.errors['email']} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Current Password</label>
              <input type="password" className="form-control form-control-lg" id="" placeholder="Enter Old Password" name="oldPassword" value={state.oldPassword} onChange={handleChange} />
              <Error error={value.errors['old']} />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label>New Password</label>
              <input type="password" className="form-control form-control-lg" id="" placeholder="Enter New Password" name="newPassword" value={state.newPassword} onChange={handleChange} />
              <Error error={value.errors['new']} />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label>Confirm New Password</label>
              <input type="password" className="form-control form-control-lg" id="" placeholder="Enter New Password" name="confirmPassword" value={state.confirmPassword} onChange={handleChange} />
              <Error error={value.errors['confirm']} />
            </div>
          </div>
          {/* </div> */}
          <div className="col-md-6 text-center">
            <a className={"btn btn-block btn-gradient-primary btn-md fit-content font-weight-medium auth-form-btn m-auto"} onClick={handleSubmit}>{value.load ? 'SUBMITTING...' : 'SUBMIT'}</a>
          </div>
        </form>
      </div>
      <Message error={value.error} success={value.success} />
    </div >
  )
}

const mapStateToProps = state => ({
  changePasswordMessage: state.authentication.changePasswordMessage,
  changePasswordError: state.authentication.changePasswordError,
});

const mapDispatchToProps = {
  changePassword: userActions.changePassword,
  removeChangeSuccessMessage: userActions.removeChangeSuccessMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);