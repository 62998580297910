import React from "react";
import "../assets/css/modal.css";
import "../assets/css/dataTable.css";
import { connect } from "react-redux";
import { countries } from "../_constants/countries";
import { Languages } from "../_constants/functions";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { documentActions } from "../_actions";
import DeleteModal from "../_components/deleteModal";
import { Error, isEmpty } from "../_components/Validation";
import { API } from "../_services";
import Message from "../_components/Message";

// const BaseURL = 'http://lab.xinifynetworks.com:5000/parent';
class DocumentEdit extends React.Component {
  state = {
    document: null,
    users: null,
    customers: null,
    disabled: true,
    files: 0,
    model: false,
    load: false,
    error: null,
    success: null,
    username: "",
    password: "",
    cpassword: "",
    reason: "",
    clickes: 0,
    nonEdit: false,
    DocApprover: true,
    modal: { delParent: false, delChild: false },
    errors: {},
    opened: [],
    country: {},
    language: {},
    editAccessibility: false,
    isInternal: false,
    isAdmin: false
  };

  componentDidMount() {
    let country = {};
    let language = {};
    let users = this.props.users.items;
    let customers = this.props.customers.items;
    let loggedInUser = this.props.auth.user;
    let role = users.filter(o => o.email === loggedInUser)[0].role;
    this.setState({ isAdmin: role === "ADMIN" });
    if (countries) {
      countries.map((v) => {
        country[v.value] = v.label
      });
      this.setState({ country: country });
    }
    if (Languages) {
      Languages.map((v) => {
        language[v.value] = v.label
      });
      this.setState({ language: language });
    }
    this.getDocument();
  }
  countryList = (lists) => {
    let ctr;
    if (lists) {
      let countries = this.state.country;
      lists = lists.split(',')
      if (lists.length > 1) {
        let ctrs = []
        lists.map((v) => { countries[v] && ctrs.push(countries[v]) });
        ctr = ctrs.join()
      } else {
        ctr = countries[lists];
      }
    }
    return ctr;
  }

  languageList = (lists) => {
    let ctr;
    if (lists) {
      let languages = this.state.language;
      lists = lists.split(',')
      if (lists.length > 1) {
        let ctrs = []
        lists.map((v) => { languages[v] && ctrs.push(languages[v]) });
        ctr = ctrs.join()
      } else {
        ctr = languages[lists];
      }
    }
    return ctr;
  }
  getDocument = (e) => {
    var id = this.props.match.params.id;
    // let documents = this.props.parentdocuments.data.list;
    // let myDoc = documents.find((o) => o.parentDocumentChangeId === Number(id));
    let users = this.props.users.items;
    let customers = this.props.customers.items;

    this.props.getSingleParentById(id).then((data) => {
      let totalFiles = data.document && data.document.length + data.parentDocumentList.length;
      let status = data.status === "APPROVED" || data.status === "REJECTED" ? true : false;
      this.setState({
        document: data,
        users: users,
        customers: customers,
        files: totalFiles,
        nonEdit: status
      });
    });
  };

  Clicked = (id) => {
    let ff = this.state.opened;
    if (!ff.includes(id)) {
      this.setState({ opened: ff.concat(id) });
    }
  };
  editChildHandle = (id) => {
    this.props.history.push(`/document/editchild/${id}`);
  };
  editParentHandle = (e) => {
    // e.persist();
    this.props.history.push(`/document/editparent/${this.state.document.parentDocumentChangeId}`);
  };

  deleteParentHandle = (id) => {
    this.setState({ errors: {} });
    if (this.state.username && this.state.password) {
      this.props
        .deleteDocument(
          "parent",
          this.props.match.params.id,
          this.state.username,
          this.state.password
        )
        .then((resp) => {
          if (resp.status === 200) {
            this.setState({ username: "", password: "" });
            this.setState({ success: resp.data.message });
            setTimeout(() => {
              this.setState({ success: null, modal: { delParent: false, delChild: false } });
              this.props.history.goBack();
            }, 3000);
          } else {
            this.setState({ error: resp.data.message });
            setTimeout(() => this.setState({ error: null }), 3000);
          }
        });
      this.setState({ errors: {} });
    } else {
      let errors = {};
      errors["delValidate"] = "Enter Username and Password";
      this.setState({ errors: errors });
    }
  };

  deleteChildHandle = (id) => {
    this.setState({ errors: {} });
    if (this.state.username && this.state.password) {
      this.props
        .deleteDocument("child", id, this.state.username, this.state.password)
        .then((data) => {
          if (data.status === 200) {
            this.setState({ username: "", password: "" });
            this.setState({ success: data.data.message });
            setTimeout(() => {
              this.setState({ success: null, modal: { delParent: false, delChild: false } });
              this.getDocument();
            }, 3000);
          } else {
            this.setState({ error: data.data.message });
            setTimeout(() => this.setState({ error: null }), 3000);
          }
          // this.props.history.go(0);
        });
      this.setState({ errors: {} });
    } else {
      let errors = {};
      errors["delValidate"] = "Enter Username and Password";
      this.setState({ errors: errors });
    }
  };

  addNewChildHandle = (e) => {
    //e.persist();
    this.props.history.push(`/document/${this.state.document.parentDocumentChangeId}/addchild/`);
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  Model = () => {
    this.setState({ model: true });
  };
  ModelClose = () => {
    this.setState({ model: false });
  };

  validation = (e) => {
    const { username, password, cpassword, reason } = this.state;

    let errors = {};
    if (!username) errors["username"] = "Enter Username";
    if (!password) errors["password"] = "Enter Password";
    if (!cpassword) errors["cpassword"] = "Enter Confirm Password";
    if (reason.length > 255) errors["reason"] = "Reason can't be more than 255 characters.";
    if (password && cpassword && cpassword !== password) errors["cpassword"] = "Password Mismatch";
    this.setState({ errors: errors });
    return errors;
  };

  approveHandle = (e) => {
    this.setState({ load: true });
    if (isEmpty(this.validation())) {
      const data = {
        approverId: this.state.document.approverId,
        changeDescription: "",
        changeJustification: "",
        documentApproval: true,
        parentChangeId: this.state.document.parentDocumentChangeId,
        password: this.state.password,
        username: this.state.username,
        reason: this.state.reason
      };
      // console.log(data)
      API.approveDocument(data).then((resp) => {
        if (resp.status === 200) {
          this.setState({ success: resp.data.message });
          this.getDocument();
          setTimeout(() => {
            this.setState({ success: null, model: false });
          }, 2000);
        } else {
          this.setState({ error: resp.data.message });
          setTimeout(() => {
            this.setState({ error: null });
          }, 2000);
        }
      });
      this.setState({ load: false });
    } else {
      this.setState({ load: false });
    }
  };

  rejectHandle = (e) => {
    this.setState({ load: true });
    if (isEmpty(this.validation())) {
      const data = {
        approverId: this.state.document.approverId,
        changeDescription: "",
        changeJustification: "",
        documentApproval: false,
        parentChangeId: this.state.document.parentDocumentChangeId,
        password: this.state.password,
        username: this.state.username,
        reason: this.state.reason
      };
      // console.log(data)
      API.approveDocument(data).then((resp) => {
        if (resp.status === 200) {
          this.setState({ success: "Document Rejected" });
          this.getDocument();
          setTimeout(() => {
            this.setState({ success: null, model: false });
          }, 2000);
        } else {
          this.setState({ error: resp.data.message });
          setTimeout(() => {
            this.setState({ error: null });
          }, 2000);
        }
      });
      this.setState({ load: false });
    } else {
      this.setState({ load: false });
    }
  };

  editAccessibilityHandle = (e) => {
    this.setState({ editAccessibility: true, isInternal: !!this.state.document.isInternal });
  };

  async internalCheck(event) {
    let isInternalSelected = event.target.id === "yes" ? true : false;
    this.setState({ isInternal: isInternalSelected });
    let updateData = {
      parentDocumentChangeId: this.state.document.parentDocumentChangeId,
      isInternal: isInternalSelected,
    };
    await API.updateAccessibility(updateData);
    window.location.reload();
  };

  render() {
    let {
      document,
      approve,
      users,
      customers,
      disabled,
      form,
      model,
      opened,
      DocApprover
    } = this.state;
    let totalFiles = 0;
    let parentFiles = 0;
    let Approver = "";
    let customer = "";
    if (document) {



      console.log(document, "Document");

      if (this.state.opened.length >= this.state.files) {
        disabled = false;
      }

      // Approved = document.status === ('APPROVED' || 'REJECTED') && true;
      // if (document.document.length > 0 && document.document[0].isInternal) {
      //   isInternal = 'Online';
      // }
      Approver = users.find((o) => o.id === Number(document.approverId));
      Approver = Approver && Approver.email;
      DocApprover = Approver !== this.props.auth.user && true;
      customer = customers.find((o) => o.id === Number(document.customerId));
      customer = customer && customer.customerName;
      parentFiles = document.parentDocumentList.length;
      totalFiles = document.document.length + parentFiles;

    }
    return (
      <div className="container-fluid">
        <div className="content-wrapper main-bg"></div>
        <div className="page-header">
          <div className="row w-100">
            <div className="col-md-6">
              <h3 className="page-title">
                <span className="page-title-icon bg-gradient-primary text-white mr-2">
                  {" "}
                  <i className="mdi mdi-file-multiple"></i>
                </span>
                Document
              </h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 grid-margin">
            <div className="card docview">
              {" "}
              <div className="card-body">
                {document ? (
                  <React.Fragment>
                    <div className="parentDoc">
                      <div className="title2">
                        {/* <span className='btn btn-sm' onClick={this.editParentHandle} style={{ float: 'right', backgroundColor: '#ef6c00', color: 'white' }}>Edit Parent</span><br></br> */}
                        {this.state.nonEdit ? null : (
                          <React.Fragment>
                            <span
                              className="mdi mdi-pencil pull-right"
                              onClick={() => this.editParentHandle()}
                              style={{
                                fontSize: "18px",
                                color: "rgb(244, 67, 54)",
                                cursor: "pointer",
                                marginRight: "5px",
                                float: "right"
                              }}></span>
                            <span
                              className="mdi mdi-delete pull-right"
                              onClick={() =>
                                this.setState({
                                  modal: { delParent: true, delChild: false },
                                  id: document.parentDocumentChangeId
                                })
                              }
                              style={{
                                fontSize: "18px",
                                color: "rgb(244, 67, 54)",
                                cursor: "pointer",
                                marginRight: "5px",
                                float: "right"
                              }}></span>
                          </React.Fragment>
                        )}
                        <i className="mdi mdi-file-multiple"></i> Parent Document:
                      </div>
                      <div className="row">
                        <div className="col">
                          <b>Accessibility: </b> {!this.state.editAccessibility ? (!!document.isInternal ? "Offline" : "Online") : ""}
                          {!this.state.editAccessibility && this.state.nonEdit ? (
                            <React.Fragment>
                              <span
                                className="mdi mdi-pencil pull-right"
                                onClick={() => this.editAccessibilityHandle()}
                                style={{
                                  fontSize: "18px",
                                  color: "rgb(244, 67, 54)",
                                  cursor: "pointer",
                                  marginRight: "5px",
                                  float: "middle"
                                }}></span>
                            </React.Fragment>) : null}

                          {this.state.editAccessibility && this.state.isAdmin && <span>
                            <div className="form-check d-inline-block mr-4 editRadioMargin" style={{ margin: '0px 2px 0px 0px !important' }}>
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="isInternal"
                                  id={"no"}
                                  checked={!!this.state.isInternal === false ? true : false}
                                  onChange={(e) => this.internalCheck(e)}
                                />{" "}
                                Online <i className="input-helper" />
                                <i className="input-helper" />
                              </label>
                            </div>
                            <div className="form-check d-inline-block editRadioMargin" style={{ margin: '0px 2px 0px 0px !important' }}>
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="isInternal"
                                  id={"yes"}
                                  checked={!!this.state.isInternal === true ? true : false}
                                  onChange={(e) => this.internalCheck(e)}
                                />{" "}
                                Offline <i className="input-helper" />
                                <i className="input-helper" />
                              </label>
                            </div>
                          </span>}
                        </div>
                        <div className="col">
                          <b>Effective Date: </b> {document.effectiveDate}
                        </div>
                        <div className="col">
                          <b>Change Type: </b> {document.changeType}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <b>Status: </b> {document.status}
                        </div>
                        <div className="col">
                          <b>Approver: </b>
                          {Approver}
                        </div>
                        <div className="col">
                          <b>Customer: </b> {customer}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col"><b>Product Name:</b><div className="text">{document.productFamilyName}</div></div>
                        {document.reason && <div className="col">
                          {document.status === 'APPROVED' && <b>Reason of Approval: </b>}
                          {document.status === 'REJECTED' && <b>Reason of Rejection: </b>}
                          <div className="text">{document.reason} </div>
                        </div>}
                      </div>
                      <div className="row">
                        <div className="col">
                          <b>Description of Change: </b>
                          <div className="text">{document.changeDescription} </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col">
                          <b>Justification of Change: </b>
                          <div className="text">{document.changeJustification} </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <b>Approval/Supporting Document(s):</b> <br />
                          <div className="files">
                            <ul>
                              {document.parentDocumentList.length > 0
                                ? document.parentDocumentList.map((p, k) => {
                                  return (
                                    <li key={k}>
                                      <a
                                        href={p.path}
                                        onClick={() => this.Clicked("p" + p.id)}
                                        target="_blank">
                                        {" "}
                                        {p.docName ? p.docName : `Document ${k + 1}`}
                                      </a>{" "}
                                      {opened.includes("p" + p.id) && (
                                        <i className="mdi mdi-check-all mdi-24px"></i>
                                      )}
                                    </li>
                                  );
                                })
                                : null}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    {this.state.nonEdit ? null : (
                      <React.Fragment>
                        <span
                          className="btn btn-sm"
                          onClick={() => this.addNewChildHandle()}
                          style={{ float: "right", backgroundColor: "green", color: "white" }}>
                          Add New Child
                        </span>
                        <br></br>
                      </React.Fragment>
                    )}
                    <div className="title2">
                      <i className="mdi mdi-file-multiple"></i> Child Documents:
                    </div>

                    <Accordion defaultActiveKey="0">
                      {document.document.length > 0
                        ? document.document.map((v, i) => {
                          return (
                            <Card key={i}>
                              <Accordion.Toggle as={Card.Header} eventKey={v.childDocumentId}>
                                Document - {i + 1}{" "}
                                {opened.includes(v.childDocumentId) && (
                                  <i className="mdi mdi-check-all mdi-24px"></i>
                                )}
                                {this.state.nonEdit ? null : (
                                  <React.Fragment>
                                    <span
                                      className="mdi mdi-pencil pull-right"
                                      onClick={() => this.editChildHandle(v.childDocumentId)}
                                      style={{
                                        fontSize: "18px",
                                        color: "rgb(244, 67, 54)",
                                        cursor: "pointer",
                                        marginRight: "5px",
                                        float: "right"
                                      }}></span>
                                    <span
                                      className="mdi mdi-delete pull-right"
                                      onClick={() =>
                                        this.setState({
                                          modal: { delParent: false, delChild: true },
                                          id: v.childDocumentId
                                        })
                                      }
                                      style={{
                                        fontSize: "18px",
                                        color: "rgb(244, 67, 54)",
                                        cursor: "pointer",
                                        marginRight: "5px",
                                        float: "right"
                                      }}></span>
                                  </React.Fragment>
                                )}
                                {/* <span className='btn btn-sm' onClick={() => this.editChildHandle(v.childDocumentId)} style={{ float: 'right', backgroundColor: '#ef6c00', color: 'white' }}>Edit</span><br></br> */}
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey={v.childDocumentId}>
                                <Card.Body>
                                  <div className="childDoc">
                                    <div className="row">
                                      <div className="col">
                                        <b>Lot Number: </b> {v.associatedReferenceNumber}
                                      </div>
                                      <div className="col">
                                        <b>Part Number: </b>
                                        {v.productSubFamilyNameOne}
                                      </div>
                                      <div className="col">
                                        <b>Bill of Material: </b> {v.productSubFamilyNameTwo}
                                      </div>
                                      <div className="col">
                                        <b>Keywords: </b> {v.keywords}
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col">
                                        <b>Country: </b> {this.countryList(v.countryList)}
                                      </div>
                                      <div className="col">
                                        <b>Language: </b> {this.languageList(v.languageList)}
                                      </div>
                                      <div className="col">
                                        <b>Comments: </b> {v.comments}
                                      </div>
                                      <div className="col">
                                        <b>Child Document Number: </b> {v.childDocumentId}
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col">
                                        <b>Change Justification: </b>
                                        <div className="text">{v.changeJustification}</div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col">
                                        <b>Change Description: </b>
                                        <div className="text">{v.changeDescription}</div>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col">
                                        <b>File:</b>{" "}
                                        <a
                                          href={v.documentPath}
                                          onClick={() => this.Clicked(v.childDocumentId)}
                                          target="_blank">
                                          {" "}
                                          {v.docName ? v.docName : 'doc'}
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>
                          );
                        })
                        : null}
                    </Accordion>
                    <br />
                    <br />
                    {this.state.nonEdit || DocApprover ? null : (
                      <center>
                        <div className="row">
                          <div className="col">
                            <button
                              type="button"
                              className="btn btn-sm btn-success"
                              onClick={this.Model}
                              disabled={disabled}>
                              {" "}
                              Approve
                            </button>
                            <button
                              type="button"
                              className="btn btn-sm btn-danger"
                              onClick={this.Model}
                              disabled={disabled}>
                              Reject
                            </button>
                          </div>
                        </div>
                      </center>
                    )}

                    {model ? (
                      <div className="modal fade show">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="exampleModalLongTitle">
                                Document Approve
                              </h5>
                              <button
                                type="button"
                                className="close"
                                onClick={this.ModelClose}
                                data-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <div className="row" style={{ marginTop: "10px" }}>
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <label>Username</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="username"
                                      name="username"
                                      value={this.state.username}
                                      placeholder=""
                                      onChange={this.handleChange}
                                    />
                                    <Error error={this.state.errors["username"]} />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Password</label>
                                    <input
                                      type="password"
                                      className="form-control"
                                      id="password"
                                      name="password"
                                      value={this.state.password}
                                      placeholder=""
                                      onChange={this.handleChange}
                                    />
                                    <Error error={this.state.errors["password"]} />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Confirm Password</label>
                                    <input
                                      type="password"
                                      className="form-control"
                                      id="cpassword"
                                      name="cpassword"
                                      value={this.state.cpassword}
                                      placeholder=""
                                      onChange={this.handleChange}
                                    />
                                    <Error error={this.state.errors["cpassword"]} />
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <label>Reason</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="reason"
                                      name="reason"
                                      value={this.state.reason}
                                      placeholder=""
                                      onChange={this.handleChange}
                                    />
                                  </div>
                                </div>
                                <Error error={this.state.errors["reason"]} />
                              </div>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={this.ModelClose}>
                                Close
                              </button>
                              {this.state.load ? null : (
                                <React.Fragment>
                                  <button
                                    type="button"
                                    onClick={this.approveHandle}
                                    className="btn btn-success">
                                    Approve
                                  </button>
                                  <button
                                    type="button"
                                    onClick={this.rejectHandle}
                                    className="btn btn-danger">
                                    Reject
                                  </button>
                                </React.Fragment>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </React.Fragment>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        {this.state.modal.delParent || this.state.modal.delChild ? (
          <div className="modal fade show">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLongTitle">
                    {" "}
                    <i className="mdi mdi-delete" />
                    Deactivate Parent Document
                  </h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() =>
                      this.setState({
                        modal: { delParent: false, delChild: false },
                        id: null,
                        errors: {}
                      })
                    }
                    data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row" style={{ marginTop: "10px" }}>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Username</label>
                        <input
                          type="text"
                          className="form-control"
                          id="username"
                          name="username"
                          value={this.state.username}
                          placeholder=""
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Password</label>
                        <input
                          type="password"
                          className="form-control"
                          id="password"
                          name="password"
                          value={this.state.password}
                          placeholder=""
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <Error error={this.state.errors["delValidate"]} />
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() =>
                      this.setState({
                        modal: { delParent: false, delChild: false },
                        id: null,
                        errors: {}
                      })
                    }>
                    <i className="mdi mdi-close" /> Close
                  </button>
                  {this.state.modal.delChild || this.state.modal.delParent ? (
                    <button
                      type="button"
                      onClick={
                        this.state.modal.delChild
                          ? () => this.deleteChildHandle(this.state.id)
                          : this.state.modal.delParent
                            ? () => this.deleteParentHandle(this.state.id)
                            : null
                      }
                      className="btn btn-danger">
                      <i className="mdi mdi-delete" /> Deactivate
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {/* {this.state.modal.delParent ? <DeleteModal modal={true} name={''} onClose={() => this.setState({ modal: { delParent: false, delChild: false }, id: null })} onDelete={() => this.deleteParentHandle(this.state.id)} /> : null}
        {this.state.modal.delChild ? <DeleteModal modal={true} name={''} onClose={() => this.setState({ modal: { delParent: false, delChild: false }, id: null })} onDelete={() => this.deleteChildHandle(this.state.id)} /> : null} */}

        <Message error={this.state.error} success={this.state.success} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
  customers: state.customers,
  auth: state.authentication,
  parentdocuments: state.documents.parentdocuments
});

const mapDispatchToProps = {
  deleteDocument: documentActions.deleteDocument,
  getSingleParentById: documentActions.getSingleParentById
};
export default connect(mapStateToProps, mapDispatchToProps)(DocumentEdit);
