import React, { useState, useEffect } from 'react';
import Starts from './Starts';
import ChangeNew from './Change';
import { Preview } from './Preview';
import { connect } from 'react-redux';
import { customerActions, userActions } from '../_actions';

function AddDocument(props) {
    const [state, setState] = useState({ starts: false, startsData: null, change: false, changeData: null, preview: null, previewData: null, edit: false, id: null, modal: false, viewId: null, parent: false, editDisable: false, childImg: false, parentId: null });
    const [count, setCount] = useState(0);
    const [doc, setDoc] = useState(1);
    const [parent, setParent] = useState(0);
    const [documents, setDocuments] = useState([]);

    useEffect(() => {
        props.listUsers();
        props.listCustomers();
        // console.log(props)
    }, [])

    useEffect(() => {
        setCount(count + 1);
        if (props.document) {
            let { approverId, countryList, changeJustification, changeDescription, cchangeDescription, cchangeJustification, effectiveDate, obsoleteDate, documentId, changeType, isInternal, associatedReferenceNumber, productFamilyName, productSubFamilyNameTwo, productSubFamilyNameOne, productFamilyDescription, keywords, languageList, username, password, comments, customerId, documentPath, approvalDocPath, uploadParentDocumentFileId, docLogoName } = props.document;
            const starts = {
                uploadParentDocumentFileId: uploadParentDocumentFileId,
                approverId: approverId,
                countryExclusionList: countryList,
                changeType: changeType,
                effectiveDate: effectiveDate,
                isInternal: isInternal,
                keywords: keywords,
                associatedReferenceNumber: associatedReferenceNumber,
                productFamilyName: productFamilyName,
                productFamilyDescription: productFamilyDescription,
                productSubFamilyNameOne: productSubFamilyNameOne,
                productSubFamilyNameTwo: productSubFamilyNameTwo,
                startsImage: true,
                startsImageName: documentPath,
                docLogoName: docLogoName,
                docLogo: true,
                changeJustification: changeJustification,
                changeDescription: changeDescription,
            }
            const change = {
                cchangeJustification: cchangeJustification,
                cchangeDescription: cchangeDescription,
                obsoleteDate: obsoleteDate,
                language: languageList,
                obsoleteDate: obsoleteDate,
                customerId: customerId,
                changeImage: true,
                changeImageName: approvalDocPath,
            }
            const preview = {
                username: username,
                password: password,
                comments: comments,
            }
            setCount(count + 1);
            // console.log('this called')
            setState(state => ({ ...state, startsData: starts, changeData: change, edit: true, id: documentId, previewData: preview, editDisable: true }));
        } else {
            setCount(count + 1);
            setState({ starts: false, startsData: null, change: false, changeData: null, preview: null, previewData: null, edit: false, id: null });
        }
    }, [props.document]);

    const addNewDocument = data => {
        data['newDocumentId'] = doc;
        setDoc(doc + 1);
        setDocuments([...documents, data]);
        setState({ starts: false, startsData: { uploadParentDocumentFileId: data.uploadParentDocumentFileId, startsImage: data.mainDocument, isInternal: data.isInternal, changeDescription: data.changeDescription, changeJustification: data.changeJustification, changeType: data.changeType, approverId: data.approverId, customerId: data.customerId, effectiveDate: data.effectiveDate, productFamilyName: data.productFamilyName, docLogo: data.docLogo, docLogoName: data.docLogoName, uploadFiles: data.uploadFiles }, parent: true, change: false, changeData: null, preview: null, previewData: null, edit: false, id: null, childImg: false });
    }

    const editDocument = data => {
        // console.log('edit ', documents)
        let myDocuments = documents;
        let myData = myDocuments.filter(function (obj) { return obj.newDocumentId !== data.newDocumentId; });
        setDocuments(myData);
        // console.log(documents)
        let { approverId, countryList, changeJustification, changeDescription, cchangeDescription, cchangeJustification, effectiveDate, obsoleteDate, documentId, changeType, isInternal, associatedReferenceNumber, productFamilyName, productSubFamilyNameTwo, productSubFamilyNameOne, productFamilyDescription, keywords, languageList, customerId, documentPath, approvalDocPath, mainDocument, childDocument, uploadParentDocumentFileId, docLogo, docLogoName, uploadChildDocumentFileId, uploadFiles } = data;
        const starts = {
            uploadParentDocumentFileId: uploadParentDocumentFileId,
            approverId: approverId,
            changeType: changeType,
            effectiveDate: effectiveDate,
            isInternal: isInternal,
            customerId: customerId,
            changeJustification: changeJustification,
            changeDescription: changeDescription,
            // productFamilyName: productFamilyName,
            // productFamilyDescription: productFamilyDescription,
            startsImage: mainDocument,
            startsImageName: mainDocument ? mainDocument.name : '',
            docLogo: docLogo,
            docLogoName: docLogo ? docLogoName.name : '',
            files: mainDocument ? 1 : 0,
            // uploadChildDocumentFileId: uploadChildDocumentFileId,
            productFamilyName: productFamilyName,
            uploadFiles: uploadFiles,
        }
        const change = {
            associatedReferenceNumber: associatedReferenceNumber,
            productSubFamilyNameOne: productSubFamilyNameOne,
            productSubFamilyNameTwo: productSubFamilyNameTwo,
            keywords: keywords,
            countryExclusionList: countryList,
            cchangeJustification: cchangeJustification,
            cchangeDescription: cchangeDescription,
            obsoleteDate: obsoleteDate,
            language: languageList,
            obsoleteDate: obsoleteDate,
            changeImage: childDocument,
            changeImageName: childDocument ? childDocument.name : '',
            uploadChildDocumentFileId: uploadChildDocumentFileId,
        }
        setCount(count + 1);
        setState(state => ({ ...state, starts: false, change: false, parent: true, childImg: true, preview: null, startsData: starts, changeData: change, modal: false }));
    }

    const removeDocument = id => {
        let doc = documents;
        let docs = doc.filter((o) => { return o.newDocumentId !== id });
        setDocuments(docs);
        setState(state => ({ ...state, modal: false }));
    }
    // console.log(state.uploadParentDocumentFileId)
    return (
        <React.Fragment>
            {!state.starts ?
                <Starts key={count}
                    documentsList={documents}
                    next={() => setState(state => ({ ...state, starts: true }))}
                    editDoc={(data) => editDocument(data)}
                    parent={state.parent}
                    parentId={(id) => setParent(id)}
                    states={state.startsData}
                    data={(datas) => setState(state => ({ ...state, startsData: datas }))}
                    action="addDocument"
                />
                :
                state.starts && !state.change ?
                    <ChangeNew
                        next={() => setState(state => ({ ...state, change: true }))}
                        states={state.changeData}
                        childImg={state.childImg}
                        back={() => setState(state => ({ ...state, starts: false }))}
                        data={(datas) => setState(state => ({ ...state, changeData: datas }))}
                    />
                    :
                    state.change && !state.preview ?
                        <Preview
                            states={state.previewData}
                            documentsList={documents}
                            addDoc={(data) => addNewDocument(data)}
                            back={() => setState(state => ({ ...state, change: false, starts: true }))}
                            data={(datas) => setState(state => ({ ...state, previewData: datas }))}
                            edit={state.edit}
                            id={state.id}
                            parentId={parent}
                            docEdit={state.editDisable}
                            origAllState={{ start: state.startsData, change: state.changeData }}
                            allStates={{ ...state.startsData, ...state.changeData }} />
                        :
                        'not found'
            }

            {state.modal ?
                <div className="modal">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Uploaded Documents ({documents.length})</h5>
                                <button type="button" onClick={() => setState(state => ({ ...state, modal: false }))} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">

                                <ul className="list-unstyled doc-list-ul mb-0">
                                    {documents.length > 0 ? documents.map((value, i) => {
                                        return (<li key={i}>
                                            <div className="row d-flex justify-content-between">
                                                <div className="col">
                                                    <b>Document - {value.associatedReferenceNumber}</b>
                                                </div>
                                                <div className="col text-right">
                                                    <div style={{ fontSize: '16px' }}>
                                                        <span style={{ marginRight: '5px', cursor: 'pointer' }} onClick={() => setState(state => ({ ...state, viewId: value.newDocumentId }))}><i className="mdi mdi-eye text-info"></i></span>
                                                        <span style={{ cursor: 'pointer' }} onClick={() => editDocument(value)}><i className="mdi mdi-pencil text-warning"></i></span>
                                                        <span style={{ cursor: 'pointer' }} onClick={() => removeDocument(value.newDocumentId)}><i className="mdi mdi-delete text-danger"></i></span>
                                                    </div>
                                                </div>
                                            </div>
                                            {state.viewId === value.newDocumentId ?
                                                <table className="table table-sm">
                                                    <tbody>
                                                        <tr>
                                                            <td>IFU Number</td>
                                                            <th>{value.associatedReferenceNumber}</th>
                                                        </tr>
                                                        <tr>
                                                            <td>Description of IFU</td>
                                                            <th>{value.productFamilyDescription}</th>
                                                        </tr>
                                                        <tr>
                                                            <td>Language</td>
                                                            <th>{value.languageList}</th>
                                                        </tr>
                                                        <tr>
                                                            <td>Change Type</td>
                                                            <th>{value.changeType}</th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                : null}
                                        </li>)

                                    }) : 'No Documents Saved'}
                                </ul>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => setState(state => ({ ...state, modal: false }))} data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>

                : null}
            {!state.editDisable &&
                <div className="q-doc-number">
                    <div className="btn btn-gradient-success" onClick={() => setState(state => ({ ...state, modal: true }))} style={{ position: 'absolute', color: '#fff', bottom: '40px', right: '15px', zIndex: '99999', borderRadius: '50px' }} data-toggle="modal" data-target="#documentslistModal">
                        Uploaded Doc <span className="badge badge-secondary">{documents.length}</span>
                    </div>
                </div>}
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    document: state.documents.document
});

const mapDispatchToProps = {
    listUsers: userActions.listUsers,
    listCustomers: customerActions.listCustomers,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDocument);