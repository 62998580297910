import {
  userConstants
} from '../_constants';
import {
  API
} from '../_services';
import {
  alertActions
} from './';
import {
  history
} from '../_helpers';

export const userActions = {
  login,
  forgotPassword,
  changePassword,
  setPassword,
  logout,
  isValidUser,
  addUser,
  updateUser,
  listUsers,
  deleteUser,
  notifyAdmin,
  removeChangeSuccessMessage,
  getReport,
  contactUs,
  removeContactUsFailureMessage,
  removeContactUsSuccessMessage
};

function login(username, password) {
  return dispatch => {
    dispatch(request({
      username
    }));

    API.login(username, password)
      .then(
        user => {
          dispatch(success(username, user.data.role));
          history.push('/');
        },
        error => {
          dispatch(failure(error));
          //dispatch(alertActions.error(error));
        }
      );
  };

  function request(user) {
    return {
      type: userConstants.LOGIN_REQUEST,
      user
    }
  }

  function success(user, role) {
    return {
      type: userConstants.LOGIN_SUCCESS,
      user,
      role
    }
  }

  function failure(error) {
    return {
      type: userConstants.LOGIN_FAILURE,
      error
    }
  }
}

function forgotPassword(username) {
  return dispatch => {
    API.forgotPassword(username)
      .then(
        response => {
          dispatch(success(response.data.message));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function success(message) {
    return {
      type: userConstants.FORGOT_PASSWORD_SUCCESS,
      message
    }
  }

  function failure(error) {
    return {
      type: userConstants.FORGOT_PASSWORD_FAILURE,
      error
    }
  }
}

function changePassword(changePasswordData) {
  return dispatch => {
    API.changePassword(changePasswordData)
      .then(
        response => {
          if (response.status === 200) {
            dispatch(success(response.data.message));
          } else {
            dispatch(failure(response.data.message));
          }
        },
        // error => {
        //   dispatch(failure(error));
        // }
      );
  };

  function success(message) {
    return {
      type: userConstants.CHANGE_PASSWORD_SUCCESS,
      message
    }
  }

  function failure(error) {
    return {
      type: userConstants.CHANGE_PASSWORD_FAILURE,
      error
    }
  }
}

function setPassword(data) {
  return dispatch => {
    API.setPassword(data)
      .then(
        response => {
          dispatch(success(response.data.message));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function success(message) {
    return {
      type: userConstants.SET_PASSWORD_SUCCESS,
      message
    }
  }

  function failure(error) {
    return {
      type: userConstants.SET_PASSWORD_FAILURE,
      error
    }
  }
}

function removeChangeSuccessMessage() {
  return dispatch => {
    dispatch(remove(""));
  };

  function remove(message) {
    return {
      type: userConstants.REMOVE_CHANGE_USER_SUCCESS_MESSAGE,
      message
    }
  }
}

function isValidUser(username, redirect = false) {
  console.log("Action Called Is valid User", username);
  return dispatch => {
    if (!redirect) {
      API.isValidUser(username)
        .then(
          user => {
            if (user.data.emailExist && user.data.userActive && user.data.passwordActive) {
              //   dispatch(failure(user));
              dispatch(success(user));
            } else if (!user.data.emailExist) {
              dispatch(failure("activate email"));
              history.push({
                pathname: '/sendRequest',
                state: {
                  username: username
                }
              });
            } else if (!user.data.userActive) {
              dispatch(failure("activate user"));
              history.push('/sendRequest');
            } else if (!user.data.passwordActive) {
              dispatch(failure("reset password"));
              let token = user.token || '';
              history.push('/user/setpassword/' + token);
            }
          },
          error => {
            console.log('ddd')
            dispatch(failure(error));
            dispatch(alertActions.error(error));
          }
        );
    } else {
      dispatch(loginRedirect(true));
      // history.push('/login');
    }
  };

  function request(user) {
    return {
      type: userConstants.CHECK_USER_REQUEST,
      user
    }
  }

  function success(user) {
    return {
      type: userConstants.CHECK_USER_SUCCESS,
      user
    }
  }

  function failure(error) {
    return {
      type: userConstants.CHECK_USER_FAILURE,
      error
    }
  }

  function loginRedirect(data) {
    return {
      type: userConstants.LOGIN_REDIRECT,
      data
    }
  }
}

function logout() {
  API.logout();
  return {
    type: userConstants.LOGOUT
  };
}

function addUser(userData) {
  console.log("Action Add User Called");
  return dispatch => {
    API.addUser(userData)
      .then(
        user => {
          if (user.status === 200) {
            let uname = `${userData.firstName} ${userData.lastName}`;
            dispatch(success(uname + 'User added successfully!!'));
          } else {
            console.log("response is", user);
            dispatch(failure(user.data.message));
          }
          API.listUser()
            .then(
              users => {
                dispatch(successList(users));
              },
              error => {
                dispatch(failure(error));
              }
            );
        },
        error => {
          dispatch(failure('Failed to add user'));
        }
      );
  };

  function successList(users) {
    return {
      type: userConstants.CHECK_LIST_USER_SUCCESS,
      users
    }
  }

  function success(message) {
    return {
      type: userConstants.CHECK_ADD_USER_SUCCESS,
      message
    }
  }

  function failure(error) {
    return {
      type: userConstants.CHECK_ADD_USER_FAILURE,
      error
    }
  }
}

function updateUser(userData) {
  console.log("Action Add User Called");
  return dispatch => {
    API.updateUser(userData)
      .then(
        response => {
          dispatch(success('User updated successfully!!'));
          API.listUser()
            .then(
              users => {
                dispatch(successList(users));
              },
              error => {
                dispatch(failure(error));
              }
            );
        },
        error => {
          dispatch(failure('Failed to update user'));
        }
      );
  };

  function successList(users) {
    return {
      type: userConstants.CHECK_LIST_USER_SUCCESS,
      users
    }
  }

  function success(message) {
    return {
      type: userConstants.CHECK_UPDATE_USER_SUCCESS,
      message
    }
  }

  function failure(message) {
    return {
      type: userConstants.CHECK_UPDATE_USER_FAILURE,
      message
    }
  }
}

function listUsers() {
  console.log("Action List User Called");
  return dispatch => {
    API.listUser()
      .then(
        users => {
          dispatch(success(users));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function success(users) {
    return {
      type: userConstants.CHECK_LIST_USER_SUCCESS,
      users,
      message: "List User successful"
    }
  }

  function failure(error) {
    return {
      type: userConstants.CHECK_LIST_USER_FAILURE,
      error
    }
  }
}

function deleteUser(id) {
  console.log("Action Delete User Called");
  return dispatch => {
    API.deleteUser(id)
      .then(
        resp => {
          dispatch(success('User deleted succesfully.'));
          API.listUser()
            .then(
              users => {
                dispatch(successList(users));
              },
              error => {
                // dispatch(failure(error));
              }
            );
        },
        error => {
          dispatch(failure("Failed to delete user."));
        }
      );
  };

  function successList(users) {
    return {
      type: userConstants.CHECK_LIST_USER_SUCCESS,
      users
    }
  }

  function success(message) {
    return {
      type: userConstants.CHECK_DELETE_USER_SUCCESS,
      message,
      id
    }
  }

  function failure(error) {
    return {
      type: userConstants.CHECK_DELETE_USER_FAILURE,
      error
    }
  }
}

function notifyAdmin(username) {
  return dispatch => {
    API.notifyAdmin(username)
      .then(
        user => {
          dispatch(success("Notified the administrator"));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function success(message) {
    return {
      type: userConstants.NOTIFIED_ADMIN_SUCCESS,
      message
    }
  }

  function failure(message) {
    return {
      type: userConstants.NOTIFIED_ADMIN_FAILURE,
      message
    }
  }
}

function getReport(from, to, email) {
  return dispatch => {
    return API.getReport(from, to, email)
      .then(
        data => {
          return data;
        },
        error => {
          return error;
        }
      );
  };
}

function contactUs(contactUsData) {
  return dispatch => {
    API.contactUs(contactUsData)
      .then(
        response => {
          if (response.status === 200) {
            dispatch(success(response.data.message));
          } else {
            dispatch(failure(response.data || response.data.message));
          }
        },
      );
  };

  function success(message) {
    return {
      type: userConstants.CONTACT_US_SUCCESS,
      message
    }
  }

  function failure(error) {
    return {
      type: userConstants.CONTACT_US_FAILURE,
      error
    }
  }
}


function removeContactUsSuccessMessage() {
  return dispatch => {
    dispatch(remove(""));
  };

  function remove(message) {
    return {
      type: userConstants.CONTACT_US_SUCCESS,
      message
    }
  }
}

function removeContactUsFailureMessage() {
  return dispatch => {
    dispatch(remove(""));
  };

  function remove(message) {
    return {
      type: userConstants.CONTACT_US_FAILURE,
      message
    }
  }
}