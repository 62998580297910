import {
  userConstants
} from '../_constants';

export function notifications(state = {}, action) {
  switch (action.type) {
    case userConstants.CHECK_ADD_NOTIFICATION_SUCCESS:
      return {
        ...state, message: action.message
      }
    case userConstants.CHECK_ADD_NOTIFICATION_FAILURE:
      return {
        ...state, error: action.error
      }
    case userConstants.CHECK_UPDATE_NOTIFICATION_SUCCESS:
      return {
        ...state, message: action.message
      }
    case userConstants.CHECK_UPDATE_NOTIFICATION_FAILURE:
      return {
        ...state, error: action.error
      }
    case userConstants.LIST_NOTIFICATION_SUCCESS:
      return {
        ...state, message: action.message, items: action.notifications
      };
    case userConstants.CHECK_DELETE_NOTIFICATION_SUCCESS:
      // remove deleted notification from state
      return {
        ...state,
        items: state.items.filter(notification => notification.id !== action.id),
          message: action.message
      };
    case userConstants.ADD_SELECTED_NOTIFICATION_SUCCESS:
      return {
          ...state, selectedNotification: action.notificationData,
      };
    case userConstants.CHECK_DELETE_NOTIFICATION_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to notification
      return {
        ...state,
        items: state.items.map(notification => {
          if (notification.id === action.id) {
            // make copy of notification without 'deleting:true' property
            const {
              deleting,
              ...userCopy
            } = notification;
            // return copy of notification with 'deleteError:[error]' property
            return {
              ...userCopy,
              deleteError: action.error
            };
          }
          return notification;
        }), error: action.error
      };
    default:
      return state
  }
}