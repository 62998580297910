import React from 'react';
const Footer = () => {
  return (
    <footer className="footer" >
      <div className="d-sm-flex justify-content-center" style={{ bottom: '0px', fontSize: '8pt' }}>
        <span>Powered by <b>Shedpaper</b></span>
      </div>
    </footer>


  )
}
export default Footer;
