import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { store } from "../_helpers";
import { history } from "../_helpers";
import { alertActions, userActions } from "../_actions";
import { HomePage } from "../HomePage";
import { LoginPage } from "../LoginPage";
import { SendRequest } from "../SendRequest";
import { DocSearch } from "../DocSearch";
import { CustomerDocSearch } from "../CustomerDocSearch";
import "../assets/css/materialdesignicons.min.css";
import "../assets/css/vendor.bundle.base.css";
import "../assets/css/style.css";
import Query from "../Allpage/Query";
import NProgress from "react-nprogress/nprogress";
import "react-nprogress/nprogress.css";
import "../assets/css/common.css";
import axios from "axios";
import "./app.css";
import Forgot from "../LoginPage/Forgot";
import ErrorBoundary from "../_helpers/errorBoundary";
import JwtDecode from "jwt-decode";
import moment from "moment";
import ContactUs from '../_components/ContactUs';

class App extends React.Component {
  state = { status: null };
  constructor(props) {
    super(props);
    // console.log("store is", store.getState());
    this.state = {};
  }
  enableAxiosInterceptor() {
    axios.interceptors.request.use(
      (config) => {
        NProgress.start();
        return config;
      },
      (error) => {
        NProgress.done();
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response) => {
        NProgress.done();
        return response;
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem("state");
          this.props.logout();
          window.location.href = '/login';
        }
        // try {
        // } catch (err) {
        //   return Promise.reject(error);
        // }
        NProgress.done();
        return Promise.reject(error);
      }
    );
  }

  disableAxiosInterceptor() { }
  storageChange(event) {
    if (event.key === "authToken") {
      if (event.oldValue === null && event.newValue === null) {
        this.props.logout();
      }
    }
  }
  componentDidMount() {
    let user = JSON.parse(localStorage.getItem("user"));
    let token = user && user.data && user.data.message;
    // console.log(token)
    window.addEventListener("storage", this.storageChange, false);
    // if (token) {
    //   let jwt = JwtDecode(token).exp;
    //   let newDate = new Date();
    //   localStorage.removeItem("state");
    //   window.location.reload();
    //   // if (jwt < newDate.getTime()) {
    //   //   // console.log('if part')
    //   //   let time = jwt * 1000 - newDate.getTime();
    //   //   setTimeout(() => {
    //   //     localStorage.removeItem("state");
    //   //     window.location.reload();
    //   //   }, time);
    //   // } else {
    //   //   // console.log('else part')
        
    //   // }
    // }
    this.enableAxiosInterceptor();
    this.inactivityMonitor();
    try {
      window.addEventListener("mousemove", this.sessionMonitor, false);
    } catch (e) { }
  }
  inactivityMonitor() {
    let vm = this;
    vm.idleTime = 0;
    //window.onload = resetTimer;
    document.onmousemove = resetTimer;
    document.onkeypress = resetTimer;
    function resetTimer() {
      clearTimeout(vm.idleTime);
      vm.idleTime = setTimeout(vm.expiredLogout, 600000);
    }
  }
  sessionMonitor(event) {
    let now = moment().unix();
    if (now >= this.expiryTime) {
      this.expiredLogout();
    }
  }
  expiredLogout() {
    console.log("removing state");
    localStorage.removeItem("state");
    localStorage.clear();
    window.location.reload();
  }
  componentWillUnmount() {
    //this.disableAxiosInterceptor()
    try {
      window.removeEventListener("mousemove", this.sessionMonitor);
      window.removeEventListener("storage", this.storageChange);
    } catch (error) {
      console.log("Component unmount error", error);
    }
  }
  render() {
    let loggedIn = this.props.login;
    return (
      <Router history={history}>
        <ErrorBoundary>
          <Switch>
            <Route exact path="/docSearch/:customerName" component={DocSearch} />
            <Route exact path="/customerDocSearch" component={CustomerDocSearch} />
            <Route exact path="/sendRequest" component={SendRequest} />
            <Route exact path="/user/setpassword/:id" component={Forgot} />
            <Route exact path="/user/setpassword/" component={Forgot} />
            <Route path="/contactus" component={ContactUs} />
            {loggedIn ? (
              <HomePage />
            ) : (
                <>
                  <Redirect to="/login" />
                  <Route path="/login" component={LoginPage} />
                </>
              )}
          </Switch>
        </ErrorBoundary>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  login: state.authentication.loggedIn ? state.authentication.loggedIn : "",
  auth: state
});

const actionCreators = {
  logout: userActions.logout
};

const connectedApp = connect(mapStateToProps, actionCreators)(App);
export { connectedApp as App };
