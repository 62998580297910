import React, { useState, useEffect } from 'react'
import { Error, isEmpty } from '../_components/Validation';
import { customerActions } from '../_actions';
import { connect } from 'react-redux';
import Message from '../_components/Message';
import { API } from '../_services';

function CustomerApprove(props) {

  const [state, setState] = useState({ approveDisplay: false, rejectDisplay: false, errors: {}, load: false, error: null, success: null, body: null });
  const [form, setForm] = useState({ username: '', password: '', cpassword: '', reason: '' });

  useEffect(() => {
    setState(state => ({ ...state, body: props.data }))
  }, [])

  const handleApprove = e => {
    setState(state => ({ ...state, approveDisplay: true, rejectDisplay: false }));
    // console.log('approve');
  }

  const handleReject = e => {
    setState(state => ({ ...state, rejectDisplay: true, approveDisplay: false }));
    // console.log('reject');
  }

  const handleChange = e => {
    e.persist();
    setForm(form => ({ ...form, [e.target.name]: e.target.value }));
  }

  const handleSubmit = e => {
    setState(state => ({ ...state, load: true }));
    if (isEmpty(validate())) {
      const data = {
        "customerApproval": true,
        "customerApproverId": props.data.id,
        "customerId": props.data.id,
        "changeDescription": "abc",
        "changeJustification": "def",
        "username": form.username,
        "password": form.password,
      }
      console.log(data)
      // props.approveCustomer(data);
      // setTimeout(() => {
      //   props.onClose();
      // }, 2000);
      API.approveCustomer(data).then(resp => {
        if (resp.status === 200) {
          setState(state => ({ ...state, success: resp.data.message }));
          setTimeout(() => {
            setState(state => ({ ...state, success: null }));
            props.onComplete();
          }, 2000);
        } else {
          setState(state => ({ ...state, error: resp.data.message }));
          setTimeout(() => {
            setState(state => ({ ...state, error: null }));
          }, 2000);
        }
      })
      setState(state => ({ ...state, load: false }));
    } else {
      setState(state => ({ ...state, load: false }));
    }
  }

  const handleReasonSubmit = e => {
    setState(state => ({ ...state, load: true }));
    if (form.reason) {
      const data = {
        "customerApproval": false,
        "customerApproverId": Number(props.data.approverId),
        "customerId": props.data.id,
        "changeDescription": 'abc',
        "changeJustification": form.reason,
        "username": form.username,
        "password": form.password,
      }
      // console.log(data)
      // props.approveCustomer(data);
      // setTimeout(() => {
      //   props.onClose();
      // }, 2000);
      API.approveCustomer(data).then(resp => {
        if (resp.status === 200) {
          setState(state => ({ ...state, success: resp.data.message }));
          setTimeout(() => {
            setState(state => ({ ...state, success: null }));
            props.onComplete();
          }, 2000);
        } else {
          setState(state => ({ ...state, error: resp.data.message }));
          setTimeout(() => {
            setState(state => ({ ...state, error: null }));
          }, 2000);
        }
      })
      setState(state => ({ ...state, load: false }));
    } else {
      let errors = {};
      errors['reason'] = 'Enter Reason';
      setState(state => ({ ...state, errors: errors }));
    }
  }

  const validate = e => {
    let errors = {};
    const { username, password, cpassword } = form;
    if (!username) errors['name'] = 'Enter Username';
    if (!password) errors['password'] = 'Enter Password';
    if (password && cpassword && (password !== cpassword)) errors['confirm'] = 'Password Mismatch';
    setState(state => ({ ...state, errors: errors }));
    return errors;
  }

  useEffect(() => {
    let errors = {};
    if (form.password && form.cpassword && (form.password !== form.cpassword)) errors['confirm'] = 'Password Mismatch';
    setState(state => ({ ...state, errors: errors }));
  }, [form]);
  // console.log(props.customer)
  return (
    <div className="modal fade show" id="exampleModalLong" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="exampleModalLabel">{props.title} {props.data.customerName}</h4>
            <button type="button" onClick={props.onClose} className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body text-center">
            <div className="col-md-12">
              Email - {state.body ? state.body.contactEmail : null}<br></br>
              Mobile - {state.body ? state.body.contactInformation : null}<br></br>
              <button type="button" className="btn btn-sm btn-success" style={{ margin: '10px' }} onClick={handleApprove}>Approve</button>
              <button type="button" className="btn btn-sm btn-danger" style={{ margin: '10px' }} onClick={handleReject}>Reject</button>
            </div>
            <div className="col-md-12">
              {state.approveDisplay ?
                <div className='show'>
                  <div className="row" style={{ marginTop: '10px' }}>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Username</label>
                        <input type="text" className="form-control" id="username" name="username" value={form.username} placeholder="" onChange={handleChange} />
                        <Error error={state.errors['name']} />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Password</label>
                        <input type="password" className="form-control" id="password" name="password" value={form.password} placeholder="" onChange={handleChange} />
                        <Error error={state.errors['password']} />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Confirm Password</label>
                        <input type="password" className="form-control" id="cpassword" name="cpassword" value={form.cpassword} placeholder="" onChange={handleChange} />
                        <Error error={state.errors['confirm']} />
                      </div>
                    </div>
                    {form.username && form.password && form.cpassword && isEmpty(state.errors) ?
                      <div className="col-md-12 form-group text-center">
                        <div className="col-md-12 text-center">
                          <span className="btn btn-block btn-gradient-primary btn-md fit-content font-weight-medium auth-form-btn m-auto" onClick={state.load ? null : handleSubmit}>{state.load ? 'Submitting...' : 'Approve'}</span>
                        </div>
                      </div>
                      : null}

                  </div>
                </div>
                :
                null
              }
              {state.rejectDisplay ?
                <div className='show'>
                  <div className="row" style={{ marginTop: '10px' }}>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Username</label>
                        <input type="text" className="form-control" id="username" name="username" value={form.username} placeholder="" onChange={handleChange} />
                        <Error error={state.errors['name']} />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Password</label>
                        <input type="password" className="form-control" id="password" name="password" value={form.password} placeholder="" onChange={handleChange} />
                        <Error error={state.errors['password']} />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Confirm Password</label>
                        <input type="password" className="form-control" id="cpassword" name="cpassword" value={form.cpassword} placeholder="" onChange={handleChange} />
                        <Error error={state.errors['confirm']} />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Reason</label>
                        <input type="text" className="form-control" id="reason" name="reason" value={form.reason} placeholder="" onChange={handleChange} />
                        <Error error={state.errors['reason']} />
                      </div>
                    </div>
                    {form.reason && form.username && form.password && form.cpassword && isEmpty(state.errors) ?
                      <div className="col-md-12 form-group text-center">
                        <div className="col-md-12 text-center">
                          <span className="btn btn-block btn-gradient-primary btn-md fit-content font-weight-medium auth-form-btn m-auto" onClick={state.load ? null : handleReasonSubmit}>{state.load ? 'Submitting...' : 'Reject'}</span>
                        </div>
                      </div>
                      : null}
                  </div>
                </div>
                :
                null
              }
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-sm btn-secondary" data-dismiss="modal" onClick={props.onClose}>Close</button>
          </div>
        </div>
      </div>
      <Message error={state.error} success={state.success} />
    </div>
  )
}

const mapStateToProps = state => ({
  customer: state
})

const mapDispatchToProps = {
  approveCustomer: customerActions.approveCustomer
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerApprove);