import React from 'react';

export default function DeleteModal(props) {

    return (
        <React.Fragment>

            <div className='modal'>
                <div className='modal_inner'>
                    <h1 className="text-center pt-3" style={{ fontFamily: "Times New Roman", fontSize: "20px" }}>
                        <i style={{ fontSize: '21px' }} className="mdi mdi-delete menu-icon ml-3"></i> {props.title ? props.title : 'Are you sure, you want to deactivate'} {props.name}?
                        <span className="mdi mdi-close-circle float-right modal-close" style={{ cursor: 'pointer' }} onClick={() => props.onClose()}></span>
                    </h1>
                    <div className="modal-footer">
                        <button onClick={() => props.onDelete()} className="btn btn-sm btn-danger">{props.edit ? 'Edit' : props.add ? 'Add' : props.deleteContext || 'Delete'}</button>
                        <button onClick={() => props.onClose()} className="btn btn-sm" data-dismiss="modal" aria-hidden="true">Close</button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
