import React, { Component } from 'react'

 class Query extends Component {
    render() {
        return (
            <div>
                <h1>query component</h1>
            </div>
        )
    }
}
export default Query;