import React from 'react';
import { isEmpty, Error } from '../_components/Validation';
import { Capitalize } from '../_constants/functions';
import { documentActions } from '../_actions';
import { connect } from 'react-redux';
import moment from 'moment';
import Message from '../_components/Message';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { API } from '../_services';

class Preview extends React.Component {
  constructor(props) {
    super(props);
    this.state = { errors: {}, username: '', password: '', comments: '', allData: null };
  }

  componentDidMount() {
    this.setState({ ...this.props.states, allData: this.props.allStates });
    console.log(this.props.allStates)
  }

  changeInput = e => {
    this.setState({ [e.target.name]: e.target.value });
  }

  validation = e => {
    const { username, password, comments } = this.state;

    let errors = {};
    if (!username) errors['username'] = 'Enter Username';
    if (!password) errors['password'] = 'Enter Password';
    if (!comments) errors['comments'] = 'Enter Comments';
    this.setState({ errors: errors });
    return errors;
  }

  handleSubmit = e => {
    e.preventDefault();
    if (isEmpty(this.validation())) {
      // this.setState({ errors: {} });
      // console.log(this.state)
      let { effectiveDate, approvedDate, changeDescription, changeJustification, cchangeDescription, cchangeJustification, keywords, language, changeImage, obsoleteDate, countryExclusionList, approverId, changeType, startsImage, associatedReferenceNumber, isInternal, productFamilyDescription, productFamilyName, productSubFamilyNameOne, productSubFamilyNameTwo, customerId, uploadChildDocumentFileId, uploadParentDocumentFileId } = this.state.allData;

      let childDoc = [];

      let documentsList = this.props.documentsList;
      console.log('list ', documentsList)
      const data = {
        associatedReferenceNumber: associatedReferenceNumber,
        changeDescription: changeDescription,
        changeJustification: changeJustification,
        cchangeDescription: cchangeDescription,
        cchangeJustification: cchangeJustification,
        changeType: changeType,
        customerId: customerId,
        isInternal: isInternal,
        keywords: keywords,
        productFamilyDescription: productFamilyDescription,
        productFamilyName: productFamilyName,
        productSubFamilyNameOne: productSubFamilyNameOne,
        productSubFamilyNameTwo: productSubFamilyNameTwo,
        countryList: countryExclusionList.toString(),
        languageList: language.toString(),
        uploadChildDocumentFileId: uploadChildDocumentFileId,
        obsoleteDate: moment(new Date().toISOString()).format('DD-MMM-YYYY'),
      }
      // console.log('data ', data)
      // let checkChild = documentsList.find((o) => o.uploadChildDocumentFileId === uploadChildDocumentFileId);
      // if (!checkChild) {
      // }
      documentsList.push(data);
      let chkArr = [];
      var docsList = [...documentsList].reverse();
      docsList.map((value, i) => {
        // console.log('e val ', value);
        if (!chkArr.includes(value.uploadChildDocumentFileId)) {
          chkArr.push(value.uploadChildDocumentFileId);
          childDoc.push({
            changeDescription: value.cchangeDescription,
            changeJustification: value.cchangeJustification,
            languageList: value.languageList,
            // changeType: value.changeType,
            countryList: value.countryList,
            keywords: value.keywords,
            associatedReferenceNumber: value.associatedReferenceNumber,
            billMaterialInformation: value.associatedReferenceNumber,
            isInternal: value.isInternal,
            productFamilyName: value.productFamilyName,
            productFamilyDescription: value.productFamilyDescription,
            productSubFamilyNameOne: value.productSubFamilyNameOne,
            productSubFamilyNameTwo: value.productSubFamilyNameTwo,
            uploadDocumentFileId: value.uploadChildDocumentFileId,
            obsoleteDate: moment(new Date().toISOString()).format('DD-MMM-YYYY'),
          });
        }
      });
      var myChildDoc = [...childDoc].reverse();
      let docData = {
        productSubFamilyNameOne: this.props.origAllState.start.productSubFamilyNameOne,
        productSubFamilyNameTwo: this.props.origAllState.start.productSubFamilyNameTwo,
        productFamilyDescription: this.props.origAllState.start.productFamilyDescription,
        changeDescription: this.props.origAllState.start.changeDescription,
        changeJustification: this.props.origAllState.start.changeJustification,
        isInternal: this.props.origAllState.start.isInternal,
        changeType: this.props.origAllState.start.changeType,
        customerId: this.props.origAllState.start.customerId,
        approverId: this.props.origAllState.start.approverId,
        uploadParentDocumentFileId: this.props.parentId,
        effectiveDate: moment(effectiveDate).format('DD-MMM-YYYY'),
        username: this.state.username,
        password: this.state.password,
        comments: this.state.comments,
        productFamilyName: productFamilyName,
        list: myChildDoc
      }
      // console.log(docData, "DOC")

      // this.props.addDocumentListData(docData);
      API.addDocumentListData(docData).then(resp => {
        console.log("doc add response", resp);
        if (resp.status === 200) {
          this.setState({ message: `Document ${resp.data.parentChangeId} Added Succesfully..!!`});
          setTimeout(() => {
            this.setState({ message: null });
            this.props.history.push('list');
          }, 3000);
        } else {
          this.setState({ error: resp.data.message });
          setTimeout(() => this.setState({ error: null }), 3000);
        }
      });
    } else {

    }
  }

  editHandle = e => {
    e.preventDefault();
    if (isEmpty(this.validation())) {
      // this.setState({ errors: {} });
      // console.log(this.state)
      let { effectiveDate, approvedDate, changeDescription, changeJustification, cchangeDescription, cchangeJustification, keywords, language, changeImage, obsoleteDate, countryExclusionList, approverId, changeType, startsImage, associatedReferenceNumber, isInternal, productFamilyDescription, productFamilyName, productSubFamilyNameOne, productSubFamilyNameTwo, customerId } = this.state.allData;
      const data = {
        approverId: approverId,
        associatedReferenceNumber: '333',
        changeDescription: changeDescription,
        changeJustification: changeJustification,
        changeType: changeType,
        childDocument: changeImage === true ? null : changeImage,
        countryList: countryExclusionList.toString(),
        comments: this.state.comments,
        customerId: customerId,
        id: this.props.id,
        isInternal: isInternal,
        keywords: keywords,
        languageList: language,
        mainDocument: startsImage === true ? null : startsImage,
        // obsoleteDate: moment(obsoleteDate).format('DD-MMM-YYYY'),
        obsoleteDate: moment(new Date().toISOString()).format('DD-MMM-YYYY'),
        password: this.state.password,
        // effectiveDate: moment(effectiveDate).format('DD-MMM-YYYY'),
        productFamilyDescription: productFamilyDescription,
        productFamilyName: productFamilyName,
        productSubFamilyNameOne: productSubFamilyNameOne,
        productSubFamilyNameTwo: productSubFamilyNameTwo,
        username: this.state.username,
      }
      // PREVIEW SUBMIT API GOES BELOW
      // console.log('preview edit', data);
      this.props.editDocument(data);
    } else {

    }
  }

  addNewDocument = e => {

    let { effectiveDate, approvedDate, changeDescription, changeJustification, cchangeDescription, cchangeJustification, keywords, language, changeImage, obsoleteDate, countryExclusionList, approverId, changeType, startsImage, associatedReferenceNumber, isInternal, productFamilyDescription, productFamilyName, productSubFamilyNameOne, productSubFamilyNameTwo, customerId, uploadChildDocumentFileId, docLogo, docLogoName, uploadFiles } = this.state.allData;
    // console.log(effectiveDate)
    const data = {
      approverId: approverId,
      associatedReferenceNumber: associatedReferenceNumber,
      changeDescription: changeDescription,
      changeJustification: changeJustification,
      cchangeDescription: cchangeDescription,
      cchangeJustification: cchangeJustification,
      changeType: changeType,
      childDocument: changeImage === true ? null : changeImage,
      countryList: countryExclusionList.toString(),
      comments: this.state.comments,
      customerId: customerId,
      id: this.props.id,
      isInternal: isInternal,
      keywords: keywords,
      languageList: language.toString(),
      mainDocument: startsImage === true ? null : startsImage,
      docLogo: docLogo === true ? null : docLogo,
      docLogoName: docLogoName,
      // obsoleteDate: moment(obsoleteDate).format('DD-MMM-YYYY'),
      obsoleteDate: moment(new Date().toISOString()).format('DD-MMM-YYYY'),
      password: this.state.password,
      effectiveDate: effectiveDate,
      keywords: keywords,
      productFamilyDescription: productFamilyDescription,
      productFamilyName: productFamilyName,
      productSubFamilyNameOne: productSubFamilyNameOne,
      productSubFamilyNameTwo: productSubFamilyNameTwo,
      username: this.state.username,
      uploadChildDocumentFileId: uploadChildDocumentFileId,
      uploadFiles: uploadFiles
    }

    this.props.addDoc(data);

  }

  back = e => {
    this.props.data({ ...this.state, errors: {} });
    this.props.back();
  }

  componentDidUpdate(prevProps) {
    // console.log('this props', this.props)
    if (prevProps.documents !== this.props.documents) {
      if (this.props.documents.message && this.props.documents.message != "Empty") {
        this.setState({ message: this.props.documents.message });
        setTimeout(() => {
          this.setState({ message: null, error: null });
          this.props.history.push('/document/list');
        }, 4000);
      } else if (this.props.documents.error && this.props.documents.error != "Empty") {
        this.setState({ error: this.props.documents.error });
        setTimeout(() => {
          // let newObj = {};
          // newObj.message = '';
          // newObj.error = '';
          // this.setState(newObj);
          // if (this.props.documents.message ? true : false) {
          //   this.props.removeMessage();
          // }
          this.setState({ message: null, error: null });
          // this.props.history.push('/document/list');
        }, 4000);
      } else {
        setTimeout(() => {
          // let newObj = {};
          // newObj.message = '';
          // newObj.error = '';
          // this.setState(newObj);
          // if (this.props.documents.message ? true : false) {
          //   this.props.removeMessage();
          // }
          this.setState({ message: null, error: null });
          // this.props.history.push('/document/list');
        }, 4000);
        // this.setState({ message: this.props.documents.message });
      }
    }
  }

  render() {
    let { errors, username, password, comments, allData, message, error } = this.state;
    let { edit } = this.props;
    // console.log(allData)
    return (
      <React.Fragment>
        <Message error={error} success={message} />
        <div>
          <div className="page-header">
            <h3 className="page-title">
              <span className="page-title-icon bg-gradient-primary text-white mr-2">
                <i className="mdi mdi-eye" />
              </span>
              Preview
            </h3>
          </div>
          <div className="row">
            <div className="col-12 grid-margin">
              <div className="card">
                <div className="card-body">
                  <div className="row">

                    {/* <div className="col-md-6 col-lg-5"> */}
                    {/* </div> */}

                    <div className="col-md-6 col-lg-5">
                      {/* <div className="row"> */}
                      {!this.props.docEdit ?
                        <div className="row more-document-add-div">
                          <div className="col-md-12">
                            <div className="form-group text-center">
                              {/* <h4 className="mb-4">Do you want to add more documents?</h4> */}
                              {/* <div className="btn btn-gradient-danger" style={{ marginRight: '10px' }}>No</div> */}
                              <div className="btn btn-gradient-primary" onClick={this.addNewDocument}>Add More Document</div>
                            </div>
                          </div>
                        </div>
                        : null}
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Username</label>
                          <input type="text" name='username' value={username} onChange={this.changeInput} className="form-control" />
                          <Error error={errors['username']} />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Password</label>
                          <input type="password" name='password' value={password} onChange={this.changeInput} className="form-control" />
                          <Error error={errors['password']} />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Comments</label>
                          <textarea rows={5} name='comments' value={comments} onChange={this.changeInput} className="form-control" />
                          <Error error={errors['comments']} />
                        </div>
                      </div>
                      <div className="col-md-12 text-center">
                        <span onClick={this.back} className="btn btn-gradient-secondary" style={{ marginRight: '10px' }}>Back</span>
                        <span onClick={edit ? this.editHandle : this.handleSubmit} className="btn btn-gradient-primary">{edit ? 'Update' : 'Submit'}</span>
                      </div>
                      {/* </div> */}
                    </div>
                    <div className="col-md-6 col-lg-7">
                      <div className="row">
                        <div className="col-md-6">
                          <label><b>Product Family Name</b></label>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <p>{allData ? allData.productFamilyName : '-'}</p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label><b>Product Sub Family Name One</b></label>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <p>{allData ? Capitalize(allData.productSubFamilyNameOne) : '-'}</p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label><b>Product Sub Family Name Two</b></label>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <p>{allData ? Capitalize(allData.productSubFamilyNameTwo) : '-'}</p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label><b>Product Family Description</b></label>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <p>{allData ? Capitalize(allData.productFamilyDescription) : '-'}</p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label><b>Description of changes:</b></label>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <p>{allData ? Capitalize(allData.changeDescription) : '-'}</p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label><b>Justification of changes:</b></label>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <p>{allData ? Capitalize(allData.changeJustification) : '-'}</p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label><b>Associated Reference Number</b></label>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <p>{allData ? allData.associatedReferenceNumber : ''}</p>
                          </div>
                        </div>
                      </div>
                      {/* <div className="row">
                        <div className="col-md-6">
                          <label><b>Child Document:</b></label>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <p className="text-info">{allData ? allData.changeImageName : '-'}</p>
                          </div>
                        </div>
                      </div> */}
                      <div className="row">
                        <div className="col-md-6">
                          <label><b>Main Document</b></label>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            {/* <p><a href="#" download className="text-info">document.pdf</a></p> */}
                            {/* <p className="text-info"></p> */}
                            {allData && allData.uploadFiles && allData.uploadFiles.length > 0 ? allData.uploadFiles.map((v, i) => {
                              return <><a key={i} href={v.fileUrl} target="_blank">{v.fileName}</a><br /></>
                            }) : '-'}
                          </div>
                        </div>
                      </div>
                      {/* <div className="row">
                        <div className="col-md-6">
                          <label><b>Redlined Document:</b></label>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <p><a href="#" download className="text-info">document.pdf</a></p>
                            <p className="text-info">{allData ? allData.changeImageName : '-'}</p>
                          </div>
                        </div>
                      </div> */}
                      <div className="row">
                        <div className="col-md-6">
                          <label><b>Product Line Keywords</b></label>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <p>{allData ? Capitalize(allData.keywords) : '-'}</p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label><b>Change Type</b></label>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <p>{allData ? Capitalize(allData.changeType) : '-'}</p>
                          </div>
                        </div>
                      </div>
                      {/* <div className="row">
                        <div className="col-md-6">
                          <label><b>Languages</b></label>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <p>{allData ? Capitalize(allData.language) : '-'}</p>
                          </div>
                        </div>
                      </div> */}

                      {/* <div className="row">
                        <div className="col-md-6">
                          <label><b>Effective Date</b></label>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <p>{allData ? moment(allData.effectiveDate).format('DD-MM-YYYY') : ''}</p>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <label><b>Obsolete Date</b></label>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <p>{allData ? moment(allData.obsoleteDate).format('DD-MM-YYYY') : ''}</p>
                          </div>
                        </div>
                      </div> */}

                      {/* <div className="row">
                        <div className="col-md-6">
                          <label><b>Change Type</b></label>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <p>{allData ? Capitalize(allData.changeType) : ''}</p>
                          </div>
                        </div>
                      </div> */}

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </React.Fragment >

    );
  }
}
const mapStateToProps = state => ({
  documents: state.documents
})

const actionCreators = {
  addDocumentFiles: documentActions.addDocumentFiles,
  addDocumentListData: documentActions.addDocumentListData,
  addDocument: documentActions.addDocument,
  editDocument: documentActions.editDocument,
  removeMessage: documentActions.removeMessage
};

const customerDatapage = compose(withRouter, connect(mapStateToProps, actionCreators))(Preview);
export { customerDatapage as Preview };