import { userConstants } from '../_constants';

export function documents(state = {}, action) {
    switch (action.type) {
        case userConstants.CHECK_ADD_DOCUMENT_SUCCESS:
            return {
                ...state, message: action.message, document: action.document
            };
        case userConstants.CHECK_ADD_DOCUMENT_FAILURE:
            return {
                ...state, error: action.error
            };
        case userConstants.CHECK_DOCUMENT_DETAILS_SUCCESS:
            return {
                ...state, message: action.message, document: action.document
            };
        case userConstants.CHECK_DOCUMENT_DETAILS_FAILURE:
            return {
                ...state, error: action.error
            };
        case userConstants.CHECK_LIST_PARENT_DOCUMENTS_SUCCESS:
            return {
                ...state, message: action.message, parentdocuments: action.documents
            };
        case userConstants.CHECK_LIST_PARENT_DOCUMENTS_FAILURE:
            return {
                ...state, error: action.error
            };

        case userConstants.CHECK_LIST_DOCUMENTS_SUCCESS:
            return {
                ...state, message: action.message, documents: action.documents
            };
        case userConstants.CHECK_LIST_DOCUMENTS_FAILURE:
            return {
                ...state, error: action.error
            };

        case userConstants.DOCUMENT_REMOVE_ADD_MESSAGE:
            return {
                ...state, message: action.message, error: action.message
            };
        case userConstants.CHECK_UPLOAD_DOCUMENTS_SUCCESS:
            return {
                ...state, message: action.message, uploadResponse: action.uploadResponse
            };
        case userConstants.CHECK_UPLOAD_DOCUMENTS_FAILURE:
            return {
                ...state, error: action.error
            };
        case userConstants.CHECK_ADD_DOCUMENTS_SUCCESS:
            return {
                ...state, message: action.message, addResponse: action.addResponse
            };
        case userConstants.CHECK_ADD_DOCUMENTS_FAILURE:
            return {
                ...state, error: action.error
            };

        case userConstants.DOCUMENT_PARENT_BY_ID_SUCCESS:
            return {
                ...state, message: action.message, documentById: action.document
            };
        case userConstants.DOCUMENT_PARENT_BY_ID_ERROR:
            return {
                ...state, error: action.error
            };
        default:
            return state
    }
}