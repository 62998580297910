import React from "react";
import { isEmpty, Error } from "../_components/Validation";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { countries } from "../_constants/countries";
import DatePicker from "react-date-picker";
import Select from "react-dropdown-select";
import { bytesToSize } from "../_constants/functions";
import { documentActions } from "../_actions";

class Starts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyName: "",
      newComponent: "",
      information: "",
      keywords: "",
      startsImage: [],
      startsImageName: null,
      errors: {},
      options: countries,
      countryExclusionList: [],
      associatedReferenceNumber: "",
      productFamilyDescription: "",
      productFamilyName: "",
      productSubFamilyNameOne: "",
      productSubFamilyNameTwo: "",
      files: 0,
      modal: false,
      viewId: null,
      customers: [],
      isInternal: "",
      changeType: null,
      customerId: "",
      approverId: "",
      effectiveDate: "",
      imgView: false,
      uploadModal: false,
      uploadParentDocumentFileId: "",
      changeDescription: "",
      changeJustification: "",
      uploadSuccess: this.props.parent,
      uploadFiles: [],
      // uploadSuccess: true,
      viewImages: true,
      docLogo: "",
      docLogoName: ""
    };
  }

  componentDidMount() {
    this.setState({ ...this.props.states });
    let customerData =
      typeof this.props.customerList.items === "object" && this.props.customerList.items
        ? this.props.customerList.items
        : [];
    let customerList = [];
    let selectedApprover = [];
    if (customerData && customerData.length > 0) {
      customerData.map((v) => {
        customerList.push({ value: v.id, name: v.customerName, label: v.customerName });
      });
    }

//    if (this.props.docLogo) {
//      this.setState({ docLogoName: this.props.docLogoName, docLogo: this.props.docLogo })
//    }

    // console.log(this.props)
//    if (this.props.effectiveDefault) {
//      this.setState({ effectiveDate: this.props.states.effectiveDate });
//    }

    let users = typeof this.props.users.items === "object" ? this.props.users.items : [];
    let activeAdmin = [];
    if (users && users.length > 0) {
      users.map((v) => {
        if (v.role === "ADMIN" && v.userActive && !v.isDeleted && v.email != this.props.auth.user) {
          activeAdmin.push({ value: v.id, name: v.firstName + " " + v.lastName, label: v.email });
        }
      });
    }

    if (this.props.states && this.props.states.approverId && activeAdmin.length > 0) {
      let approverId = this.props.states.approverId;
      let value = activeAdmin.find((o) => o.value === Number(approverId));
      if (value) selectedApprover.push(value);
    }

    let customers = [];
    if (this.props.states && this.props.states.customerId) {
      let customer = this.props.states.customerId;
      let value = customerList.find((o) => o.value === customer);
      if (value) customers.push(value);
    }

    this.setState({
      approvers: activeAdmin,
      approver: selectedApprover,
      customerList: customerList,
      customers: customers
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.uploadParentDocumentFileId &&
      prevProps.uploadParentDocumentFileId !== this.props.uploadParentDocumentFileId
    ) {
      console.log('did props ', this.props)
      this.props.parentId(this.props.uploadParentDocumentFileId);
      this.setState({
        uploadParentDocumentFileId: this.props.uploadParentDocumentFileId,
        uploadSuccess: true,
        uploadFiles: this.props.uploaded.fileDetails,
      });
      setTimeout(() => {
        this.setState({ uploadModal: false });
      }, 1000);
    }
    // console.log(this.props.uploadParentDocumentFileId)
  }

  changeInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  imageUpload = (e) => {
    this.refs.fileUploader.click();
  };

  imageChange = (event) => {
    event.persist();
    if (event.target.files.length > 0) {
      this.setState({ startsImage: [...this.state.startsImage, event.target.files[0]] });
    } else {
      // this.setState({ startsImage: null, startsImageName: null });
    }
  };

  changeCheckbox = (event) => {
    this.setState({ [event.target.name]: event.target.id });
  };

  internalCheck = (event) => {
    this.setState({ [event.target.name]: event.target.id === "yes" ? true : false });
  };

  validation = (e) => {
    const {
      startsImage,
      changeType,
      approverId,
      isInternal,
      productFamilyName,
      customerId,
      effectiveDate,
      changeJustification,
      changeDescription,
      docLogo
    } = this.state;

    let errors = {};
    if (!changeType) errors["type"] = "Select Type";
    if (!productFamilyName) errors["name"] = "Enter Product Family Name";
    if (!approverId) errors["approver"] = "Select Approver";
    if (typeof isInternal !== "boolean") errors["internal"] = "Select Internal";
    if (!customerId) errors["customer"] = "Select Customer";
    if (!effectiveDate) errors["effective"] = "Select Effective Date";
    if (!changeDescription) errors["desc"] = "Enter Product Family Description";
    if (!changeJustification) errors["just"] = "Enter Justification of Description";
    if (!docLogo) errors["docimage"] = "Select Document Logo";
    this.setState({ errors: errors });
    return errors;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (isEmpty(this.validation())) {
      this.setState({ errors: {} });
      this.props.data({ ...this.state, errors: {} });
      this.props.next(true);
    }

  };

  onMultiSelect = (selectedList, name) => {
    let list = selectedList.map((country) => {
      return country.value;
    });

    this.setState({ [name]: list });
  };

  onSelect = (value, name) => {
    this.setState({ [name]: value[0].value });
  };

  onChange = (date, type) => {
    this.setState({ [type]: date });
  };

  removeImages = (e) => {
    this.setState({ startsImage: [] });
  };

  viewImages = (e) => {
    this.setState({ viewImages: this.state.viewImages ? false : true });
  };

  docLogoUpload = (e) => {
    this.refs.docUploader.click();
  };

  docLogoChange = (event) => {
    if (event.target.files.length > 0) {
      let files = [];
      files.push(event.target.files[0]);
      //this.props.addDocumentFiles(files, false);
      this.setState({
        docLogo: event.target.files[0],
        docLogoName: event.target.files[0].name
      });
    } else {
      this.setState({
        docLogo: null,
        docLogoName: null
      });
    }
  };

  uploadDocument = (e) => {
    e.persist();
    // console.log("doc", this.state.startsImage);
    // console.log("logo", this.state.docLogo);
    this.props.addDocumentFiles(this.state.startsImage, true, this.state.docLogo);
  };

  deleteFile = (id) => {
    // console.log(this.state.startsImage);
    let pimg = this.state.startsImage;
    pimg.splice(--id, 1);
    this.setState({ startsImage: pimg });

    console.log(this.state.startsImage);
  };

  // editDocument = data => {
  //   this.props.editDoc(data);
  // }

  render() {
    // console.log(this.state.startsImage)
    let {
      companyName,
      newComponent,
      startsImage,
      startsImageName,
      information,
      changeType,
      errors,
      associatedReferenceNumber,
      isInternal,
      productFamilyName,
      productFamilyDescription,
      productSubFamilyNameOne,
      productSubFamilyNameTwo,
      customers,
      customerList,
      keywords,
      effectiveDate,
      changeDescription,
      changeJustification,
      docLogo,
      docLogoName
    } = this.state;

    return (
      <React.Fragment>
        <div className="page-header">
          <h3 className="page-title">
            <span className="page-title-icon bg-gradient-primary text-white mr-2">
              <i className="mdi mdi-plus-circle" />
            </span>
            Start
          </h3>
        </div>

        <div className="row">
          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <form>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>
                          Approval/Supporting Document(s) &nbsp;&nbsp;
                          {this.state.startsImage.length < 4 && !this.state.uploadSuccess ? (
                            <span
                              onClick={this.state.startsImage.length <= 4 ? this.imageUpload : null}
                              className="btn btn-sm btn-primary">
                              <i className="mdi mdi-attachment" />
                            </span>
                          ) : null}
                          &nbsp;&nbsp;
                          {startsImage.length > 0 && !this.state.uploadSuccess ? (
                            <React.Fragment>
                              <span onClick={this.removeImages} className="btn btn-sm btn-danger">
                                {" "}
                                <i className="mdi mdi-close" />
                              </span>
                              &nbsp;&nbsp;
                              <span onClick={this.viewImages} className="btn btn-sm btn-info">
                                {" "}
                                <i className="mdi mdi-eye" />
                              </span>
                            </React.Fragment>
                          ) : null}
                          {/* {startsImage.length > 0 && this.state.uploadSuccess ?
                            <span onClick={this.viewImages} className='btn btn-sm btn-info'> <i className="mdi mdi-eye" /></span>
                            : null} */}
                          {this.state.uploadSuccess ? (
                            <i
                              className="mdi mdi-checkbox-marked-circle"
                              style={{ color: "green" }}
                            />
                          ) : null}
                        </label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            value={
                              startsImage.length > 0
                                ? startsImage.length + " Files"
                                : "No Image Selected"
                            }
                            aria-label="Recipient's username"
                            disabled
                            aria-describedby="basic-addon2"
                            id="BtnBrowseHidden"
                          />
                          <input
                            type="file"
                            ref="fileUploader"
                            onChange={this.imageChange}
                            style={{ display: "none" }}
                          />
                          <div className="input-group-append">
                            {/* <button className={this.state.startsImage.length > 0 && !this.state.uploadSuccess ? "btn btn-sm btn-gradient-primary" : 'btn btn-sm btn-gradient-secondary'} onClick={this.state.startsImage.length > 0 && !this.state.uploadSuccess ? () => this.setState({ uploadModal: true }) : null} type="button">Upload File</button> */}
                          </div>
                        </div>
                        {this.state.viewImages ? (
                          <div className="collapse show" id="collapseExample">
                            <div className="card card-body">
                              <ul className="list-group">
                                {this.state.startsImage.length > 0
                                  ? this.state.startsImage.map((v, i) => {
                                    return (
                                      <li key={i} className="list-group-item">
                                        {++i}. {v.name} - {bytesToSize(v.size)}{" "}
                                        {this.state.uploadSuccess ? (
                                          <i
                                            className="mdi mdi-checkbox-marked-circle"
                                            style={{ color: "green" }}
                                          />
                                        ) : null}{" "}
                                        <i
                                          className="mdi mdi-delete"
                                          onClick={() => this.deleteFile(i)}
                                          style={{ cursor: "pointer" }}
                                        />
                                      </li>
                                    );
                                  })
                                  : null}
                              </ul>
                            </div>
                          </div>
                        ) : null}
                        <Error error={errors["image"]} />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-group">
                        <label>
                          Document Logo{" "}
                          {docLogo ? (
                            <i
                              className="mdi mdi-checkbox-marked-circle"
                              style={{ color: "green" }}
                            />
                          ) : null}{" "}
                        </label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            value={docLogo ? docLogoName : "No Image Selected"}
                            aria-label="Recipient's username"
                            disabled
                            aria-describedby="basic-addon2"
                            id="BtnBrowseHidden"
                          />
                          <input
                            type="file"
                            ref="docUploader"
                            onChange={this.docLogoChange}
                            style={{ display: "none" }}
                          />
                          <div className="input-group-append">
                            <button
                              className={
                                this.state.uploadSuccess || this.props.parent
                                  ? "btn btn-sm btn-gradient-secondary"
                                  : "btn btn-sm btn-gradient-primary"
                              }
                              onClick={
                                this.state.uploadSuccess || this.props.parent
                                  ? null
                                  : this.docLogoUpload
                              }
                              type="button">
                              Upload File
                            </button>
                          </div>
                        </div>
                        <Error error={errors["docimage"]} />
                      </div>
                    </div>
                    <div className="col-12">
                      <button
                        className={
                          this.state.startsImage.length > 0 &&
                            this.state.docLogo &&
                            !this.state.uploadSuccess
                            ? "btn btn-sm btn-primary"
                            : "btn btn-sm btn-secondary"
                        }
                        onClick={
                          this.state.startsImage.length > 0 && !this.state.uploadSuccess
                            ? () => this.setState({ uploadModal: true })
                            : null
                        }
                        type="button"
                        style={{ float: "right" }}>
                        Upload All File
                      </button>
                    </div>

                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Product Name</label>
                        <input
                          type="text"
                          name="productFamilyName"
                          value={productFamilyName}
                          onChange={this.changeInput}
                          className="form-control"
                          placeholder={"Enter Product Name"}
                          disabled={this.props.parent ? true : false}
                        />
                        <Error error={errors["name"]} />
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row d-flex align-items-center">
                        <div className="col-md-3 col-lg-3">Accessibility</div>
                        <div className="col-md-9 col-lg-9">
                          <div className="form-check d-inline-block mr-4">
                            <label className="form-check-label">
                              <input
                                type="radio"
                                className="form-check-input"
                                name="isInternal"
                                id={"no"}
                                checked={isInternal === false ? true : false}
                                onChange={this.props.parent ? null : this.internalCheck}
                              />{" "}
                              Online <i className="input-helper" />
                              <i className="input-helper" />
                            </label>
                          </div>
                          <div className="form-check d-inline-block mr-4">
                            <label className="form-check-label">
                              <input
                                type="radio"
                                className="form-check-input"
                                name="isInternal"
                                id={"yes"}
                                checked={isInternal === true ? true : false}
                                onChange={this.props.parent ? null : this.internalCheck}
                              />{" "}
                              Offline <i className="input-helper" />
                              <i className="input-helper" />
                            </label>
                          </div>
                        </div>
                        <Error error={errors["internal"]} />
                      </div>
                    </div>
                    {/* <div className="col-12">
                      <div className="row d-flex align-items-center">
                        <div className="col-md-3 col-lg-3">
                          Type of information
                        </div>
                        <div className="col-md-9 col-lg-9">
                          <div className="form-check d-inline-block mr-4">
                            <label className="form-check-label">
                              <input type="radio" className="form-check-input" name="information" id="new" checked={information === 'new' ? true : false} onChange={this.changeCheckbox} /> New <i className="input-helper" /><i className="input-helper" /></label>
                          </div>
                          <div className="form-check d-inline-block mr-4">
                            <label className="form-check-label">
                              <input type="radio" className="form-check-input" name="information" id="change" checked={information === 'change' ? true : false} onChange={this.changeCheckbox} />   Change <i className="input-helper" /><i className="input-helper" /></label>
                          </div>
                          <div className="form-check d-inline-block">
                            <label className="form-check-label">
                              <input type="radio" className="form-check-input" name="information" id="remove" checked={information === 'remove' ? true : false} onChange={this.changeCheckbox} />   Remove <i className="input-helper" /><i className="input-helper" /></label>
                          </div>
                        </div>
                        <Error error={errors['info']} />
                      </div>
                    </div> */}
                    <div className="col-12">
                      <div className="row d-flex align-items-center">
                        <div className="col-md-3 col-lg-3">Change Type</div>
                        <div className="col-md-9 col-lg-9">
                          <div className="form-check d-inline-block mr-4">
                            <label className="form-check-label">
                              <input
                                type="radio"
                                className="form-check-input"
                                name="changeType"
                                id="General Document"
                                checked={changeType === "General Document" ? true : false}
                                onChange={this.props.parent ? null : this.changeCheckbox}
                              />{" "}
                              General Document <i className="input-helper" />
                              <i className="input-helper" />
                            </label>
                          </div>
                          <div className="form-check d-inline-block mr-4">
                            <label className="form-check-label">
                              <input
                                type="radio"
                                className="form-check-input"
                                name="changeType"
                                id="Record"
                                checked={changeType === "Record" ? true : false}
                                onChange={this.props.parent ? null : this.changeCheckbox}
                              />{" "}
                              Record <i className="input-helper" />
                              <i className="input-helper" />
                            </label>
                          </div>
                          <div className="form-check d-inline-block mr-4">
                            <label className="form-check-label">
                              <input
                                type="radio"
                                className="form-check-input"
                                name="changeType"
                                id="Model/Product Information"
                                checked={changeType === "Model/Product Information" ? true : false}
                                onChange={this.props.parent ? null : this.changeCheckbox}
                              />{" "}
                              Model/Product Information <i className="input-helper" />
                              <i className="input-helper" />
                            </label>
                          </div>
                          <div className="form-check d-inline-block">
                            <label className="form-check-label">
                              <input
                                type="radio"
                                className="form-check-input"
                                name="changeType"
                                id="Product Drawing"
                                checked={changeType === "Product Drawing" ? true : false}
                                onChange={this.props.parent ? null : this.changeCheckbox}
                              />{" "}
                              Product Drawing <i className="input-helper" />
                              <i className="input-helper" />
                            </label>
                          </div>
                        </div>
                        <Error error={errors["type"]} />
                      </div>
                    </div>

                    <div className="col-md-6" style={{ marginTop: "12px" }}>
                      <div className="form-group">
                        <label>Customer</label>
                        <Select
                          disabled={this.props.parent ? true : false}
                          className="form-control"
                          options={customerList}
                          values={customers}
                          onChange={(value) => this.onSelect(value, "customerId")}
                        />
                        <Error error={errors["customer"]} />
                      </div>
                    </div>

                    <div className="col-md-6" style={{ marginTop: "12px" }}>
                      <div className="form-group">
                        <label>Approver</label>
                        <Select
                          disabled={this.props.parent ? true : false}
                          className="form-control"
                          options={this.state.approvers}
                          values={this.state.approver}
                          onChange={(value) => this.onSelect(value, "approverId")}
                        />
                        <Error error={errors["approver"]} />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Effective Date</label>
                        <DatePicker
                          className="form-control datepickerWidth"
                          name="effectiveDate"
                          disabled={this.props.parent ? true : false}
                          onChange={(e) => this.onChange(e, "effectiveDate")}
                          value={effectiveDate}
                          format="dd/MM/y"
                        />
                        <Error error={errors["effective"]} />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Description of Change</label>
                        <textarea
                          rows={4}
                          name="changeDescription"
                          value={changeDescription}
                          onChange={this.props.parent ? null : this.changeInput}
                          disabled={this.props.parent ? true : false}
                          className="form-control"
                        />
                        <Error error={errors["desc"]} />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Justification of Change</label>
                        <textarea
                          rows={4}
                          name="changeJustification"
                          value={changeJustification}
                          onChange={this.props.parent ? null : this.changeInput}
                          disabled={this.props.parent ? true : false}
                          className="form-control"
                        />
                        <Error error={errors["just"]} />
                      </div>
                    </div>
                  </div>
                  <div className="form-group text-center">
                    <div className="row d-flex align-items-center mt-5">
                      <div className="col-md-12 text-center">
                        <Link to="/document/list">
                          <span className="btn btn-gradient-danger" style={{ marginRight: "10px" }}>
                            Back
                          </span>
                        </Link>
                        {this.state.uploadSuccess ? (
                          <span onClick={this.handleSubmit} className="btn btn-gradient-primary">
                            Next
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {this.state.uploadModal ? (
          <div className="modal">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Are you Sure to Upload Documents
                  </h5>
                  <button
                    type="button"
                    onClick={() => this.setState({ uploadModal: false })}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <ul className="list-group">
                    {this.state.uploadSuccess ? (
                      <p className="text-success">Uploaded Successfully</p>
                    ) : null}
                    {this.state.startsImage.length > 0
                      ? this.state.startsImage.map((v, i) => {
                        return (
                          <li key={i} className="list-group-item">
                            {++i}. {v.name} - {bytesToSize(v.size)}
                          </li>
                        );
                      })
                      : null}
                  </ul>
                  <br />
                  Parent Logo
                  <ul className="list-group">
                    {this.state.docLogo ? (
                      <li className="list-group-item">
                        {this.state.docLogoName} - {bytesToSize(this.state.docLogo.size)}
                      </li>
                    ) : (
                        "No Parent Logo"
                      )}
                  </ul>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => this.setState({ uploadModal: false })}
                    data-dismiss="modal">
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.uploadDocument}
                    data-dismiss="modal">
                    Upload
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedCustomer: state.customers.selectedCustomer || {},
  customerList: state.customers,
  users: state.users,
  auth: state.authentication,
  uploadParentDocumentFileId: state.documents.uploadResponse && state.documents.uploadResponse.uploadParentDocumentFileId,
  uploaded: state.documents.uploadResponse && state.documents.uploadResponse
});

const actionCreators = {
  addDocumentFiles: documentActions.addDocumentFiles
};

export default connect(mapStateToProps, actionCreators)(Starts);
