import React, { Component } from 'react';
import { userActions, customerActions } from '../_actions';
import { connect } from 'react-redux';
import logo from './shedpaper.jpg';
import { Link } from 'react-router-dom'

class Topnavbar extends Component {

  state = { menu: false, profile: false, users: [], message: null, myName: null }

  constructor(props) {
    super(props);

    // this.setWrapperRef = this.setWrapperRef.bind(this);
    // this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleMenu = this.handleMenu.bind(this);
    //this.getMyList = this.getMyList.bind(this);
    this.handleApprove = this.handleApprove.bind(this);
  }

  componentDidMount() {
    let name = this.props && this.props.users.length > 0 && this.props.users.items && this.props.users.items.find((o) => { return o.email === this.props.loggedInUser })
    // console.log(name)
    this.setState({ myName: name ? name.firstName : '' })
    // var film = this.props.users && this.props.users.items && this.props.users.items.slice(0, 5).map((item) => {
    //   return item.email;
    // }) || [];
    // console.log(film)
    // this.setState({ users: film });
  }

  // getMyList(approvalInitiatorList) {
  //   let myId;
  //   let myApproversList = [];
  //   if(this.props.users && this.props.users.items) {
  //     this.props.users.items.forEach(element => {
  //       if(element.email === this.props.loggedInUser) {
  //         myId = element.id;
  //         return false;
  //       }
  //     });
  //   }
  //   // myId = 2;
  //   // console.log("myId", myId);
  //   approvalInitiatorList.forEach(element => {
  //     if(element.customerApprovalId === myId ) {
  //       myApproversList.push(element);
  //     }
  //   });
  //   console.log("myApproversList", myApproversList);
  //   return myApproversList;
  // }

  componentDidUpdate(prevProps) {
    if (prevProps.approvalInitiatorList !== this.props.approvalInitiatorList) {
      let approverUsers = this.props.approvalInitiatorList.data.approvalResponse;
      // console.log("inside update", approverUsers);
      //let appUsers = this.getMyList(approverUsers);
      // console.log(approverUsers);
      // console.log(message)
      this.setState({ users: approverUsers });
    }

    // if (prevProps.customers !== this.props.customers) {
    //   let message = this.props.customers;
    //   console.log(message)
    //   console.log(this.props)
    //   this.setState({ message: message })
    // }
  }

  handleMenu(e) {
    this.props.getApprovalInitiatorList();
    this.setState({ menu: this.state.menu ? false : true, profile: false });
    // this.setState({ users: this.getMyList(approverList) });
    // //console.log(JSON.stringify(approvalInitiatorList));
    // this.setState({ menu: this.state.menu ? false : true })
  }

  handleProfile = (e) => {
    this.setState({ profile: this.state.profile ? false : true, menu: false });
  }

  // setWrapperRef(node) {
  //   this.wrapperRef = node;
  // }

  // handleClickOutside(event) {
  //   if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
  //     this.setState({ menu: false });
  //   }
  // }

  handleLogout = e => {
    this.props.logout();
  }

  handleApprove(action, selUser) {
    let value = action === 'approve' ? 'yes' : 'no';
    let data = {
      "customerApproval": value,
      "customerApproverId": selUser.customerApprovalId,
      "customerId": selUser.customerId,
      "changeDescription": "abc",
      "changeJustification": ""
    }
    this.props.approveCustomer(data);
  }

  render() {
    // console.log(this.state.message)
    let name = '';
    if (this.props.users && typeof (this.props.users.items) === 'object' && this.props.users.items.length > 0) {
      let uname = this.props.users.items.find((o) => { return o.email === this.props.loggedInUser });
      name = uname.firstName + ' ' + uname.lastName;
    } else {
      name = 'My Account';
    }
    // console.log(name)
    return (
      <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
          <a className="navbar-brand brand-logo" href="/"><img src={logo} alt="logo" /></a>
          <a className="navbar-brand brand-logo-mini" href="/"><img src={logo} alt="logo" /></a>
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-stretch">

          {/* <div className="search-field d-none d-md-block">
                      <form className="d-flex align-items-center h-100" action="#">
                        <div className="input-group">
                          <div className="input-group-prepend bg-transparent">
                            <i className="input-group-text border-0 mdi mdi-magnify"></i>
                          </div>
                          <input type="text" className="form-control bg-transparent border-0" placeholder="Search Document"/>
                        </div>
                      </form>
                    </div> */}
          <ul className="navbar-nav navbar-nav-right">

            <li className="nav-item d-none d-lg-block full-screen-link">
              <a className="nav-link">
                {/* Welcome {this.props.user} */}
              </a>
            </li>

            {/* <li className="nav-item dropdown">
              <a className="nav-link count-indicator dropdown-toggle" id="notificationDropdown" onClick={this.handleMenu} data-toggle="dropdown">
                <i className="mdi mdi-bell-outline"></i>
                <span className="count-symbol bg-danger"></span>
              </a>
              <div className={this.state.menu ? "dropdown-menu dropdown-menu-right navbar-dropdown preview-list show" : "dropdown-menu dropdown-menu-right navbar-dropdown preview-list"} aria-labelledby="notificationDropdown" >
                <h6 className="p-3 mb-0" style={{ width: 'max-content' }}>Notifications
                </h6>
                <div className="dropdown-divider"></div>
                {this.state.users.length > 0 && this.state.users.map((v, i) => {
                  return (<React.Fragment key={i}>
                    <a className="dropdown-item preview-item">
                      <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                        <h6 className="preview-subject font-weight-normal mb-1">{v.customerContactEmail}</h6>
                      </div>
                      <div className="preview-thumbnail">
                        <div className='btn btn-sm btn-success' onClick={() => this.handleApprove('approve', v)} style={{ marginLeft: '15px' }}>Approve</div>
                        <div className='btn btn-sm btn-danger' onClick={() => this.handleApprove('reject', v)} style={{ marginLeft: '10px' }}>Reject</div>
                      </div>
                    </a>
                  </React.Fragment>)
                })}
                <div className="dropdown-divider"></div>
              </div>
            </li> */}

            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" id="notificationDropdown" onClick={this.handleProfile} data-toggle="dropdown">
                <i className="mdi mdi-account-box-outline"></i>&nbsp;&nbsp;{name}
                <span className="count-symbol bg-danger"></span>
              </a>
              <div className={this.state.profile ? "dropdown-menu dropdown-menu-right navbar-dropdown preview-list show" : "dropdown-menu dropdown-menu-right navbar-dropdown preview-list"} aria-labelledby="notificationDropdown" style={{ width: 'max-content' }} >
                {/* <div className="dropdown-divider"></div>
                <span className="nav-link" onClick={() => this.setState({ profile: false })}>
                  <Link to='/contactus' style={{ color: 'black', textDecoration: 'none' }}>
                    <i className="mdi mdi-contact-mail"></i> &nbsp;&nbsp; Contact us
                  </Link>
                </span> */}
                <div className="dropdown-divider"></div>
                <span className="nav-link" onClick={() => this.setState({ profile: false })}>
                  <Link to='/password' style={{ color: 'black', textDecoration: 'none' }}>
                    <i className="mdi mdi-account-settings"></i> &nbsp;&nbsp; Change Password
                  </Link>
                </span>
                <div className="dropdown-divider"></div>
                <span className="nav-link" onClick={this.handleLogout}>
                  <i className="mdi mdi-power"></i> &nbsp;&nbsp; Logout
                </span>

              </div>
            </li>
          </ul>
          <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
            <span className="mdi mdi-menu"></span>
          </button>
        </div>
      </nav>
    )
  }
}

const mapStateToProps = state => ({
  users: state.users,
  loggedInUser: state.authentication.user,
  approvalInitiatorList: state.customers.approvalInitiatorList
})

const mapDispatchToProps = {
  logout: userActions.logout,
  getApprovalInitiatorList: customerActions.getApprovalInitiatorList,
  approveCustomer: customerActions.approveCustomer
};

export default connect(mapStateToProps, mapDispatchToProps)(Topnavbar);