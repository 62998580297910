import React from 'react';
import '../assets/css/modal.css';
import '../assets/css/dataTable.css';
import { connect } from 'react-redux';
import { countries } from '../_constants/countries';
import { Error, isEmpty } from '../_components/Validation';

class DocumentView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            countries: null,
            approver: null,
            preview: null
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        let country = this.props.data.countryExclusionList ? this.props.data.countryExclusionList.split(',') : null;
        let selectedCountry = [];

        if (country) {
            country.map((v) => {
                if (countries.find((o) => o.value === v)) {
                    selectedCountry.push(countries.find((o) => o.value === v).label);
                }
            })
        }
        // console.log(this.props.logo.data)
        // this.setState({ preview: `data:${this.props.logo.data};base64, image/png` })
        // this.setState({ preview: 'data:image/jpeg;base64,' + this.hexToBase64(this.props.logo.data) })
        // preview: `data:${resp.data.type};base64,${resp.data.picByte}`
        let countriesList = selectedCountry.length > 0 ? (selectedCountry.toString()).replace(/,/g, ', ') : selectedCountry.toString();

        let approverId = this.props.data.approverId;
        let users = this.props.users.items;
        let approver = users.find((o) => o.id === Number(approverId));
        this.setState({ countries: countriesList, approver: approver });
        // console.log('logo', this.props)

    }

    // hexToBase64(str) {
    //   return btoa(String.fromCharCode.apply(null, str.replace(/\r|\n/g, "").replace(/([\da-fA-F]{2}) ?/g, "0x$1 ").replace(/ +$/, "").split(" ")));
    // }

    handleSubmit(e) {
        e.preventDefault();
        this.props.onClose();
    }

    render() {
        // console.log(this.props.data)
        return (
            <div className="modal fade show" id="exampleModalLong" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title" id="exampleModalLabel">{this.props.title}</h3>
                            <button type="button" onClick={this.props.onClose} className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {/* Name */}
                            <div className="row">
                                <div className="col-md-6">
                                    <label><b>Approval Routing Number</b></label>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <p>{this.props.data.approvalRoutingNumber || '-'}</p>
                                    </div>
                                </div>
                            </div>
                            {/* Contact */}
                            <div className="row">
                                <div className="col-md-6">
                                    <label><b>Associated Reference Number</b></label>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <p>{this.props.data.associatedReferenceNumber || '-'}</p>
                                    </div>
                                </div>
                            </div>
                            {/* Email */}
                            <div className="row">
                                <div className="col-md-6">
                                    <label><b>Country List</b></label>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <p>{this.props.data.countryList || '-'}</p>
                                    </div>
                                </div>
                            </div>


                            {/* Country */}
                            <div className="row">
                                <div className="col-md-6">
                                    <label><b>Document Number</b></label>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <p>{this.props.data.documentNumber || '-'}</p>
                                        {/* {this.state.countries} */}
                                    </div>
                                </div>
                            </div>

                            {/* Website Link */}
                            <div className="row">
                                <div className="col-md-6">
                                    <label><b>Effective Date</b></label>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <p>{this.props.data.effectiveDate || '-'}</p>
                                    </div>
                                </div>
                            </div>
                            {/* Approver */}
                            <div className="row">
                                <div className="col-md-6">
                                    <label><b>Language List</b></label>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <p>{this.props.data.languageList || '-'}</p>
                                    </div>
                                </div>
                            </div>
                            {/* Approval */}
                            <div className="row">
                                <div className="col-md-6">
                                    <label><b>Product Sub Family Name</b></label>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <p>{this.props.data.productSubFamilyNameOne
                                            + ' ' + this.props.data.productSubFamilyNameTwo}</p>
                                    </div>
                                </div>
                            </div>
                            {/* Logo */}
                            <div className="row">
                                <div className="col-md-6">
                                    <label><b>Obsolete Date</b></label>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <p>{this.props.data.obsoleteDate || '-'}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <label><b>Status</b></label>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <p>{this.props.data.status || '-'}</p>
                                    </div>
                                </div>
                            </div>
                            {/* <img src={this.state.preview ? this.state.preview : null} alt='o' /> */}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-secondary" data-dismiss="modal" onClick={this.props.onClose}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    users: state.users,
    logo: state.customers.selectedCustomerLogo
});

export default connect(mapStateToProps)(DocumentView);