import React, {
  Component
} from 'react';
import {
  Tab,
  Tabs,
  TabList,
  TabPanel
} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import '../assets/css/modal.css';
import '../assets/css/dataTable.css';
import { AddUserModal } from '../_components/AddUserModal';
import {
  userActions
} from '../_actions';
import {
  connect
} from 'react-redux';
import _ from 'lodash';
import Message from '../_components/Message';
import DeleteModal from '../_components/deleteModal';
import Table from '../_components/Table';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
// import Spinner from 'react-bootstrap/Spinner'
import DatePicker from 'react-date-picker';
import moment from 'moment';
import { API } from '../_services';
// import { Trash, Edit } from '../Icon';

class Manageuser extends Component {
  constructor() {
    super();
    this.state = {
      showPopup: false,
      active: 'ACTIVE USERS',
      users: [],
      message: '',
      error: '',
      modalData: {
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        role: '',
        isTrainingCompleted: '',
        action: '',
      },
      modal: false,
      id: null,
      name: null,
      setShow: false,
      dateStart: null,
      dateEnd: null,
      myRole: 'USER',
    };
    this.deleteUserId = this.deleteUserId.bind(this);
    this.togglePopup = this.togglePopup.bind(this);
  }
  selectTab = e => {
    let {
      menu
    } = this.state;
    this.setState({
      active: e.target.textContent
    })
  }

  togglePopup(userData) {
    this.setState({
      showPopup: !this.state.showPopup
    });
    if (!this.state.showPopup) {
      if (userData) {
        this.state.modalData = userData || {};
        this.state.modalData.action = 'Edit User';
      } else {
        this.state.modalData.action = 'Add User';
      }
    } else {
      this.setState({
        modalData: {
          id: '',
          firstName: '',
          lastName: '',
          email: '',
          role: '',
          action: ''
        }
      });
    }
  }

  componentDidMount() {
    this.props.listUsers();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.users !== this.props.users) {
      let updatedObj = { users: this.props.users.items };
      if (!prevProps.users.message) {
        updatedObj.message = this.props.users.message;
      }
      if (!prevProps.users.error) {
        updatedObj.error = this.props.users.error;
      }
      this.setState(updatedObj);
      setTimeout(() => {
        let newObj = {};
        newObj.message = this.props.users.message;
        newObj.error = this.props.users.error;
        this.setState(newObj);
      }, 2000);

      let users = this.props.state.users ? this.props.state.users.items : [];
      let user = this.props.state.authentication.user;
      let myUser = users && users.length > 0 ? users.filter(o => o.email === user) : null;
      this.setState({ myRole: myUser && myUser[0] ? myUser[0].role : 'USER' });

    }
  }

  deleteUserId(id) {
    let users = this.state.users;
    let data = users.find((o) => o.id === id);
    this.setState({ modal: true, id: id, name: data.firstName });
    // this.props.deleteUser(id);
  }

  deleteHandle = (id) => {
    // console.log(id);
    this.props.deleteUser(id);
    this.setState({ modal: false, id: null });
  }

  editHandle = id => {
    let users = this.state.users;
    let data = users.find((o) => o.id === id);
    this.setState({ modalData: { ...data, action: 'Edit ' + data.firstName }, showPopup: true });
  }

  handleClose = () => this.setState({ setShow: false });
  handleShow = () => this.setState({ setShow: true });

  handleDownload = () => {
    this.setState({ setShow: true });
    let start = moment(this.state.dateStart).format('DD-MMM-YYYY');
    let end = moment(this.state.dateEnd).format('DD-MMM-YYYY');

    API.getAuditReport(start, end).then((response) => {
      if (response.status === 200) {
        // this.setState({ emailSentMessage: response.data.message});
        // console.log(response.data)
        const blob = new Blob([response.data], { type: 'text/csv' })
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.setAttribute('hidden', '')
        a.setAttribute('href', url)
        a.setAttribute('download', 'download.csv')
        document.body.appendChild(a)
        a.click()
      }
    }).catch((err) => {
    })
  }
  onAuditReport = (date, type) => {
    this.setState({ [type]: date });
  }
  render() {

    let { users, active, message, error, setShow } = this.state;
    users = users ? users : [];
    // let number = 1;

    const activeUserHeader = [
      { headerName: 'NO.', field: 'no', resizable: true },
      { headerName: 'FIRST NAME', field: 'firstName', resizable: true },
      { headerName: 'LAST NAME', field: 'lastName', resizable: true },
      { headerName: 'EMAIL', field: 'email', resizable: true },
      { headerName: 'ROLE', field: 'role', resizable: true }
    ];

    const allUsersHeader = [
      { headerName: 'NO.', field: 'no', resizable: true },
      { headerName: 'FIRST NAME', field: 'firstName', resizable: true },
      { headerName: 'LAST NAME', field: 'lastName', resizable: true },
      { headerName: 'EMAIL', field: 'email', resizable: true },
      { headerName: 'ROLE', field: 'role', resizable: true },
      {
        headerName: 'STATUS', field: 'status', cellRendererFramework: (param) => {
          return param.node.data.isDeleted ? <span style={{ color: 'red' }}>INACTIVE</span> : param.node.data.userActive ? <span style={{ color: 'green' }}>ACTIVE</span> : <span style={{ color: 'orange' }}>INACTIVE</span>
        }
      }
    ];

    if(this.state.myRole === 'ADMIN') {
      allUsersHeader.push({
        headerName: 'ACTION', cellRendererFramework: (params) => {
          return (
            <React.Fragment>    
              <span className="mdi mdi-delete" onClick={() => this.deleteUserId(params.node.data.id)} style={{ fontSize: '18px', color: '#e91e63', cursor: 'pointer' }} data-toggle="tooltip" data-placement="top" title="Delete"></span>
              <span className="mdi mdi-pencil" onClick={() => this.editHandle(params.node.data.id)} style={{ fontSize: '18px', color: '#198ae3', cursor: 'pointer', marginRight: '5px' }} data-toggle="tooltip" data-placement="top" title="Edit"></span>
            </React.Fragment>)
      }});
      activeUserHeader.push(
        {
          headerName: 'ACTION', cellRendererFramework: (params) => {
            return (
              <React.Fragment>
                <span className="mdi mdi-pencil" onClick={() => this.editHandle(params.node.data.id)} style={{ fontSize: '18px', color: '#198ae3', cursor: 'pointer', marginRight: '5px' }} data-toggle="tooltip" data-placement="top" title="Edit"></span>
                <span className="mdi mdi-delete" onClick={() => this.deleteUserId(params.node.data.id)} style={{ fontSize: '18px', color: '#e91e63', cursor: 'pointer' }} data-toggle="tooltip" data-placement="top" title="Delete"></span>
              </React.Fragment>)
          },
        }
      )
    }

    // let activeUsers = users.length > 0 && users.map((user, i) =>
    //   user.userActive && !user.isDeleted ?
    //     <tr id={user.email} key={i}>
    //       <td>{number++}</td>
    //       <td>{user.firstName}</td>
    //       <td>{user.lastName}</td>
    //       <td>{user.email}</td>
    //       <td>{user.role}</td>
    //       <td className="disp">
    //         <span className="mdi mdi-pencil" onClick={() => this.editHandle(user.id)} style={{ fontSize: '18px', color: '#198ae3', cursor: 'pointer', marginRight: '5px' }}></span>
    //         <span className="mdi mdi-delete" onClick={() => this.deleteUserId(user.id)} style={{ fontSize: '18px', color: '#e91e63', cursor: 'pointer' }}></span>
    //       </td>
    //     </tr>
    //     : null
    // )

    let activeUserList = []; let allUsersList = [];
    let num = 1;
    typeof (users) === 'object' && users.length > 0 && users.map((value, i) => {
      if (value.userActive && !value.isDeleted) {
        activeUserList.push({
          no: num++,
          firstName: value.firstName,
          lastName: value.lastName,
          email: value.email,
          role: value.role,
          id: value.id,
        });
      }
      allUsersList.push({
        no: ++i,
        firstName: value.firstName,
        lastName: value.lastName,
        email: value.email,
        role: value.role,
        userActive: value.userActive,
        isDeleted: value.isDeleted,
        // status: value.isDeleted ? <span style={{ color: 'red' }}>DELETED</span> : value.userActive ? <span style={{ color: 'green' }}>ACTIVE</span> : <span style={{ color: 'orange' }}>INACTIVE</span>,
        id: value.id,
        status: value.isDeleted ? 'INACTIVE' : (value.userActive ? 'ACTIVE' : 'INACTIVE')
      })
    });

    // console.log(activeUserList)
    // let allUsers = users.length > 0 && users.map((user, i) =>
    //   user.userActive ?
    //     <tr id={user.email} key={i}>
    //       <td>{++i}</td>
    //       <td>{user.firstName}</td>
    //       <td>{user.lastName}</td>
    //       <td>{user.email}</td>
    //       <td>{user.role}</td>
    //       <td>{user.isDeleted ? <span style={{ color: 'red' }}>DELETED</span> : user.userActive ? <span style={{ color: 'green' }}>ACTIVE</span> : <span style={{ color: 'orange' }}>INACTIVE</span>}</td>
    //       <td className="disp">
    //         <span className="mdi mdi-pencil" onClick={() => this.editHandle(user.id)} style={{ fontSize: '18px', color: '#198ae3', cursor: 'pointer', marginRight: '5px' }}></span>
    //         {!user.isDeleted ? <span className="mdi mdi-delete" onClick={() => this.deleteUserId(user.id)} style={{ fontSize: '18px', color: '#e91e63', cursor: 'pointer' }}></span> : null}
    //       </td>
    //     </tr>

    //     : null
    // )

    return (

      <div className="container-fluid">
        {/* {
          message && <div className="alert alert-success" role="alert">
            {message}
          </div>
        }
        {error &&
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        } */}

        <Message error={error} success={message} />

        {this.state.showPopup ?
          <AddUserModal
            text='Close Me'
            closePopup={this.togglePopup.bind(this)}
            modalData={this.state.modalData}
            myRole={this.state.myRole}
          />
          : null
        }

        {this.state.modal ? <DeleteModal modal={true} deleteContext="Deactivate" name={this.state.name} onClose={() => this.setState({ modal: false, id: null })} onDelete={() => this.deleteHandle(this.state.id)} /> : null}

        <div className="content-wrapper main-bg"></div>
        <div className="page-header">
          <div className="row w-100">
            <div className="col-md-6">
              <h3 className="page-title">
                <span className="page-title-icon bg-gradient-primary text-white mr-2">
                  <i className="mdi mdi-file-multiple"></i>
                </span>
                    Users List
                </h3>
            </div>
            <div className="col-md-6 buttons">
              {this.state.myRole === 'ADMIN' ?
                <button onClick={() => this.togglePopup()} className="btn btn-sm btn-info float-right ml-auto"><i className="mdi mdi-account-plus menu-icon pr-2"></i>ADD USER</button>
                : null}
              <button onClick={this.handleShow} className="btn btn-sm btn-dark float-right ml-auto"><i className="mdi mdi-clipboard-arrow-down menu-icon pr-2"></i>Download Audit Reports</button>
            </div>
          </div>
        </div>

        <div style={{ background: 'white', padding: '30px', borderRadius: "15px", boxShadow: "2px 2px solid red", overflow: 'auto' }}>
          <Tabs>
            <TabList className="stltab">
              <Tab onClick={this.selectTab}><span className={active === 'ACTIVE USERS' ? "activeTab" : ""}>ACTIVE USERS</span></Tab>
              <Tab onClick={this.selectTab}><span className={active === 'ALL USERS' ? "activeTab" : ""}>ALL USERS</span></Tab>
              {/* <Tab onClick={this.selectTab}><span className={active === 'REQUESTED USERS' ? "active font-weight-bold" : ""}>REQUESTED USERS</span></Tab> */}
            </TabList>
            <TabPanel>
              {/* <table className="table table-hover bg-white manage-user-table" style={{ marginTop: '20px' }}>
                <thead className="text-center" >
                  <tr className="tableheader">
                    <th>No</th>
                    <th>Firstname</th>
                    <th>Lastname</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className="">
                  {activeUsers}
                </tbody>
              </table> */}

              <Table headers={activeUserHeader} data={activeUserList} />

            </TabPanel>
            <TabPanel>
              {/* <table className="table table-hover bg-white manage-user-table" style={{ marginTop: '20px' }}>
                <thead className="text-center" >
                  <tr className="tableheader">
                    <th>No</th>
                    <th>Firstname</th>
                    <th>Lastname</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className="">
                  {allUsers}
                </tbody>
              </table> */}

              <Table headers={allUsersHeader} data={allUsersList} />

            </TabPanel>
            {/* <TabPanel>
              <table className="table table-hover bg-white" style={{ marginTop: '20px' }}>
                <thead className="">
                  <tr className="tableheader">
                    <th>Firstname</th>
                    <th>Lastname</th>
                    <th>Email</th>
                    <th>phno</th>
                    <th>STATUS</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className="">
                  {
                    users.length > 0 && users.map((user, i) =>
                      !user.userActive ?
                        <tr id={user.email} key={i}>
                          <td>{user.firstName}</td>
                          <td>{user.lastName}</td>
                          <td>{user.email}</td>
                          <td>{user.role}</td>
                          <td>{user.userActive ? 'ACTIVE' : 'INACTIVE'}</td>
                          <td className="disp">
                            <button onClick={() => null} className="btn btn-sm btn-warning" style={{ marginRight: '5px' }}><span className="mdi mdi-pencil" style={{ fontSize: '16px' }}></span></button>
                            <button onClick={() => this.deleteUserId(user.id)} className="btn btn-sm btn-danger"><span className="mdi mdi-delete" style={{ fontSize: '16px' }}></span></button>
                          </td>
                        </tr>

                        : null

                    )
                  }
                </tbody>
              </table>
            </TabPanel> */}
          </Tabs>
        </div>
        <Modal show={setShow} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Download Audit Reports</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Date Start: </label>
              <DatePicker className="form-control datepickerWidth" name='dateStart' value={this.state.dateStart} onChange={(e) => this.onAuditReport(e, 'dateStart')} format='dd/MM/yyy' />
            </div>
            <div className="form-group">
              <label>Date End: </label>
              <DatePicker className="form-control datepickerWidth" name='dateEnd' value={this.state.dateEnd} onChange={(e) => this.onAuditReport(e, 'dateEnd')} format='dd/MM/yyy' />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>Cancel</Button>
            <Button variant="primary" onClick={this.handleDownload}>Download</Button>

          </Modal.Footer>
        </Modal>
      </div >

    )
  }
}

const mapStateToProps = state => ({
  users: state.users,
  state: state,
})

const actionCreators = {
  listUsers: userActions.listUsers,
  deleteUser: userActions.deleteUser
};

const connectedApp = connect(mapStateToProps, actionCreators)(Manageuser);
export {
  connectedApp as Manageuser
};