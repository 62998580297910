import React from "react";
import "../assets/css/modal.css";
import "../assets/css/dataTable.css";
import { API } from "../_services/API";
import { connect } from "react-redux";
import { countries } from "../_constants/countries";

class CustomerView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countries: null,
      approver: null,
      preview: null,
      data: null,
      load: true
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    // console.log(API.getCustomerById(12))
    // API.getCustomerById(12).then(resp => {
    //   if (resp.status === 200) {
    //     this.setState({ data: resp.data, load: false })
    //   }
    // }).catch(err => {
    //   this.setState({ data: null, load: false });
    // })

    let country = this.props.customerData.countryExclusionList
      ? this.props.customerData.countryExclusionList.split(",")
      : null;
    let selectedCountry = [];

    if (country) {
      country.map((v) => {
        if (countries.find((o) => o.value === v)) {
          selectedCountry.push(countries.find((o) => o.value === v).label);
        }
      });
    }
    // console.log(this.props.logo.data)
    // this.setState({ preview: `data:${this.props.logo.data};base64, image/png` })
    // this.setState({ preview: 'data:image/jpeg;base64,' + this.hexToBase64(this.props.logo.data) })
    // preview: `data:${resp.data.type};base64,${resp.data.picByte}`
    let countriesList =
      selectedCountry.length > 0
        ? selectedCountry.toString().replace(/,/g, ", ")
        : selectedCountry.toString();

    let approverId = this.props.customerData.approverId;
    let users = this.props.users.items;
    if (users) {
      let approver = users.find((o) => o.id === Number(approverId));
      this.setState({ countries: countriesList, approver: approver });
    }
    // console.log('loading');
  }

  // hexToBase64(str) {
  //   return btoa(String.fromCharCode.apply(null, str.replace(/\r|\n/g, "").replace(/([\da-fA-F]{2}) ?/g, "0x$1 ").replace(/ +$/, "").split(" ")));
  // }

  handleSubmit(e) {
    e.preventDefault();
    this.props.closePopup();
  }

  render() {
    console.log(this.props);
    return (
      <div
        className="modal fade show"
        id="exampleModalLong"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title" id="exampleModalLabel">
                {this.props.title}
              </h3>
              <button
                type="button"
                onClick={this.handleSubmit}
                className="close"
                data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {/* Name */}

              <div className="row">
                <div className="col-md-6">
                  <label>
                    <b>Name:</b>
                  </label>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    {/* <p>{this.props.customerData.customerName || '-'}</p> */}
                    <p>{this.props.customerData.customerName || "-"}</p>
                  </div>
                </div>
              </div>
              {/* Contact */}
              <div className="row">
                <div className="col-md-6">
                  <label>
                    <b>Contact Number:</b>
                  </label>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <p>{this.props.customerData.contactInformation || "-"}</p>
                  </div>
                </div>
              </div>
              {/* Email */}
              <div className="row">
                <div className="col-md-6">
                  <label>
                    <b>Email:</b>
                  </label>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <p>{this.props.customerData.contactEmail || "-"}</p>
                  </div>
                </div>
              </div>

              {/* <div className="row">
                <div className="col-md-6">
                  <label><b>Color Background:</b></label>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <p>{this.props.customerData.colorBackground || '-'}</p>
                  </div>
                </div>
              </div> */}

              {/* Country */}
              <div className="row">
                <div className="col-md-6">
                  <label>
                    <b>Country:</b>
                  </label>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    {/* <p>{this.props.customerData.countryExclusionList || '-'}</p> */}
                    {this.state.countries}
                  </div>
                </div>
              </div>

              {/* Email */}
              {/* <div className="row">
                <div className="col-md-6">
                  <label><b>Email:</b></label>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <p>{this.props.customerData.contactEmail || '-'}</p>
                  </div>
                </div>
              </div> */}
              {/* <div className="row">
                <div className="col-md-6">
                  <label><b>Search:</b></label>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <p>{this.props.customerData.customerSearch || '-'}</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label><b>Url Prefix:</b></label>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <p>-</p>
                  </div>
                </div>
              </div> */}
              {/* Website Link */}
              <div className="row">
                <div className="col-md-6">
                  <label>
                    <b>Website link:</b>
                  </label>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <p>{this.props.customerData.customerWebsiteLink || "-"}</p>
                  </div>
                </div>
              </div>
              {/* Approver */}
              <div className="row">
                <div className="col-md-6">
                  <label>
                    <b>Approver</b>
                  </label>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <p>
                      {this.state.approver
                        ? this.state.approver.firstName +
                          " " +
                          this.state.approver.lastName +
                          " (" +
                          this.state.approver.email +
                          ")"
                        : null}
                    </p>
                  </div>
                </div>
              </div>
              {/* Approval */}
              <div className="row">
                <div className="col-md-6">
                  <label>
                    <b>Approval Date:</b>
                  </label>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <p>{this.props.customerData.approvedDate || "-"}</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label>
                    <b>Status:</b>
                  </label>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <p>{this.props.customerData.status || "-"}</p>
                  </div>
                </div>
              </div>
              {/* Logo */}
              <div className="row">
                <div className="col-md-6">
                  <label>
                    <b>Logo:</b>
                  </label>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <img src={this.props.customerData.logoUrl || ""} width={100} height={100} />
                  </div>
                </div>
              </div>
              {/* <img src={this.state.preview ? this.state.preview : null} alt='o' /> */}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-sm btn-secondary"
                data-dismiss="modal"
                onClick={this.handleSubmit}>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
  logo: state.customers.selectedCustomerLogo
});

export default connect(mapStateToProps)(CustomerView);
