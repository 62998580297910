import { userConstants } from '../_constants';

export function customers(state = {}, action) {
    switch (action.type) {
        case userConstants.CHECK_ADD_CUSTOMER_SUCCESS:
            return {
                ...state, message: action.message, document: document
            }
        case userConstants.CHECK_ADD_CUSTOMER_FAILURE:
            return {
                ...state, error: action.error
            }
        case userConstants.CHECK_UPDATE_CUSTOMER_SUCCESS:
            return {
                ...state, message: action.message
            }
        case userConstants.CHECK_UPDATE_CUSTOMER_FAILURE:
            return {
                ...state, error: action.error
            }
        case userConstants.CHECK_LIST_CUSTOMER_SUCCESS:
            return {
                ...state, items: action.customers
            };
        case userConstants.CHECK_LIST_CUSTOMER_SUCCESS:
            return {
                ...state, error: action.error
            };
        case userConstants.ADD_SELECTED_CUSTOMER:
            return {
                ...state, selectedCustomer: action.selectedCustomer,
            };
        // LOGO
        case userConstants.GET_LOGO:
            // console.log('logo reducer')
            return {
                ...state, selectLogo: action.selectLogo,
            };
        case userConstants.REMOVE_SELECTED_CUSTOMER:
            return {
                ...state, selectedCustomer: action.selectedCustomer
            };
        case userConstants.REMOVE_ADD_UPDATE_MESSAGE:
            return {
                ...state, message: action.message, error: action.message
            };
        case userConstants.APPROVER_INITIATOR_FETCH_SUCCESS:
            return {
                ...state, approvalInitiatorList: action.approvalInitiatorList
            };
        case userConstants.APPROVER_INITIATOR_FETCH_FAILURE:
            return {
                ...state, error: action.error
            };
        case userConstants.APPROVE_CUSTOMER_SUCCESS:
            return {
                ...state, message: action.message
            };
        case userConstants.APPROVE_CUSTOMER_FAILURE:
            return {
                ...state, error: action.message
            };
        default:
            return state
    }
}