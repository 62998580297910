import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { store } from "../_helpers";
import { customerActions } from "../_actions";
import { API } from "../_services";
import "../assets/css/modal.css";
import "../assets/css/dataTable.css";
import { history } from "../_helpers";
import { findCountryByShortCode, countries } from "../_constants/countries";
import { Multiselect } from "multiselect-react-dropdown";
import Select from "react-dropdown-select";
import { Error, validateEmail, isEmpty, validURL } from "../_components/Validation";

class AddCustomer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCustomer: {},
      options: countries,
      countryExclusionList: [],
      customerLogo: null,
      errors: {},
      load: false,
      approvers: [],
      approver: [],
      logo: null
    };
    // console.log(countries)
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.onCountrySelection = this.onCountrySelection.bind(this);
    this.onCustomerLogoChange = this.onCustomerLogoChange.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    // console.log(name, value)

    // var stateCopy = Object.assign({}, this.state);
    // stateCopy.selectedCustomer[name] = value;
    // console.log(stateCopy)
    // this.setState(stateCopy, () => { this.checkValidation() });
    this.setState({ selectedCustomer: { ...this.state.selectedCustomer, [name]: value } }, () => {
      this.checkValidation();
    });
  }

  validation = () => {
    const {
      contactInformation,
      customerName,
      contactEmail,
      customerWebsiteLink
    } = this.state.selectedCustomer;
    const { customerLogo } = this.state;

    let errors = {};
    // if (!customerName) errors['name'] = 'Enter Customer Name';
    // if (!contactInformation) errors['contact'] = 'Enter Contact Number'; else if (isNaN(contactInformation)) errors['fname'] = 'Invalid Contact Number';
    // if (!contactEmail) errors['email'] = 'Enter Email'; else if (!validateEmail(contactEmail)) contactEmail['email'] = 'Invalid Email Address';
    // // if (!customerLogo) errors['logo'] = 'Select a Logo';
    if (!customerName) errors["name"] = "Enter Customer Name";
    else if (!isNaN(customerName)) errors["name"] = "Should not be a Number";
    if (!contactInformation) errors["contact"] = "Enter Contact Number";
    // else if (isNaN(contactInformation)) errors["contact"] = "Invalid Contact Number";
    else if (contactInformation.length < 10 || contactInformation.length > 15)
      errors["contact"] = "Contact Number Should be 10 to 15 Digits";
    if (!contactEmail) errors["email"] = "Enter Email";
    else if (!validateEmail(contactEmail)) errors["email"] = "Invalid Email Address";
    if (!validURL(customerWebsiteLink)) errors["url"] = "Invalid URL";
    if (!customerLogo && this.props.match.params.customerId === "create")
      errors["logo"] = "Select a Logo";
    this.setState({ errors: errors });
    return errors;
  };

  checkValidation = () => {
    const {
      contactInformation,
      contactEmail,
      customerName,
      customerWebsiteLink
    } = this.state.selectedCustomer;
    let errors = {};
    if (contactEmail && !validateEmail(contactEmail)) errors["email"] = "Invalid Email Address";
    if (
      contactInformation &&
      (contactInformation.length < 10 || contactInformation.length > 15)
    )
      errors["contact"] = "Should be 10 to 15 Digits";
    if (customerName && !isNaN(customerName)) errors["name"] = "Should not be a Number";
    if (customerWebsiteLink && !validURL(customerWebsiteLink)) errors["url"] = "Invalid URL";
    this.setState({ errors: errors });
  };

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true, load: true });
    // console.log(isEmpty(this.validation()))
    if (isEmpty(this.validation())) {
      let number = this.state.selectedCustomer.contactInformation.replace(/\D/g, '');;
      const customerData = {
        contactInformation: number,
        customerName: this.state.selectedCustomer.customerName,
        contactEmail: this.state.selectedCustomer.contactEmail,
        // customerSearch: this.state.selectedCustomer.customerSearch,
        approverId: this.state.selectedCustomer.approverId,
        customerSearch: null,
        customerWebsiteLink: this.state.selectedCustomer.customerWebsiteLink,
        countryExclusionList: this.state.countryExclusionList,
        customerLogo: this.state.customerLogo
      };
      // console.log('customer', customerData);
      if (this.props.match.params.customerId === "create") {
        // customerData.approverId = '1';
        this.props.addCustomer(customerData);
      } else {
        customerData.id = this.state.selectedCustomer.id;
        this.props.updateCustomer(customerData);
        //   history.push('/managecustomer');
      }
      this.setState({ load: false });
    } else {
      this.setState({ load: false });
    }
  }

  componentDidMount() {
    let country = this.props.selectedCustomer.countryExclusionList
      ? this.props.selectedCustomer.countryExclusionList.split(",")
      : null;
    let selectedCountry = [];
    let selectedApprover = [];
    if (country) {
      country.map((v) => {
        if (countries.find((o) => o.value === v)) {
          selectedCountry.push(countries.find((o) => o.value === v));
        }
      });
    }
    // console.log('after logo', this.props.logo
    // if (this.props.logo && this.props.logo.data) {
    //   this.setState({ logo: this.props.logo.data })
    // }
    let users = this.props.users.items;
    let activeAdmin = [];
    if (typeof users === "object" && users && users.length > 0) {
      users.map((v) => {
        if (v.role === "ADMIN" && v.userActive && !v.isDeleted && v.email != this.props.auth.user) {
          activeAdmin.push({ value: v.id, name: v.firstName + " " + v.lastName, label: v.email });
        }
      });
    }
    // console.log('next', this.props)
    if (this.props.selectedCustomer.approverId && activeAdmin.length > 0) {
      let approverId = this.props.selectedCustomer.approverId;
      let value = activeAdmin.find((o) => o.value === Number(approverId));
      if (value) selectedApprover.push(value);
    }

    if (this.props.selectedCustomer.logoUrl) {
      console.log(this.props.selectedCustomer.logoUrl, " url");
    }

    this.setState({
      selectedCustomer: this.props.selectedCustomer,
      selectedValue: selectedCountry,
      countryExclusionList: selectedCountry,
      approvers: activeAdmin,
      approver: selectedApprover
    });
  }

  componentWillReceiveProps(nextProps) {
    // console.log('next', nextProps)
  }

  onMultiSelect = (selectedList, name) => {
    let list = selectedList.map((country) => {
      return country.value;
    });

    this.setState({ [name]: list });
  };

  onSelect = (value, name) => {
    this.setState({ selectedCustomer: { ...this.state.selectedCustomer, [name]: value[0].value } });
  };

  onCustomerLogoChange(e) {
    this.setState({ customerLogo: e.target.files[0] });
  }

  render() {
    let {
      contactInformation,
      customerName,
      contactEmail,
      customerSearch,
      customerWebsiteLink,
      approverId,
      logoUrl
    } = this.state.selectedCustomer;
    // console.log(this.state)
    return (
      <div className="container-fluid">
        {/* <img src={`data:image/jpeg;base64,${this.state.logo}`} /> */}
        <Link to="/managecustomer">
          <span style={{ fontSize: "20px", marginLeft: "3%", color: "black" }}>
            <i className="mdi mdi-arrow-left-thick"></i>
          </span>
        </Link>
        <div className="content-wrapper main-bgs">
          <div className="page-header">
            <h3 className="page-title">
              <span className="page-title-icon bg-gradient-primary text-white mr-2">
                <i className="mdi mdi-plus-circle"></i>
              </span>
              {this.props.match.params.customerId === "create" ? "Add Customer" : "Edit Customer"}
            </h3>
          </div>
          <div className="row">
            <div className="col-12 grid-margin">
              <div className="card">
                <div className="card-body">
                  <form encType="multipart/form-data">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id=""
                            name="customerName"
                            value={customerName ? customerName : ""}
                            placeholder=""
                            onChange={this.handleChange}
                          />
                          <Error error={this.state.errors["name"]} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Contact Number</label>
                          <input
                            type="text"
                            className="form-control"
                            id=""
                            name="contactInformation"
                            value={contactInformation ? contactInformation : ""}
                            placeholder=""
                            onChange={this.handleChange}
                          />
                          <Error error={this.state.errors["contact"]} />
                        </div>
                      </div>
                      {this.props.match.params.customerId === "create" ? (
                        <div className="col-md-6">
                          <div className="form-group">
                            <label> Logo</label>

                            <div className="input-group">
                              <input
                                type="file"
                                id="file-upload"
                                className="form-control"
                                placeholder=""
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                                style={{ disaply: "none" }}
                                onChange={this.onCustomerLogoChange}
                              />
                              {/* <div className="input-group-append">
                                                            <button className="btn btn-sm btn-gradient-primary" type="button">Upload</button>
                                                        </div> */}
                            </div>
                            <Error error={this.state.errors["logo"]} />
                          </div>
                        </div>
                      ) : null}
                      <div
                        className={
                          this.props.match.params.customerId === "create" ? "col-md-6" : "col-md-12"
                        }>
                        <div className="form-group">
                          <label>Email</label>
                          <input
                            type="text"
                            className="form-control"
                            id=""
                            name="contactEmail"
                            value={contactEmail ? contactEmail : ""}
                            placeholder=""
                            onChange={this.handleChange}
                          />
                          <Error error={this.state.errors["email"]} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Country</label>
                          {/* <select className="form-control">
                                                        <option>country 1</option>
                                                        <option>country 2</option>
                                                        <option>country 3</option>
                                                    </select> */}

                          {/* <Multiselect
                            options={this.state.options} // Options to display in the dropdown
                            // selectedValues={this.state.countryExclusionList} // Preselected value to persist in dropdown
                            onSelect={this.onCountrySelection} // Function will trigger on select event
                            onRemove={this.onCountrySelection}
                            displayValue="label"
                          /> */}
                          <Select
                            multi
                            options={this.state.options}
                            values={this.state.selectedValue}
                            onChange={(values) =>
                              this.onMultiSelect(values, "countryExclusionList")
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Approver</label>
                          <Select
                            // key={this.state.approvers}
                            options={this.state.approvers}
                            values={this.state.approver}
                            onChange={(value) => this.onSelect(value, "approverId")}
                          />
                          {/* <input type="text" className="form-control" id="" name="customerSearch" value={customerSearch ? customerSearch : ''} placeholder="" onChange={this.handleChange} /> */}
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Website link</label>
                          <input
                            type="text"
                            className="form-control"
                            id="customerWebsiteLink"
                            name="customerWebsiteLink"
                            value={customerWebsiteLink ? customerWebsiteLink : ""}
                            placeholder=""
                            onChange={this.handleChange}
                          />
                          <Error error={this.state.errors["url"]} />
                        </div>
                      </div>

                      {this.props.match.params.customerId !== "create" ? (
                        <div className="col-md-12">
                          <div className="form-group">
                            <label> Logo</label>

                            <div className="input-group">
                              <input
                                type="file"
                                id="file-upload"
                                className="form-control"
                                placeholder=""
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                                style={{ disaply: "none" }}
                                onChange={this.onCustomerLogoChange}
                              />
                              {/* <div className="input-group-append">
                                                            <button className="btn btn-sm btn-gradient-primary" type="button">Upload</button>
                                                        </div> */}
                            </div>
                            <Error error={this.state.errors["logo"]} />
                            <br />
                            <img src={logoUrl ? logoUrl : ""} alt="logo" width={100} height={100} />
                          </div>
                        </div>
                      ) : null}

                      <div className="col-md-12 form-group text-center">
                        <div className="row d-flex align-items-center mt-5">
                          <div className="col-md-12 text-center">
                            {this.state.load ? (
                              <span className="btn btn-block btn-gradient-primary btn-md fit-content font-weight-medium auth-form-btn m-auto">
                                Submitting
                              </span>
                            ) : (
                                <span
                                  className="btn btn-block btn-gradient-primary btn-md fit-content font-weight-medium auth-form-btn m-auto"
                                  onClick={this.handleSubmit}>
                                  {this.props.match.params.customerId === "create"
                                    ? "Add Customer"
                                    : "Update Customer"}
                                </span>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedCustomer: state.customers.selectedCustomer || {},
  users: state.users,
  logo: state.customers.logo,
  auth: state.authentication,
});

const actionCreators = {
  addCustomer: customerActions.addCustomer,
  updateCustomer: customerActions.updateCustomer
};

const addCustomerPage = connect(mapStateToProps, actionCreators)(AddCustomer);
export { addCustomerPage as AddCustomer };
