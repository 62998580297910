import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Error, isEmpty } from "./Validation";
import { userActions } from "../_actions/user.actions";
import logo from "../assets/images/shedpaper.jpg";
import { API } from "../_services";
import { Link } from 'react-router-dom';
import { validateEmail } from "../_components/Validation";


export const ContactUs = ({
  ContactUsMessage,
  ContactUsError,
  ContactUs,
}) => {
  const [state, setState] = useState({
    email: "",
    name: "",
    phoneNumber: "",
    subject: "",
    message: ""
  });
  const [value, setValue] = useState({ errors: {}, load: false });

  const handleChange = (e) => {
    e.persist();
    setState((state) => ({ ...state, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    let errors = {};
    setValue((value) => ({ ...value, errors: errors }));
    setTimeout(() => {
      if (ContactUsMessage) {
        setState({
          ContactUsMessage: ''
        });
      }
      if (ContactUsError) {
        setState({
          ContactUsError: ''
        });
      }
    }, 5000);
  }, [state, ContactUsMessage, ContactUsError]);

  const handleSubmit = (e) => {
    setValue((value) => ({ ...value, load: true }));
    if (isEmpty(handleValid())) {
      setValue((value) => ({ ...value, load: false }));
      let userData = {
        email: state.email,
        name: state.name,
        phoneNumber: state.phoneNumber,
        subject: state.subject,
        textField: state.message
      };
      API.contactUs(userData)
        .then((result) => {
          setState((state) => ({ ...state, 'ContactUsMessage': "Successfully contacted to administrator." }));
        })
        .catch((err) => {
          setState((state) => ({ ...state, 'ContactUsError': "Error on connecting administrator." }));
        });
    } else {
      setValue((value) => ({ ...value, load: false }));
    }
  };

  const handleValid = (e) => {
    const { email, name, phoneNumber, subject, message } = state;
    let errors = {};
    if (!email) {
      errors["email"] = "Enter the eMail";
    } else if(!validateEmail(email)) {
      errors["email"] = "Invalid Email Address";
    }
    if (!phoneNumber) {
      errors["phoneNumber"] = "Enter a phone number";
    } else if (phoneNumber.length < 10 || phoneNumber.length > 15) {
      errors["phoneNumber"] = "Phone Number Should be 10 to 15 Digits";
    }
    if (!name) errors["name"] = "Enter the name";
    if (!subject) errors["subject"] = "Enter the subject";
    if (!message) errors["message"] = "Enter the message"
    setValue((value) => ({ ...value, errors: errors }));
    return errors;
  };

  return (
    <div className="container-fluid main-panel">
      <div className="page-header">
        <div className="row w-100">
          <div className="col-md-6">
            <img src={logo} className="img-fluid-med logo" />
            <h3 className="page-title">
              <span className="page-title-icon bg-gradient-primary text-white mr-2">
                <i className="mdi mdi-account-settings-variant"></i>
              </span>
              Contact US
            </h3>
          </div>
        </div>
      </div>
      {state.ContactUsMessage ? (
        <h6 className="font-weight-light text-center" style={{ color: "green" }}>
          {state.ContactUsMessage}
        </h6>
      ) : state.ContactUsError ? (
        <h6 className="font-weight-light text-center" style={{ color: "red" }}>
          {state.ContactUsError}
        </h6>
      ) : (
            ""
          )}
      <div
        style={{
          background: "white",
          borderRadius: "15px",
          boxShadow: "2px 2px solid red",
          overflow: "auto"
        }}>
        <form>
          <div className="col-md-6">
            <div className="form-group">
              <label>EMail</label>
              <input
                type="text"
                className="form-control form-control-sm"
                name="email"
                value={state.email}
                onChange={handleChange}
              />
              <Error error={value.errors["email"]} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Name</label>
              <input
                type="text"
                className="form-control form-control-sm"
                name="name"
                value={state.name}
                onChange={handleChange}
              />
              <Error error={value.errors["name"]} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>PhoneNumber</label>
              <input
                type="text"
                className="form-control form-control-sm"
                name="phoneNumber"
                value={state.phoneNumber}
                onChange={handleChange}
              />
              <Error error={value.errors["phoneNumber"]} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Subject</label>
              <input
                type="text"
                className="form-control form-control-sm"
                name="subject"
                value={state.subject}
                onChange={handleChange}
              />
              <Error error={value.errors["subject"]} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Message</label>
              <textarea
                type="text"
                className="form-control form-control-sm"
                name="message"
                value={state.message}
                onChange={handleChange}
              />
              <Error error={value.errors["message"]} />
            </div>
          </div>
          <div className="col-md-6 text-center">
            <a
              className={
                "btn btn-block btn-gradient-primary btn-md fit-content font-weight-medium auth-form-btn m-auto"
              }
              onClick={handleSubmit}>
              SUBMIT
            </a>
          </div>
        </form>
      </div>
      <footer className="footer text-center pt-3 pb-3s" style={{position: 'fixed', bottom: 0}}>
        <div className="container text-center">
          <p className="mb-0">
            © 2021 Powered by Shedpaper.{" "}
          </p>
        </div>
      </footer>
    </div>
  );
};

export default ContactUs;