import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from '../_reducers';

function loadFromLocalStorage() {
    try {
        const serializedState = localStorage.getItem('state');
        if (serializedState === null) return undefined;
        return JSON.parse(serializedState);
    } catch (e) {
        // console.log(e);
        return undefined;
    }
}

const persisted = loadFromLocalStorage();
const loggerMiddleware = createLogger();

export const store = createStore(
    rootReducer,
    persisted,
    applyMiddleware(
        thunkMiddleware,
        // loggerMiddleware
    )
);