import React from "react";
import { API } from "../_services";
import Select from "react-dropdown-select";
import { countries } from "../_constants/countries";
import { Languages } from "../_constants/functions";
import { SearchResult } from "./SearchResult";
import axios from "axios";
import "../LoginPage/login.css";
import logo from "../assets/images/shedpaper.jpg";
import { Link } from 'react-router-dom';
import { TermsAndCondition } from "../_components/TermsAndCondition";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

var qs = require("qs");


export class CustomerDocSearch extends React.Component {
  constructor() {
    super();
    this.state = {
      error: "",
      email: "",
      id: "",
      documentNumber: "",
      country: "",
      language: "",
      productName: "",
      searchResult: [],
      languages: [],
      languageList: [],
      selectedCountry: [],
      options: countries,
      hideSearchResult: true,
      language_label: "",
      country_label: "",
      searchField: "",
      searchData: "",
      custlogo: "",
      encryptedParentChangeId: "",
      productName: "",
      encryptedEmail: ""
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.selectedLanguageValue = this.selectedLanguageValue.bind(this);
    this.selectedCountryValue = this.selectedCountryValue.bind(this);
    this.toggleTermsAndCondition = this.toggleTermsAndCondition.bind(this);
  }

  async componentDidMount() {
    this.getGeoInfo();
    // const query = qs.parse(this.props.location.search, {
    //   ignoreQueryPrefix: true
    // });
    let query = {};
    let urlChunks = this.props.location.search.split('&');
    query.name = urlChunks[0].split('=')[1];
    query.parentChangeId = urlChunks[1].split('=')[1];
    query.email = urlChunks[2].split('=')[1];
    let logoData = await API.getCustomerLogo(query.name, query.parentChangeId);
    let productData = await API.getParentLogoForCustomer(query.name);
    let productNotification = logoData.data.productNotification || '';
    let filteredProduct;
    let prodLogo;
    if (productData.data && productData.data.list) {
      filteredProduct = productData.data.list.filter((prod) => {
        return Number(prod.parentChangeId) === Number(query.parentChangeId);
      });
      console.log("filteredProduct", filteredProduct);
      prodLogo = filteredProduct.length ? filteredProduct[0].logoUrl : logoData.data.logoUrl;
    }
    this.setState({
      custlogo: logoData.data.logoUrl && logoData.data.logoUrl,
      customerName: query.name,
      encryptedParentChangeId: query.parentChangeId,
      encryptedEmail: query.email,
      prodLogo: prodLogo,
      productNotification: productNotification
    });

    let langList = [];
    if (
      this.props.states &&
      this.props.states.language &&
      !Array.isArray(this.props.states.language)
    ) {
      let clist = this.props.states.language;
      let lang = clist.split(",");
      if (lang) {
        lang.map((v) => {
          let chk = Languages.find((o) => o.value === v);
          if (chk) {
            langList.push(chk);
          }
        });
      }
    } else if (
      this.props.states &&
      this.props.states.language &&
      Array.isArray(this.props.states.language)
    ) {
      let lang = this.props.states.language;
      if (lang) {
        lang.map((v) => {
          let chk = Languages.find((o) => o.value === v);
          if (chk) {
            langList.push(chk);
          }
        });
      }
    }

    this.setState({
      languages: langList,
      languageList: Languages
    });
  }

  handleChange(e) {
    const { name, value } = e.target;
    // console.log(name, value)
    this.setState({ [name]: value });
  }

  handleSubmit(event) {
    event.preventDefault();
    let formData = {
      language: this.state.language,
      country: this.state.country,
      keyword: this.state.keyword,
      documentNumber: this.state.documentNumber,
      productName: this.state.productName,
      encryptedCustomerId: this.state.id,
      encryptedEmail: this.state.encryptedEmail,
      parentChangeId: this.state.encryptedParentChangeId,
      customerName: this.state.customerName
    };
    this.setState({
      searchData: {
        keyword: this.state.keyword,
        language: this.state.language_label,
        country: this.state.country_label,
        documentNumber: this.state.documentNumber,
        customerName: this.state.customerName,
        productName: this.state.productName,
        encryptedEmail: this.state.encryptedEmail
      }
    });
    API.searchDocuments(formData)
      .then((result) => {
        this.setState({
          searchResult: result.data.list,
          hideSearchResult: false
        });
      })
      .catch((err) => {
        this.setState({ error: "No Data Found" });
      });
  }

  selectedLanguageValue(values) {
    this.setState({
      language: values[0].value,
      language_label: values[0].label
    });
  }

  selectedCountryValue(values) {
    this.setState({
      country: values[0].value,
      country_label: values[0].label
    });
  }

  getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        this.setState({
          country: data.country_code,
          country_label: data.country_name,
          selectedCountry: [{ value: data.country_code, label: data.country_name }]
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  toggleTermsAndCondition(){
    this.setState({
      showTermsAndCondition: !this.state.showTermsAndCondition,
    });
  }

  render() {
    let { languages, languageList } = this.state;
    return (
      <div className="container-fluid">
        {this.state.showTermsAndCondition ? (
          <TermsAndCondition
            text="Close Me"
            closePopup={this.toggleTermsAndCondition.bind(this)}
          />
        ) : null}
        {this.state.hideSearchResult ? (
          <div className="content1">
            <div className="container text-center">
              <div className="row d-flex justify-content-center mt-4">
                <div className="col-md-12 col-lg-8">
                  <div className="box001 text-left">
                    <form>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group mb-4">
                            <img src={this.state.custlogo} className="img-fluid-med logo" />
                            {this.state.productNotification && <h5>{this.state.productNotification}</h5>}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Search field </label>
                            <input
                              type="text"
                              name="keyword"
                              id=""
                              placeholder="Keywords"
                              value={this.state.keyword}
                              onChange={this.handleChange}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Document Number </label>
                            <input
                              type="text"
                              name="documentNumber"
                              id=""
                              placeholder=""
                              value={this.state.documentNumber}
                              onChange={this.handleChange}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Language</label>
                            <Select
                              className="form-control"
                              options={languageList}
                              values={languages}
                              onChange={(values) => this.selectedLanguageValue(values)}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Country</label>
                            <Select
                              className="form-control"
                              options={this.state.options}
                              values={this.state.selectedCountry}
                              onChange={(values) => this.selectedCountryValue(values)}
                            />
                          </div>
                        </div>
                        <div className="col-md-12 mt-4 text-center">
                          <button
                            className={
                              "btn btn-block btn-gradient-primary btn-md fit-content font-weight-medium auth-form-btn m-auto"
                            }
                            onClick={this.handleSubmit}>
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
            <div>
              <SearchResult
                custLogo={this.state.custlogo}
                prodLogo={this.state.prodLogo}
                searchData={this.state.searchData}
                email={this.state.encryptedEmail}
                searchResult={this.state.searchResult}></SearchResult>
            </div>
          )}

        <div className="footer text-center pt-3 pb-3s" style={{ position: "fixed" }}>
          <div className="container text-center">
            <p className="mb-0">
              © 2021 Powered by Shedpaper.{" "}
              <Link to='/contactus' style={{ color: 'black', textDecoration: 'none' }}>
                <a className="text-primary">
                  Contact
                </a>
              </Link>
              <a onClick={() => this.toggleTermsAndCondition()} className="text-primary TC">
                Terms And Condition
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}
