import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { customerActions } from "../_actions";
import { history } from "../_helpers";
import Message from "../_components/Message";
import "../assets/css/modal.css";
import "../assets/css/dataTable.css";
import Table from "../_components/Table";
import { API } from "../_services";
import logo from "../assets/images/shedpaper.jpg";

export class SearchResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      searchResult: [],
      searchData: props.searchData
    };
  }

  componentDidMount() {
    this.setState({ searchResult: this.props.searchResult || [] })
  }

  async downloadDocument(docPath) {
    let response, url;
    if (this.props.searchMode === 'internal') {
      response = await API.getDocumentDownload(docPath.childDocumentId);
      url = response.data ? response.data.documentPath : '';
    } else {
      response = await API.getDocumentURL(docPath.childDocumentId, this.props.email);
      url = response.data ? response.data.docURL : '';
    }
    if (url) {
      window.open(url, "_blank");
    }
  }
  render() {
    const searchResult = this.state.searchResult || [];
    const message = this.state.message;
    const error = "";

    const Header = [
      
      { headerName: "Document ID", field: "parentChangeId", resizable: true },
      { headerName: "Desc of document", field: "changeDescription", resizable: true },
      { headerName: "Keywords", field: "keywords", resizable: true },
      { headerName: "ChangeOrder No.", field: "changeOrderNo", resizable: true },
      { headerName: "Product Name", field: "productName", resizable: true },
      {
        headerName: "Download Document",
        width: "10px",
        cellRendererFramework: (params) => {
          return (
            <React.Fragment>
              <span
                className="mdi mdi-download"
                onClick={() => this.downloadDocument(params.node.data)}
              />
            </React.Fragment>
          );
        }
      }
    ];
    return (
      <div className="container-fluid">
        {this.props.searchMode !== 'internal' && <div className="container">
          <div className="head pt-3 text-center">
            <ul className="list-unstyled list-inline">
              <li className="list-inline-item">
                <a href="#">
                  <img src={this.props.custLogo} className="img-fluid-med logo" />
                </a>
              </li>
            </ul>
          </div>
        </div>}
        <Message error={error} success={message} />
        {this.props.searchMode !== 'internal' && <div className="row no-margin-row">
          <div className="col-12">
            <h3 className="text-black">Search Result</h3>
            <hr />
          </div>
          <div className="col-12 mb-3">
            <img src={this.props.prodLogo} className="img-fluid-med logo" />
          </div>
          <div className="col-md-4 col-lg-4">
            <small className="op-5 font-weight-bold">Search field</small>
            <p>{this.state.searchData.keyword}</p>
          </div>
          <div className="col-md-4 col-lg-2">
            <small className="op-5 font-weight-bold">Language </small>
            <p>{this.state.searchData.language}</p>
          </div>
          <div className="col-md-4 col-lg-2">
            <small className="op-5 font-weight-bold">Country </small>
            <p>{this.state.searchData.country}</p>
          </div>
          <div className="col-md-4 col-lg-2">
            <small className="op-5 font-weight-bold">Document Number</small>
            <p>{this.state.searchData.documentNumber} </p>
          </div>
        </div>}
        <div className="page-header">
          <div className="row w-100 no-margin-row">
            <div className="col-md-12">
              <h3>List of searched documents</h3>
              <hr />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <Table headers={Header} data={searchResult} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
