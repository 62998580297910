import React, { useEffect, useState } from "react";
import logo from "../assets/images/shedpaper.jpg";
import { Error, isEmpty } from "../_components/Validation";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { userActions } from "../_actions";
import { compose } from "redux";

function Forgot(props) {
  const [state, setState] = useState({
    newPassword: "",
    cPassword: "",
    otp: "",
  });
  const [value, setValue] = useState({
    errors: {},
    load: false,
    message: null,
    success: null,
  });

  let hash = props.match.params.id;
  // console.log(hash)
  const handleChange = (e) => {
    e.persist();
    setState((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    setValue((value) => ({
      ...value,
      load: true,
    }));
    if (isEmpty(handleValid())) {
      const data = {
        token: hash ? hash : (localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).resetToken : ''),
        password: state.newPassword,
      };
      props.setPassword(data);
      // setValue(value => ({ ...value, load: false }))
    } else {
      setValue((value) => ({
        ...value,
        load: false,
      }));
    }
  };

  const handleValid = (e) => {
    const { newPassword, otp, cPassword } = state;
    let errors = {};
    if (!newPassword) errors["new"] = "Enter New Password";
    if (!cPassword) errors["confirm"] = "Enter Confirm Password";
    // if (!otp) errors['otp'] = 'Enter OTP';
    // if (otp && (otp !== myOTP)) errors['otp'] = 'Invalid OTP';
    if (cPassword && newPassword && newPassword !== cPassword)
      errors["confirm"] = "Password Mismatch";
    setValue((value) => ({
      ...value,
      errors: errors,
    }));
    return errors;
  };

  useEffect(() => {
    const { cPassword, newPassword } = state;
    let errors = {};
    if (cPassword && newPassword && cPassword !== newPassword) {
      errors["confirm"] = "Password Mismatch";
    }
    setValue((value) => ({
      ...value,
      errors: errors,
    }));
  }, [state]);

  useEffect(() => {
    props.removeChangeSuccessMessage(null);
  }, []);

  // console.log('state', props.state)
  useEffect(() => {
    if (props.state.users.success) {
      if (props.state.users.success === "Password created successfully.") {
        setValue((state) => ({
          ...state,
          success: props.state.users.success,
          load: false,
        }));
        setTimeout(() => {
          setValue((state) => ({
            ...state,
            success: null,
          }));
          props.history.push("/login");
        }, 4000);
      } else {
        setValue((state) => ({
          ...state,
          message: props.state.users.success,
          load: false,
        }));
        setTimeout(() => {
          setValue((state) => ({
            ...state,
            message: null,
          }));
        }, 6000);
      }
      setTimeout(() => {
        setValue((state) => ({
          ...state,
          message: null,
          success: null,
        }));
      }, 10000);
    } else {
      setValue((state) => ({
        ...state,
        message: null,
        load: false,
      }));
    }
  }, [props.state.users]);

  return (
    <div
      className="content-wrapper d-flex align-items-center auth"
      style={{
        height: "100%",
      }}
    >
      <div className="row w-100">
        <div className="col-lg-5 col-md-6 mx-auto">
          <div className="auth-form-light text-left p-5">
            <div className="brand-logo">
              <img src={logo} />{" "}
            </div>{" "}
            <div className="loginCon">
              <h4 className="login"> Reset Password </h4>{" "}
              <h6 className="font-weight-light text-center">
                {props.location.pathname === '/user/setpassword/' && <span>Your password has expired. </span>} Please add new password to continue.{" "}
              </h6>{" "}
              <h6
                style={{
                  color: "green",
                }}
                className="font-weight-light text-center"
              >
                {" "}
                {value.success}{" "}
              </h6>{" "}
              <form className="pt-3" name="form">
                <div className="form-group mt-4 mb-4">
                  <input
                    type="password"
                    className="form-control form-control"
                    id="exampleInputEmail2"
                    placeholder="Enter New Password"
                    name="newPassword"
                    value={state.newPassword}
                    onChange={handleChange}
                  />
                  <br />
                  <input
                    type="password"
                    className="form-control form-control"
                    id="exampleInputEmail3"
                    placeholder="Enter Confirm Password"
                    name="cPassword"
                    value={state.cPassword}
                    onChange={handleChange}
                  />{" "}
                </div>{" "}
                <Error error={value.message} />
                <br />
                <Error error={value.errors["new"]} />
                <br />
                <Error error={value.errors["confirm"]} />
                <br />
                <div className="mt-2">
                  <a
                    className={
                      value.load
                        ? "btn btn-block btn-gradient-secondary btn-lg font-weight-medium auth-form-btn"
                        : "btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn"
                    }
                    onClick={value.load ? null : handleSubmit}
                  >
                    {" "}
                    {value.load ? "Submitting..." : "Submit"}{" "}
                  </a>{" "}
                </div>{" "}
              </form>{" "}
              <Link to="/login">
                {" "}
                <span
                  style={{
                    float: "left",
                    color: "blue",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <i className="mdi mdi-arrow-left-thick"> </i> Login
                </span>{" "}
              </Link>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>{" "}
    </div>
  );
}

const mapStateToProps = (state) => ({
  // changePasswordMessage: state.authentication.changePasswordMessage,
  // changePasswordError: state.authentication.changePasswordError,
  state: state,
});

const mapDispatchToProps = {
  setPassword: userActions.setPassword,
  removeChangeSuccessMessage: userActions.removeChangeSuccessMessage,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Forgot);
// export default connect(mapStateToProps, mapDispatchToProps)(Forgot);
