import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { store } from '../_helpers';
import { userActions } from '../_actions';
import {API} from '../_services';
import '../assets/css/modal.css';
import '../assets/css/dataTable.css';

import {CustomerList} from './CustomerList';


export class ManageCustomer extends React.Component {
    constructor() {
        super();
        this.state = {
            error:''
        };
    };
      
    render() {
        return (
            <CustomerList/>
        )
    }
}