import React, { useEffect, useState } from 'react';
import './validation.css';

export default function Message(props) {
    const [state, setState] = useState({ error: null, success: null })
    useEffect(() => {
        if (props.success) {
            setState({ error: null, success: props.success });
            setTimeout(() => {
                setState({ error: null, success: null });
            }, 4000);
        }
        if (props.error) {
            setState({ success: null, error: props.error });
            setTimeout(() => {
                setState({ error: null, success: null });
            }, 4000);
        }
    }, [props]);

    return (
        <React.Fragment>
            {state.error ?
                <div id="snackbar" className="shown danger"><b> <i className="fa fa-times-circle"></i> {state.error}</b></div>
                : null}
            {state.success ?
                <div id="snackbar" className="shown success"><b> <i className="fa fa-check-circle"></i> {state.success}</b></div>
                : null}
        </React.Fragment>
    )
}
