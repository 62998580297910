import {
  userConstants
} from '../_constants';

export function users(state = {}, action) {
  switch (action.type) {
    case userConstants.CHECK_ADD_USER_SUCCESS:
      return {
        ...state, message: action.message
      }
      case userConstants.CHECK_ADD_USER_FAILURE:
        return {
          ...state, error: action.error
        }
        case userConstants.CHECK_UPDATE_USER_SUCCESS:
          return {
            ...state, message: action.message
          }
          case userConstants.CHECK_UPDATE_USER_FAILURE:
            return {
              ...state, error: action.error
            }
            case userConstants.CHECK_LIST_USER_SUCCESS:
              return {
                items: action.users
              };
            case userConstants.GETALL_REQUEST:
              return {
                loading: true
              };
            case userConstants.GETALL_SUCCESS:
              return {
                items: action.users
              };
            case userConstants.GETALL_FAILURE:
              return {
                error: action.error
              };
            case userConstants.SET_PASSWORD_SUCCESS:
              return {
                success: action.message
              };
            case userConstants.SET_PASSWORD_FAILURE:
              return {
                error: action.error
              };
            case userConstants.REMOVE_CHANGE_USER_SUCCESS_MESSAGE:
              return {
                error: null,
                  success: null,
              };
            case userConstants.DELETE_REQUEST:
              // add 'deleting:true' property to user being deleted
              return {
                ...state,
                items: state.items.map(user =>
                  user.id === action.id ?
                  {
                    ...user,
                    deleting: true
                  } :
                  user
                )
              };
            case userConstants.CHECK_DELETE_USER_SUCCESS:
              // remove deleted user from state
              return {
                ...state,
                items: state.items.filter(user => user.id !== action.id),
                  message: action.message
              };
            case userConstants.CHECK_DELETE_USER_FAILURE:
              // remove 'deleting:true' property and add 'deleteError:[error]' property to user
              return {
                ...state,
                items: state.items.map(user => {
                  if (user.id === action.id) {
                    // make copy of user without 'deleting:true' property
                    const {
                      deleting,
                      ...userCopy
                    } = user;
                    // return copy of user with 'deleteError:[error]' property
                    return {
                      ...userCopy,
                      deleteError: action.error
                    };
                  }

                  return user;
                }), error: action.error
              };
            case userConstants.CONTACT_US_SUCCESS:
              return {
                ...state, contactUsMessage: action.message
              };
            case userConstants.CONTACT_US_FAILURE:
              return {
                ...state, contactUsError: action.error
              };
            default:
              return state
  }
}